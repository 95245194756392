import {useState, useRef} from 'react'
import styled from "styled-components";
import Modal from '@mui/material/Modal';

import useResponsive from '../../../hooks/useResponsive';

interface IProps {
  children: any;
  openBottomModal: boolean;
  closeBottomModal: () => void;
}

const BottomModal = ({children, openBottomModal, closeBottomModal}: IProps) => {
  const [touching, setTouching] = useState<boolean>(false);

  const responsive = useResponsive(580);

  const prevClientYRef = useRef<number>(0);
  const modalRef = useRef<any>();
  const topbarRef = useRef<any>();

  const handleTouchMove = (e) => {
    const {bottom} = modalRef.current.getBoundingClientRect();

    if(bottom < window.innerHeight && e.touches[0].clientY < prevClientYRef.current) return;

    let winDif = window.innerHeight - topbarRef.current.offsetHeight;
    let cordY = e.touches[0].clientY;
    modalRef.current.style.transform = `translateY(${(cordY - winDif) + modalRef.current.offsetHeight}px)`;
    setTouching(true);
    prevClientYRef.current = cordY;
    if((window.innerHeight - cordY) < 280) {
      closeBottomModal();    
      setTouching(false);
    }
  }

  const handleTouchEnd = () => {
    modalRef.current.style.transition = "transform 300ms ease";
    modalRef.current.style.transform = "translateY(0)";
    setTouching(false);
  }

  return(
    <StyledModal open={openBottomModal} onClose={closeBottomModal}>
      <Container 
        ref={modalRef}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        style={touching ? {} : {
          transition: "transform 300ms ease"
        }}
      > 
        <>
          {responsive ? <TopLine ref={topbarRef} /> : <CloseBtn onClick={closeBottomModal}>x</CloseBtn>}
          {children}
        </>
      </Container>
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  width: 800px;
  padding: 15px 0;
  height: fit-content;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
  animation: fadeIn 300ms ease;
  box-shadow: 0 0 6px rgba(0, 0, 0, .2);
  outline: none;
  z-index: 9999;

  @media screen and (max-width: 900px) {
    width: 90%;
  }

  @media screen and (max-width: 580px) {
    width: 100%;
    top: initial;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: initial;
    border-radius: 30px 30px 0 0;
    animation: fadeToTop 300ms ease;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0);
    }

    80% {
      transform: scale(1.2);
    }

    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes fadeToTop {
    from {
      transform: translateY(100%);
    }

    to {
      transform: translateY(0);
    }
  }
`;

const TopLine = styled.div`
  width: 60px;
  height: 5px;
  background-color: #d6d6d6;
  border-radius: 50px;
`;

const CloseBtn = styled.span`
  position: absolute;
  left: 20px;
  top: 15px;
  font-size: 28px;
  color: grey;
  cursor: pointer;
`;

export default BottomModal;