import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import StorageService from "../../../../core/services/storage";

import { setSelectedHotel } from "../../../../Actions/onboardingActions";

import ArrowSVG from "../../../../assets/images/icons/arrow.svg";

const HotelsList = () => {
  const [openList, setOpenList] = useState<boolean>();

  const hotels = useSelector((state: any) => state.clientDesign.design.hotelList);
  const selectedHotel = useSelector((state: any) => state.onboarding.selected_hotel);

  const dispatch = useDispatch();

  const handleOpenHotelList = () => {
    setOpenList(!openList);
  }

  const handleSelectHotel = (hotel: any) => {
    setOpenList(false);
    dispatch(setSelectedHotel(hotel));
    StorageService.local.save.item("hotel", hotel);
  }

  return(
    <Container>
      <SelectBox onClick={handleOpenHotelList}>
        {!selectedHotel ? "City" : selectedHotel?.name}
        <ArrowIMG 
          src={ArrowSVG} 
          style={{
            transform: openList ? "rotate(0)" : "rotate(-90deg)"
          }} 
        />
      </SelectBox>
      {openList && <List>
        {hotels.map((hotel) => {
          return <ListItem key={hotel.id} onClick={() => handleSelectHotel(hotel)}>{hotel.display_name || hotel.name}</ListItem>
        })}
      </List>}
    </Container>
  ) 
}

const Container = styled.div`
  position: relative;
  width: 100%;
  margin-top: 10px;
`;

const SelectBox = styled.div`
  padding: 0 10px;
  height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #1E293B;
  border-radius: 8px;
  transition: all 300ms ease;
  cursor: pointer;
`;

const List = styled.div`
  margin-top: 5px;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .2);
  z-index: 999;
`;

const ListItem = styled.div`
  width: 100%;
  margin: 10px;
  padding-left: 30px;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
`;

const ArrowIMG = styled.img`
  width: 18px;
  transition: all 300ms ease;
`;

export default HotelsList;