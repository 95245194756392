import moment from 'moment';
import {useSelector} from "react-redux";
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import Mixpanel, { MP_Events, MP_Props } from '../../../core/services/mixpanel';
import StorageService from '../../../core/services/storage';
import { postOnBoardingData, getOauthToken } from '../../../core/services/trip';
import { Routes } from '../../../core/utils/endpoints';
import { useTripDetails } from '../../../providers/TripDetailsProvider';
import { ICreateTripDetails, IItineraryEvents, IOnBoarding_New, ITimeframes } from '../Onboarding/interfaces';
import { TripTypesDic } from '../../../core/utils/dictionaries';
import {
  IItinerary, IItineraryResponse
} from './interfaces';
import ParamsService from "../../../core/services/params";

export const useConvertOnBoarding = () => {
    const {setTripDetailsWrapper} = useTripDetails();

    const {DATE_SELECTION, HOTEL_SELECTION, TOKENLESS_ONBOARDING, name: clientName} = useSelector((state: any) => state.clientDesign.design);
    const {travelerItineraries, selected_interests, tripPace, selected_hotel, dateRange} = useSelector((state: any) => state.onboarding);

    const selected_type = tripPace;
    const selectedHotel = selected_hotel;
    const startDate = new Date(dateRange[0]);
    const endDate = new Date(dateRange[1]);
    const history = useHistory();
    
    const createTimeFrames = (startDate:string, numberOfDays:number, lat:number, lng:number) => {
        let timeframesArray: ITimeframes[] = [];
        for(let i = 0; i < numberOfDays; i++) {
          timeframesArray.push({
            date: new Date(new Date(startDate).getTime() + 84000000 * (i + 1)).toISOString().slice(0, 10),
            start_time: "07:00:00",
            end_time: "19:00:00",
            starting_geolocation: {
              lat, 
              lng,
            },
            ending_geolocation: {
              lat, 
              lng,
            }
          });
        }
        return timeframesArray;
    }
      
    const convertToNewDataPrefrences=(old: ICreateTripPayload):any=>{
        const cachedOnboardingData: any | null = StorageService.local.get.onboardingData();
        let hotel =  StorageService.local.get.item('hotel')
        let filterByHotel;
        let onBoardData;
        if(cachedOnboardingData!.onboarding_data.type === 'property'){
            StorageService.local.save.item('hotel', cachedOnboardingData!.onboarding_data.property)
        }  
    
        if(cachedOnboardingData!.onboarding_data.type === 'hospitality'){   
            filterByHotel = cachedOnboardingData!.onboarding_data.properties.find(a => a.name === selected_hotel.name);
            onBoardData = cachedOnboardingData!.onboarding_data.properties[0]
        }else{
            filterByHotel = cachedOnboardingData!.onboarding_data.property
            onBoardData = cachedOnboardingData!.onboarding_data.property
        }
        const type=cachedOnboardingData?.onboarding_data.type
        let lng_lat;
        let id;
        let include_breakfast;
        let include_lunch;
        let include_dinner;
        let distance_tolerance;
        let time_tolerance_hours;
        let attraction_cluster_size;
        let max_attraction_range_km;
        let max_paid_per_day;
        let cross_destination;
    
        if(type==="hospitality" || type === 'property'){
            lng_lat= filterByHotel.city.geolocation
            id= filterByHotel.city.id
            include_breakfast=onBoardData.itinerary_config.include_breakfast!
            include_lunch=onBoardData.itinerary_config.include_lunch!
            include_dinner=onBoardData.itinerary_config.include_dinner!
            distance_tolerance=onBoardData.itinerary_config.distance_tolerance_km!
            time_tolerance_hours=onBoardData.itinerary_config.time_tolerance_hours!
            attraction_cluster_size=onBoardData.itinerary_config.attraction_cluster_size!
            max_paid_per_day=onBoardData.itinerary_config.max_paid_per_day!
            cross_destination= onBoardData.itinerary_config.cross_destination;
            max_attraction_range_km= onBoardData.itinerary_config.max_attraction_range_km;
        }
        else{
            lng_lat=cachedOnboardingData?.onboarding_data!.destinations![0].geolocation
            id=cachedOnboardingData?.onboarding_data!.destinations![0].id
            include_breakfast=cachedOnboardingData?.onboarding_data!.config!.include_breakfast
            include_lunch=cachedOnboardingData?.onboarding_data!.config!.include_lunch
            include_dinner=cachedOnboardingData?.onboarding_data!.config!.include_dinner
            distance_tolerance=cachedOnboardingData?.onboarding_data!.config!.distance_tolerance_km
            time_tolerance_hours=cachedOnboardingData?.onboarding_data!.config!.time_tolerance_hours
            attraction_cluster_size=cachedOnboardingData?.onboarding_data!.config!.attraction_cluster_size
            max_paid_per_day=cachedOnboardingData?.onboarding_data!.config!.max_paid_per_day
            cross_destination= cachedOnboardingData?.onboarding_data!.config!.cross_destination;
            max_attraction_range_km= cachedOnboardingData?.onboarding_data!.config!.max_attraction_range_km;
        }
        
        const timeframes=createTimeFrames(old.td?.start_date!,old.td?.number_of_days!,lng_lat.lat,lng_lat.lng);
    
        const properties = StorageService.local.get.onboardingData()?.onboarding_data.properties;
    
        const filteredHotel = properties?.find(hotel => hotel.uuid === selected_hotel.uuid || selected_hotel.id);
        const NewDataPrefrences:ICreateTripDetails={
        starting_date:old.td?.start_date!,
        number_of_days:old.td?.number_of_days!,
        destination:id,
        starting_geolocation:{
            lng:lng_lat.lng, 
            lat:lng_lat.lat
        },
        preferences:{
            category_ids:old.tp.interests,
            include_breakfast:include_breakfast,
            // include_lunch:true,
            include_lunch:include_lunch,
            include_dinner:include_dinner,
            intensity:old.tp.intensity+1,
            distance_tolerance_km:distance_tolerance,
            time_tolerance_hours:time_tolerance_hours,
            attraction_cluster_size:attraction_cluster_size,
            max_paid_per_day: max_paid_per_day,
            // @ts-ignore
            excluded_categories: filteredHotel.itinerary_config.excluded_categories,
            cross_destination: cross_destination,
            max_attraction_range_km: max_attraction_range_km
        },
        itinerary_sections: timeframes,
        design: cachedOnboardingData?.onboarding_data.design! || cachedOnboardingData?.onboarding_data.property.design!,
        }
        return NewDataPrefrences;
    }
      
    const getHotelList=(cachedOnboardingData: IOnBoarding_New)=>{
        let hotels: IHotel[] = [];
        if (cachedOnboardingData.onboarding_data.type==='hospitality'){
            hotels=cachedOnboardingData.onboarding_data.properties.map((p,index)=>{
                return{
                    id: index,
                    city: p.city.name,
                    name: p.name,
                    display_name: p.display_name,
                    coming_soon: false,
                    irrelevant_interests: null
                }
            });
        } else {
            hotels.push({id:0, city:"", name:"", display_name: "",coming_soon:false,irrelevant_interests:null})
        }
        return hotels
    }
    
    const getCityList=(cachedOnboardingData: IOnBoarding_New)=>{
        let city:any=[];
        if (cachedOnboardingData.onboarding_data.type==='hospitality'){
            city=cachedOnboardingData.onboarding_data.properties.map((p)=>{
            return{
                city_name:p.city.name
            }
        });
        }
        else{
            city.push({city_name:""})
        }
        return city
    }
      
    const convertToOldDesign=()=>{
        const cachedOnboardingData: IOnBoarding_New | null = StorageService.local.get.onboardingData();
        let design;
        if(cachedOnboardingData!.onboarding_data.property){
            design = cachedOnboardingData!.onboarding_data.property.design;
        }else{
            design = cachedOnboardingData!.onboarding_data.design
        }
        const response = {
            name:design?.title,
            Logo: design?.logo,
            PrimaryColor_text:design?.primary_color_text,
            PrimaryColor_bg: design?.primary_color_bg,
            SecondaryColor_text:design?.secondary_color_text,
            SecondaryColor_bg:design?.secondary_color_bg,
            TertiaryColor_text:design?.tertiary_color_text,
            TertiaryColor_bg:design?.tertiary_color_bg,
            EmailHeaderAndFooter_text:design?.email_header_and_footer_text,
            EmailHeaderAndFooter_bg: design?.email_header_and_footer_bg,
            EmailDiscoverButton_text:design?.email_discover_button_text,
            EmailDiscoverButton_bg:design?.email_header_and_footer_bg,
            Image1_text:design?.image1_text,
            Image1_URL:design?.image1_url,
            Image2_text:design?.image2_text,
            Image2_URL:design?.image2_url,
            Image3_text:design?.image3_text,
            Image3_URL:design?.image3_url,
            Image4_text:design?.image4_text,
            Image4_URL:design?.image4_url,
            Image5_text:design?.image5_text,
            Image5_URL:design?.image5_url,
            Image6_text:design?.image6_text,
            Image6_URL:design?.image6_url,
            irrelevant_interests: [], //"21,22,5,6"
            merchant_flow:false,
            tokenless_onboarding: true,
            hotel_selection: true,
            city_selection:false,
            date_selection:true,
            hotels_list: getHotelList(cachedOnboardingData!) || null,
            city_list: getCityList(cachedOnboardingData!) || null,
        }
        return response;
    }
    
    const convertToTp = () => {
    return {
        intensity: selected_type,
        interests: Array.from(selected_interests)
    }
    }
      
    const convertToTd = (itinerary:IItinerary) => {
    const cachedOnboardingData: IOnBoarding_New | null = StorageService.local.get.onboardingData();
    let hotel =  StorageService.local.get.item('hotel');
    let response;
    if(cachedOnboardingData?.onboarding_data.type==="hospitality"){
        response={
            id: itinerary.uuid,
            state:"",
            city:hotel?.city || selected_hotel.city,
            number_of_days:itinerary.number_of_days,
            accommodation_name: selectedHotel?.name,
            accommodation_address: "",
            start_date:itinerary.starting_date
        }
    }
    else{
        response={
            id: itinerary.uuid,
            state:"",
            city:"",
        number_of_days:itinerary.number_of_days,
        accommodation_name: selectedHotel?.name,
        accommodation_address: "",
        start_date:itinerary.starting_date
        }
    }
    return response
    }
      
    const changeType=(events:IItineraryEvents[])=>{
    const response=events.map((e)=>{
        let type;
    
        switch(e.type){
        case "ATR":
            type=1;
            break;
            case"CMT":
            type=3;
            break;
            case"RES":
            type=2
            break
            default:
            type=1
            break
        }
        return{...e, type}
    });
    return response
    }
      
    const getDays = (itinerary:IItinerary) => {
    const response :any[]=itinerary.sections.map((s,index)=>{
        return {
        id:new Date().getTime() + Math.random(),
        date:s.date,
        index: index,
        itinerary_item:changeType(s.events!)
        }
    });
    
    return response;
    }
      
    const convertToOldFeedData=(newDataResponse:IItineraryResponse)=>{
        const {itinerary}=newDataResponse;
    
        const response={
        id:itinerary.uuid,
        status:1,
        token:itinerary.uuid,
        design:convertToOldDesign(),
        tp:convertToTp(),
        td:convertToTd(itinerary),
        trip_favorites:Array.from(selected_interests),
        day_itinerary:getDays(itinerary),
        }
        localStorage.setItem('trip', JSON.stringify(itinerary));
        return response;
    }
    
    const countDays = (startDate, endDate) => {
        return Math.ceil(Math.abs(new Date(startDate).getTime() - new Date(endDate).getTime()) / 86400000) + 1;
    }

    async function createTripHandler() {
        try {
            let newSelectedInterests = [];
            for(let i = 0; i < selected_interests.length; i++) {
                // @ts-ignore
                newSelectedInterests.push(...selected_interests[i]);
            }
            const tripPrefrences: ITripPreferences = {
                intensity: selected_type || 0,
                interests: newSelectedInterests,
            };
            let payload: ICreateTripPayload | undefined = {
                tp: tripPrefrences,
                tokenless: TOKENLESS_ONBOARDING,
                client: clientName,
            };
            let tripDetails: ITripDetailsRequest | undefined = undefined;
        
            if (TOKENLESS_ONBOARDING) {
                tripDetails = {};
        
                if (DATE_SELECTION) {
                    if (!(startDate && endDate)) throw new Error('no dates were choosen');
            
                    let start = moment(startDate);
                    const end = moment(endDate);
            
                    if(startDate.toISOString().slice(0, 10) === new Date(Date.now() + 84000000).toISOString().slice(0, 10)) {
                        start = moment(startDate);
                    }
            
                    const days = countDays(start.toISOString().slice(0, 10), end.toISOString().slice(0, 10));
            
                    tripDetails.start_date = start.toISOString().slice(0, 10);
                    tripDetails.number_of_days = days;
            
                    let dateRange = {
                        start: tripDetails.start_date = start.toISOString().slice(0, 10),
                        end: end.toISOString().slice(0, 10)
                    }
            
                    localStorage.setItem('dates', JSON.stringify(dateRange));
                    }
                
                    if (HOTEL_SELECTION) {
                        if (!selectedHotel) throw new Error('no hotel choosen');
                        payload.hotel_id = selectedHotel.id || selectedHotel.uuid;
                    }
                
                    payload.td = tripDetails;
                }
            let response = convertToNewDataPrefrences(payload);
        
            const paramClient = StorageService.local.get.item("client_url");
            // const createdTrip = await createTrip(payload, TOKENLESS_ONBOARDING);
            console.log(response);
            const createdTrip_new = await postOnBoardingData(paramClient, response);
            const createOauthToken = await getOauthToken();
            StorageService.local.save.item('OauthToken', createOauthToken?.data.access_token);
            StorageService.local.save.item("traveler",createdTrip_new.itinerary.traveler);
            const oldDataForFeed=convertToOldFeedData(createdTrip_new);
        
            return oldDataForFeed;
        } catch (error) {
            console.log(error);
        }
    }
      
    async function finishTrip() {
  
        try {

        const createdTripResponse: any = await createTripHandler();
        setTripDetailsWrapper(createdTripResponse, false);
        const { token } = createdTripResponse;
        if (TOKENLESS_ONBOARDING && token) {
            StorageService.local.save.token(token);
            //Merging the two different tokens together into one Alias
            Mixpanel.alias(Mixpanel.getDistinctID(), token);
        }
        history.push(Routes.feed);
        } catch (error) {
            console.log(error)
        toast.error('OOPS! We had trouble creating your trip!');
        }
        
    }

    return {
        finishTrip,
    }
}