import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

interface IProps {
  openReplaceDialog: boolean;
  handleCloseReplaceDialog: () => void;
  deleteAttr: () => void;
  handleBack: () => void;
}
export default function AlertDialog({
  deleteAttr,
  openReplaceDialog,
  handleCloseReplaceDialog,
  handleBack,
}: IProps) {
  return (
    <div>
      <Dialog
        open={openReplaceDialog}
        onClose={handleCloseReplaceDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dipalog-title">{""}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to delete the selected attraction or keep it?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteAttr} color="primary">
            Delete
          </Button>
          <Button onClick={handleCloseReplaceDialog} color="primary" autoFocus>
            Keep
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
