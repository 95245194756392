import styled from "styled-components";

interface IProps {
  logo?: string;
  text: string;
  color?: string;
}

const Loading = ({logo, color, text}: IProps) => {
  return(
    <Container>
      <Logo src={logo} alt="" />
      <Text color={color}>
        {text}
      </Text>
      <Spinner>
        <Dot color={color} />
        <Dot color={color} style={{top: "auto", bottom: 0, animationDelay: "-1.0s"}} />
      </Spinner>
    </Container>
  )
}

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
`;

const Logo = styled.img`
  width: 80px;
  height: auto;
  text-align: center;
`;

const Text = styled.h3`
  width: 80%;
  text-align: center;
  font-size: 18px;
  line-height: 28px;
  color: ${(p) => p.color};
`;

const Spinner = styled.div`
  margin: 100px auto;
  width: 50px;
  height: 50px;
  position: relative;
  text-align: center;
  animation: sk-rotate 2.5s infinite linear;
  -webkit-animation: sk-rotate 2.5s infinite linear;
`;

const Dot = styled.div`
  width: 70%;
  height: 70%;
  display: inline-block;
  position: absolute;
  margin: 20px;
  top: 0;
  background-color: ${(p) => p.color};
  border-radius: 100%;
  animation: sk-bounce 2.5s infinite ease-in-out;
  -webkit-animation: sk-bounce 2.5s infinite ease-in-out;
`;

export default Loading;