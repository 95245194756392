export const LocationSVG = (size, color) => {
  return <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.625 8.125C15.625 6.63316 15.0324 5.20242 13.9775 4.14752C12.9226 3.09263 11.4918 2.5 10 2.5C8.50816 2.5 7.07742 3.09263 6.02252 4.14752C4.96763 5.20242 4.375 6.63316 4.375 8.125C4.375 10.4325 6.22125 13.44 10 17.0425C13.7788 13.44 15.625 10.4325 15.625 8.125ZM10 18.75C5.41625 14.5838 3.125 11.0412 3.125 8.125C3.125 6.30164 3.84933 4.55295 5.13864 3.26364C6.42795 1.97433 8.17664 1.25 10 1.25C11.8234 1.25 13.572 1.97433 14.8614 3.26364C16.1507 4.55295 16.875 6.30164 16.875 8.125C16.875 11.0412 14.5838 14.5838 10 18.75Z" fill={color} />
    <path d="M10 10C10.4973 10 10.9742 9.80246 11.3258 9.45083C11.6775 9.09919 11.875 8.62228 11.875 8.125C11.875 7.62772 11.6775 7.15081 11.3258 6.79917C10.9742 6.44754 10.4973 6.25 10 6.25C9.50272 6.25 9.02581 6.44754 8.67417 6.79917C8.32254 7.15081 8.125 7.62772 8.125 8.125C8.125 8.62228 8.32254 9.09919 8.67417 9.45083C9.02581 9.80246 9.50272 10 10 10V10ZM10 11.25C9.1712 11.25 8.37634 10.9208 7.79029 10.3347C7.20424 9.74866 6.875 8.9538 6.875 8.125C6.875 7.2962 7.20424 6.50134 7.79029 5.91529C8.37634 5.32924 9.1712 5 10 5C10.8288 5 11.6237 5.32924 12.2097 5.91529C12.7958 6.50134 13.125 7.2962 13.125 8.125C13.125 8.9538 12.7958 9.74866 12.2097 10.3347C11.6237 10.9208 10.8288 11.25 10 11.25Z" fill={color}/>
  </svg>
}

export const CalendarSVG = (size, color) => {
  return <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.9168 3.3335H16.1112V4.44461H17.7779V16.6668H2.22234V4.44461H3.889V3.3335H2.08345C1.95358 3.33567 1.82541 3.3634 1.70626 3.41511C1.58712 3.46682 1.47932 3.5415 1.38904 3.63488C1.29876 3.72826 1.22775 3.83851 1.18009 3.95933C1.13242 4.08015 1.10902 4.20918 1.11123 4.33905V16.7724C1.10902 16.9023 1.13242 17.0313 1.18009 17.1521C1.22775 17.2729 1.29876 17.3832 1.38904 17.4766C1.47932 17.5699 1.58712 17.6446 1.70626 17.6963C1.82541 17.748 1.95358 17.7758 2.08345 17.7779H17.9168C18.0467 17.7758 18.1748 17.748 18.294 17.6963C18.4131 17.6446 18.5209 17.5699 18.6112 17.4766C18.7015 17.3832 18.7725 17.2729 18.8201 17.1521C18.8678 17.0313 18.8912 16.9023 18.889 16.7724V4.33905C18.8912 4.20918 18.8678 4.08015 18.8201 3.95933C18.7725 3.83851 18.7015 3.72826 18.6112 3.63488C18.5209 3.5415 18.4131 3.46682 18.294 3.41511C18.1748 3.3634 18.0467 3.33567 17.9168 3.3335Z" fill={color}/>
    <path d="M4.44434 7.77783H5.55545V8.88894H4.44434V7.77783Z" fill={color} />
    <path d="M7.77783 7.77783H8.88894V8.88894H7.77783V7.77783Z" fill={color} />
    <path d="M11.1111 7.77783H12.2222V8.88894H11.1111V7.77783Z" fill={color} />
    <path d="M14.4443 7.77783H15.5554V8.88894H14.4443V7.77783Z" fill={color} />
    <path d="M4.44434 10.5557H5.55545V11.6668H4.44434V10.5557Z" fill={color} />
    <path d="M7.77783 10.5557H8.88894V11.6668H7.77783V10.5557Z" fill={color} />
    <path d="M11.1111 10.5557H12.2222V11.6668H11.1111V10.5557Z" fill={color} />
    <path d="M14.4443 10.5557H15.5554V11.6668H14.4443V10.5557Z" fill={color} />
    <path d="M4.44434 13.3335H5.55545V14.4446H4.44434V13.3335Z" fill={color} />
    <path d="M7.77783 13.3335H8.88894V14.4446H7.77783V13.3335Z" fill={color} />
    <path d="M11.1111 13.3335H12.2222V14.4446H11.1111V13.3335Z" fill={color} />
    <path d="M14.4443 13.3335H15.5554V14.4446H14.4443V13.3335Z" fill={color} />
    <path d="M5.55556 5.55577C5.7029 5.55577 5.84421 5.49724 5.94839 5.39305C6.05258 5.28887 6.11111 5.14756 6.11111 5.00022V1.66688C6.11111 1.51954 6.05258 1.37823 5.94839 1.27405C5.84421 1.16986 5.7029 1.11133 5.55556 1.11133C5.40821 1.11133 5.26691 1.16986 5.16272 1.27405C5.05853 1.37823 5 1.51954 5 1.66688V5.00022C5 5.14756 5.05853 5.28887 5.16272 5.39305C5.26691 5.49724 5.40821 5.55577 5.55556 5.55577Z" fill={color} />
    <path d="M14.4445 5.55577C14.5918 5.55577 14.7331 5.49724 14.8373 5.39305C14.9415 5.28887 15 5.14756 15 5.00022V1.66688C15 1.51954 14.9415 1.37823 14.8373 1.27405C14.7331 1.16986 14.5918 1.11133 14.4445 1.11133C14.2971 1.11133 14.1558 1.16986 14.0516 1.27405C13.9474 1.37823 13.8889 1.51954 13.8889 1.66688V5.00022C13.8889 5.14756 13.9474 5.28887 14.0516 5.39305C14.1558 5.49724 14.2971 5.55577 14.4445 5.55577Z" fill={color} />
    <path d="M7.22217 3.3335H12.7777V4.44461H7.22217V3.3335Z" fill={color} />
  </svg>
}

export const HearthSVG = (size, color) => {
  return <svg width={size} height={size} viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.25 2C4.79875 2 2 5.10651 2 8.93918C2 12.0331 3.09375 19.376 13.86 26.7988C14.0529 26.9304 14.2743 27 14.5 27C14.7257 27 14.9471 26.9304 15.14 26.7988C25.9062 19.376 27 12.0331 27 8.93918C27 5.10651 24.2012 2 20.75 2C17.2988 2 14.5 6.20556 14.5 6.20556C14.5 6.20556 11.7012 2 8.25 2Z" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
}

export const ManSVG = (size, color) => {
  return <svg width={size} height="27" viewBox="0 0 17 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.49983 8.69231C10.8668 8.69231 12.7855 6.97033 12.7855 4.84615C12.7855 2.72198 10.8668 1 8.49983 1C6.13289 1 4.21411 2.72198 4.21411 4.84615C4.21411 6.97033 6.13289 8.69231 8.49983 8.69231Z" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M16 15.4227C16 13.6376 15.2098 11.9256 13.8033 10.6633C12.3968 9.40103 10.4891 8.69189 8.5 8.69189C6.51088 8.69189 4.60322 9.40103 3.1967 10.6633C1.79018 11.9256 1 13.6376 1 15.4227V18.3073H4.21429L5.28571 25.9996H11.7143L12.7857 18.3073H16V15.4227Z" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
}