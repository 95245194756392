import React, { createContext, useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router';
import { ThemeProvider } from 'styled-components';
import {useDispatch} from 'react-redux'
import {saveDesign} from '../Actions/clientDesignActions'
import {
  IDesign,
  IItinerary,
  IItineraryEvents,
  IItineraryResponse,
  IOnBoarding_New,
  IPropertiesData,
} from '../components/pages/Onboarding/interfaces';
import { convertTripDetails } from '../core/services/converts';
import Mixpanel, { MP_Events, MP_Props } from '../core/services/mixpanel';
import ParamsService from '../core/services/params';
import StorageService from '../core/services/storage';
import { getOnboardingData, getOnboardingDataByProp, getOnboardingDataByPropId, getSavedItinerary } from '../core/services/trip';
import { WELCOME_SCREEN_DURATION } from '../core/utils/common';
import { Routes } from '../core/utils/endpoints';
import { InsertAllTripItemsIDsIntoMap } from '../core/utils/feed';
import { useLayout } from './LayoutProvider';
import { BridgifyInstance } from "../core/services/instances";

export interface ITripDetailsProvider {
  tripDetails: ITripDetailsState;
  setTripDetailsWrapper: (updatedTripDetails: any, convertedAlready: boolean) => void;
  updateTripDetails: () => any;
  navigatePageWithWelcomeScreen: (destination: Routes, params?: string) => void;
  convertToOldFeedData: any,
  setTripDetails: any
}

export interface ITripDetailsState extends ITripDetails_Converted {
  itemsIDs: number[];
}
export const BRIDGIFY_DEFAULT_JSON: IClientDesign = {
  Logo: null,
  name: 'bridgify',
  MERCHANT_FLOW: false,
  TOKENLESS_ONBOARDING: true,
  HOTEL_SELECTION: false,
  CITY_SELECTION: false,
  DATE_SELECTION: false,
  PrimaryColor: {
    bgColor: '#631D76',
    textColor: 'white',
  },
  SecondaryColor: {
    bgColor: '#EDAE5F',
    textColor: 'black',
  },
  TertiaryColor: {
    bgColor: '#000235',
    textColor: 'white',
  },
  Images: {
    Image1: {
      text: 'We are building the perfect itinerary for you...',
      imgURL: 'https://bridgify-frontend-s3.s3.eu-central-1.amazonaws.com/clients/bridgify/man.jpg',
    },
    Image2: {
      text: 'Our smart algorithms are scanning the web for you...',
      imgURL:
        ' https://bridgify-frontend-s3.s3.eu-central-1.amazonaws.com/clients/bridgify/boys.jpg',
    },
    Image3: {
      text: ' We are calculating the best trip itinerary based on your needs & interests.',
      imgURL:
        'https://bridgify-frontend-s3.s3.eu-central-1.amazonaws.com/clients/bridgify/falcon.jpg',
    },
    Image4: {
      text: "We don't save any private information... Only the final itinerary is saved.",
      imgURL:
        ' https://bridgify-frontend-s3.s3.eu-central-1.amazonaws.com/clients/bridgify/light.jpg',
    },
    Image5: {
      text: 'The itinerary is highly custimizable! You can explore & book unique activities for your trip.',
      imgURL:
        'https://bridgify-frontend-s3.s3.eu-central-1.amazonaws.com/clients/bridgify/groynes.jpg',
    },
    Image6: {
      text: 'Next up is your itinerary!',
      imgURL:
        'https://bridgify-frontend-s3.s3.eu-central-1.amazonaws.com/clients/bridgify/black.jpg',
    },
  },
};
const defaultValue: ITripDetailsProvider = {
  setTripDetailsWrapper: () => {},
  tripDetails: {
    _id: 0,
    tripAccommodation: {
      accommodation_address: '',
      accommodation_name: '',
      city: '',
      id: 0,
      number_of_days: 0,
      start_date: null,
      finish_date: null,
      state: '',
    },
    favorites: [],
    tripPreferences: { intensity: 0, interests: [] },
    tripDays: [],
    itemsIDs: [],
    ClientDesign: BRIDGIFY_DEFAULT_JSON,
  },
  updateTripDetails: () => {},
  navigatePageWithWelcomeScreen: () => {},
  convertToOldFeedData: (itinerary: any) => {},
  setTripDetails: () => {}
};

const TripDetailsContext = createContext<ITripDetailsProvider>(defaultValue);

const convertToOldDesign = (design: IDesign) => {
  const response = {
    name: design?.title,
    Logo: design?.logo,
    PrimaryColor_text: design?.primary_color_text,
    PrimaryColor_bg: design?.primary_color_bg,
    SecondaryColor_text: design?.secondary_color_text,
    SecondaryColor_bg: design?.secondary_color_bg,
    TertiaryColor_text: design?.tertiary_color_text,
    TertiaryColor_bg: design?.tertiary_color_bg,
    EmailHeaderAndFooter_text: design?.email_header_and_footer_text,
    EmailHeaderAndFooter_bg: design?.email_header_and_footer_bg,
    EmailDiscoverButton_text: design?.email_discover_button_text,
    EmailDiscoverButton_bg: design?.email_header_and_footer_bg,
    Image1_text: design?.image1_text,
    Image1_URL: design?.image1_url,
    Image2_text: design?.image2_text,
    Image2_URL: design?.image2_url,
    Image3_text: design?.image3_text,
    Image3_URL: design?.image3_url,
    Image4_text: design?.image4_text,
    Image4_URL: design?.image4_url,
    Image5_text: design?.image5_text,
    Image5_URL: design?.image5_url,
    Image6_text: design?.image6_text,
    Image6_URL: design?.image6_url,
    irrelevant_interests: [], //"21,22,5,6"
    merchant_flow: false,
    tokenless_onboarding: true,
    hotel_selection: true,
    city_selection: false,
    date_selection: true,
    hotels_list: [{ id: 1, name: '', coming_soon: false, irrelevant_interests: null }],
    city_list: [{ city_name: '' }],
  };
  return response;
};

const convertToTp = () => {
  return {
    intensity: '',
    interests: [],
  };
};

const convertToTd = (itinerary: IItinerary, design: any) => {
  const cachedOnboardingData: IOnBoarding_New | null = StorageService.local.get.onboardingData();
  const response = {
    id: itinerary.uuid,
    state: '',
    city: cachedOnboardingData?.onboarding_data.properties[0].city.name,
    number_of_days: itinerary.number_of_days,
    accommodation_name: design.title,
    accommodation_address: '',
    start_date: itinerary.starting_date,
  };
  return response;
};

const changeType = (events: IItineraryEvents[]) => {
  const response = events.map((e) => {
    let type;

    switch (e.type) {
      case 'ATR':
        type = 1;
        break;
      case 'CMT':
        type = 3;
        break;
      case 'RES':
        type = 2;
        break;
      default:
        type = 1;
        break;
    }

    return { ...e, type };
  });
  return response;
};

const getDays = (itinerary: IItinerary) => {
  const response: any[] = itinerary.sections.map((s, index) => {
    return {
      id: new Date().getTime() + Math.random(),
      date: s.date,
      index: index,
      itinerary_item: changeType(s.events!),
    };
  });

  return response;
};

const convertToOldFeedData = (newDataResponse: IItineraryResponse, design: any) => {
  let itinerary = newDataResponse.itinerary || JSON.parse(localStorage.getItem('trip')!);

  const response = {
    id: itinerary.uuid,
    status: 1,
    token: itinerary.uuid,
    design: convertToOldDesign(design),
    tp: convertToTp(),
    td: convertToTd(itinerary, design),
    trip_favorites: [1, 2, 3, 4, 5],
    day_itinerary: getDays(itinerary),
  }

  return response;
}

export const useTripDetails = (): ITripDetailsProvider => useContext(TripDetailsContext);

export function TripDetailsProvider({ children }) {
  const [tripDetails, setTripDetails] = useState<ITripDetailsState>({
    _id: 0,
    tripAccommodation: {
      accommodation_address: '',
      accommodation_name: '',
      city: '',
      id: 0,
      number_of_days: 0,
      start_date: null,
      finish_date: null,
      state: '',
    },
    tripPreferences: { intensity: 0, interests: [] },
    tripDays: [],
    favorites: [],
    itemsIDs: [],
    ClientDesign: BRIDGIFY_DEFAULT_JSON,
  });
  const [hospitality, setHospitality] = useState(false);
  const [errorOnboarding, setErrorOnboarding] = useState('');
  const { ClientDesign } = tripDetails;
  //Set client throuth the URL address or if testing through the tested customer
  const history = useHistory();
  const { isDesktop } = useLayout();

  const { PrimaryColor, SecondaryColor, TertiaryColor } = ClientDesign;
  const theme: ITheme = {
    primary: PrimaryColor,
    secondary: SecondaryColor,
    tertiary: TertiaryColor,
  };

  function navigatePageWithWelcomeScreen(destination: Routes, params: string = ''): void {
    const pathname = history.location.pathname;
    if (pathname === Routes.default || pathname === Routes.onBoarding) {
      setTimeout(() => {
        history.push(`${destination}${params}`);
      }, WELCOME_SCREEN_DURATION);
    }
  }

  /**
   * A wrapper function for setTripDetails.
   * @param updatedTripDetails
   * @param convertedAlready - indicates if there's a need for data convertion for the setState
   */

  async function setTripDetailsWrapper(
    updatedTripDetails,
    convertedAlready = false,
  ): Promise<ITripDetailsState> {
    const updatedAndConvertedTripDetails = convertedAlready
      ? updatedTripDetails
      : convertTripDetails(updatedTripDetails);
    //Update itemsID on every trip update
    const itemsIDs = Array.from(InsertAllTripItemsIDsIntoMap(updatedAndConvertedTripDetails));
    const ClientDesign = updatedAndConvertedTripDetails.ClientDesign;
    // FIXME:
    // await fetchClientDesign();
    // console.log(ClientDesign)
    const tripDetailsWithItemsIS: ITripDetailsState = {
      ...updatedAndConvertedTripDetails,
      itemsIDs,
      ClientDesign,
    };
    setTripDetails(tripDetailsWithItemsIS);
    StorageService.session.save.tripDetails(tripDetailsWithItemsIS);
    return tripDetailsWithItemsIS;
  }

  async function updateTripDetails() {
    try {
      const token = StorageService.local.get.token();
      // const tripDetails = await getTripDetails();
      const tripDetails = await getSavedItinerary(token!);
      // StorageService.local.save.item('trip', tripDetails);
      localStorage.setItem('trip', JSON.stringify(tripDetails.itinerary));
      const convertedToOldData = convertToOldFeedData(tripDetails, tripDetails.itinerary.design);
      setTripDetailsWrapper(convertedToOldData);
    } catch (error) {
      const errorCode: string = ('' + error).includes('400') ? '400' : '401';
      throw new Error(errorCode);
    }
  }

  function initToken(): string | null {
    //Change token in case of a new one
    let leastUpdatedToken: string | null = StorageService.local.get.token();
    const paramToken: string | null = ParamsService.getToken();
    if (paramToken && paramToken !== leastUpdatedToken) {
      StorageService.session.clear();
      leastUpdatedToken = paramToken;
      StorageService.local.save.token(leastUpdatedToken);
    }
    return leastUpdatedToken;
  }

  //TODO: CHANGE THE ENDPOINT TO THE NEW ONE

  /**
   * This function has two jobs:
   * 1. Fetch the trip details with the given token
   * 2. Sets the client design from the tripDetails response or fetch it independently
   */
  async function fetchTripDetails(): Promise<ITripDetailsState> {
    const cachedTripDetails: ITripDetails_Converted | null =
      StorageService.session.get.tripDetails();
    if (cachedTripDetails) {
      // CASE 1: YES- Valid Token, YES- Cached Trip - Same session, no need to track analytics\
      const tripDetailsState = setTripDetailsWrapper(cachedTripDetails, true);
      return tripDetailsState;
    } else {
      //getripdetails
      const token = StorageService.local.get.token();
      const tripDetails = await getSavedItinerary(token!);
      const convertedToOldData = convertToOldFeedData(tripDetails, tripDetails.itinerary.design);
      const tripDetailsState: ITripDetailsState = await setTripDetailsWrapper(
        convertedToOldData,
        false,
      );
      return tripDetailsState;
    }
  }

  const getHotelAndCityList = (newData: any[]): any => {
    const hotelList: IHotel[] = newData.map((d) => {
      return {
        id: d.uuid,
        city: d.city.name,
        name: d.name,
        display_name: d.display_name,
        coming_soon: false,
        irrelevant_interests: null,
      };
    });
    const cityList: any[] = newData.map((d) => {
      return { city_name: d.city.name };
    });
    return { hotelList, cityList };
  };

  const convertToOldInterface = (newOnboardingData: IOnBoarding_New): IClientDesign => {
    //  if type==hospitaliy taking the defalut design ,else properties[0].design
    let name;
    let hotelList;
    let cityList;
    let hotelSelection;
    let chooseDesign: IDesign;
    const paramProperty: string | undefined = ParamsService.getProperty();
    const paramPropertyId: string | null = ParamsService.getPropertyId();
    const localProperty: string | null = localStorage.getItem('property');
    const localPropertyId: string | null = localStorage.getItem('propertyID');
    if (newOnboardingData.onboarding_data.type === 'hospitality') {
      chooseDesign = newOnboardingData.onboarding_data.design!;
      name = newOnboardingData.onboarding_data.properties[0].name;
      hotelList = getHotelAndCityList(newOnboardingData.onboarding_data.properties).hotelList;
      cityList = getHotelAndCityList(newOnboardingData.onboarding_data.properties).cityList;
      hotelSelection = true;
    } else {
      if(paramProperty || paramPropertyId || localProperty || localPropertyId){
        name = newOnboardingData.onboarding_data.property?.itinerary_config.title;
        hotelList = null;
        cityList = { city_name: newOnboardingData.onboarding_data.property?.city.name };
        hotelSelection = false;
        chooseDesign = newOnboardingData.onboarding_data.property?.design!;
        StorageService.local.save.item('design', newOnboardingData.onboarding_data.property?.design!);
      }else{
        name = newOnboardingData.onboarding_data.config.title;
        hotelList = null;
        cityList = null;
        hotelSelection = false;
        chooseDesign = newOnboardingData.onboarding_data.design!;
        StorageService.local.save.item('design', newOnboardingData.onboarding_data.design!);
      }
    }

    const OldData: IClientDesign = {
      name: name,
      Logo: chooseDesign.logo,
      hotelList: hotelList,
      cityList: cityList,
      PrimaryColor: {
        bgColor: chooseDesign.primary_color_bg,
        textColor: chooseDesign.primary_color_text,
      },
      SecondaryColor: {
        bgColor: chooseDesign.secondary_color_bg,
        textColor: chooseDesign.secondary_color_text,
      },
      TertiaryColor: {
        bgColor: chooseDesign.tertiary_color_bg,
        textColor: chooseDesign.tertiary_color_text,
      },
      Images: {
        Image1: { text: chooseDesign.image1_text, imgURL: chooseDesign.image1_url },
        Image2: { text: chooseDesign.image2_text, imgURL: chooseDesign.image2_url },
        Image3: { text: chooseDesign.image3_text, imgURL: chooseDesign.image3_url },
        Image4: { text: chooseDesign.image4_text, imgURL: chooseDesign.image4_url },
        Image5: { text: chooseDesign.image5_text, imgURL: chooseDesign.image5_url },
        Image6: { text: chooseDesign.image6_text, imgURL: chooseDesign.image6_url },
      },
      TOKENLESS_ONBOARDING: true,
      HOTEL_SELECTION: hotelSelection,
      CITY_SELECTION: false,
      DATE_SELECTION: true,
      MERCHANT_FLOW: false,
    };

    return OldData;
  };

const dispatch = useDispatch()



  async function fetchOnboardingData(): Promise<IClientDesign> {
    const cachedOnboardingData: IOnBoarding_New | null = StorageService.local.get.onboardingData();
  const paramClientName: string | undefined = ParamsService.getClient();
    const paramProperty: string | undefined = ParamsService.getProperty();
    const paramPropertyId: string | null = ParamsService.getPropertyId();
    const paramItineraryId: string | null = ParamsService.getItineraryId();
    let newDataConvetedToOld;
    let name;
    let type;
    let errorOnboard;
    // if(paramItineraryId){
    //   handleSelectTrip(paramItineraryId)
    // }
    if (cachedOnboardingData !== null) {
      type = cachedOnboardingData.onboarding_data.type;
      if (type === 'hospitality') {
        name = cachedOnboardingData!.onboarding_data!.design!.title;
      }
    }
    //If there's already a cached client design, and there's no need to prioritize a param client
    if (cachedOnboardingData && (!paramClientName || paramClientName === name)) {
      if (cachedOnboardingData.onboarding_data.type === 'hospitality') {
        setHospitality(true);
      }
      try {
        newDataConvetedToOld = convertToOldInterface(cachedOnboardingData);
      } catch (error) {
        console.log(error);
      }
      setTripDetails((prevState: ITripDetailsState) => ({
        ...prevState,
        ClientDesign: newDataConvetedToOld,
      }));
      return newDataConvetedToOld;
      //If there's a specific client param to fetch it's design
    } else if (paramClientName && paramClientName !== name) {
      try {
        let onboardingData;
        StorageService.local.save.item('client_url', paramClientName);
        if(!paramProperty && !paramPropertyId){
          errorOnboard = 'Client not found!'
          onboardingData = await getOnboardingData(paramClientName);
        }
        if(paramProperty && !paramPropertyId){
          errorOnboard = 'Client or Property not found!'
          console.log('paramClientName', paramClientName)
          let data = await getOnboardingDataByProp(paramClientName, paramProperty);
          onboardingData = converToHospitality(data)
          console.log('onboardingData paramProperty', onboardingData)
          StorageService.local.save.item('paramProperty', paramProperty)
          StorageService.local.save.item('hotel', onboardingData.onboarding_data.properties[0])
        }
  
        if(!paramProperty && paramPropertyId){
          errorOnboard = 'Client or Property Id not found!'
          let data = await getOnboardingDataByPropId(paramClientName, paramPropertyId);
          onboardingData = converToHospitality(data)
          console.log('onboardingData paramPropertyId', onboardingData)
          console.log('propertyData', onboardingData.properties)
          StorageService.local.save.item('paramPropertyId', paramPropertyId)
          StorageService.local.save.item('hotel', onboardingData.onboarding_data.properties[0])
        }        
        const newDataConvetedToOld: IClientDesign = convertToOldInterface(onboardingData);
        StorageService.local.save.onboardingData(onboardingData);
        setTripDetails((prevState: ITripDetailsState) => ({
          ...prevState,
          ClientDesign: newDataConvetedToOld,
        }));
        StorageService.local.save.clientDesign(newDataConvetedToOld);
        dispatch(saveDesign(newDataConvetedToOld))
        return newDataConvetedToOld;
      } catch (error) {
        console.error(error);
        localStorage.setItem('error', errorOnboard)
        navigatePageWithWelcomeScreen(Routes.page404);
  
      }
    }
    return BRIDGIFY_DEFAULT_JSON;
  }

  function converToHospitality(data) {
    console.log('data', data)
      console.log('hospitality', {onboarding_data: {
        design: data.onboarding_data.property.design,
        display_license_agreement:true,
        license_agreement_image: data.onboarding_data.license_agreement_image,
        properties: [data.onboarding_data.property],
        type: 'hospitality',
      }})
      return {onboarding_data: {
        design: data.onboarding_data.property.design,
        display_license_agreement:true,
        license_agreement_image: data.onboarding_data.license_agreement_image,
        properties: [data.onboarding_data.property],
        type: 'hospitality',
      }}
  }

  /**Clears the cookies if there's a reusable=1 in the URL params */
  function cookiesInit(): void {
    const acceptedTerms = localStorage.getItem("accepted_terms") || "";
    if (ParamsService.getReusable()) {
      StorageService.session.clear();
      StorageService.local.clear();
      if(acceptedTerms) {
        localStorage.setItem("accepted_terms", acceptedTerms);
      }
    }
  }

  enum InitTripCases {
    ToFeed = 'To Feed', // 'trip found, redirection to the Feed'
    ToOnboarding = 'To Onboarding', // 'No trip, redirect to Onboarding flow'
    NoTokenError = 'No Token Error', //'User token is Missing, not a tokenless flow'
  }
  const paramItineraryId: string | null = ParamsService.getItineraryId();

  useEffect(() => {
    /**
     * Initialization of the trip details that includes:
     * 1. Trip details - itinerary details, favorites (Etc.)
     * 2. Client Design - client's theme + configuration (tokenless?, Etc.)
     *
     * The steps are:
     *    1. check for Existing trip Details - cache or stored in DB (including clientDesign)
     *      if yes--> load it
     *      else--> redirect to tokenless flow or throw Error
     */
    async function initTripDetails() {
      cookiesInit();
      try {
        //if user enters the link with a token-> don't trigger the dialog
        if (ParamsService.getToken()) StorageService.local.save.validatedEmail();

        const leastUpdatedToken = initToken();
        //If there's a token: fetch tripDetails
        //Else --> redirect to tokenless flow or throw Error
        if (leastUpdatedToken) {
          try {
            if(paramItineraryId){
              handleSelectTrip(paramItineraryId)
            }
            const tripDetails = await fetchTripDetails();
            const { ClientDesign } = tripDetails;
            //Check whether the trip-details response's 'design' field is empty
            // or there's a specific client parameter that should be noticed
            if (!ClientDesign || ParamsService.getClient() !== ClientDesign.name) {
              fetchOnboardingData();
              navigatePageWithWelcomeScreen(Routes.onBoarding);
            } else {
              dispatch(saveDesign(ClientDesign))
              StorageService.local.save.clientDesign(ClientDesign);
            }
          } catch (error: any) {
            if (error.message.includes('400')) {
              throw new Error(InitTripCases.ToOnboarding);
            } else {
              throw new Error(InitTripCases.NoTokenError);
            }
          }
        } else {
          const clientDesign = await fetchOnboardingData();
          
          // FIXME:
          // fetchClientDesign();

          //If the client is tokenless-> go onboarding, else-> 404
          throw new Error(
            clientDesign.TOKENLESS_ONBOARDING
              ? InitTripCases.ToOnboarding
              : InitTripCases.NoTokenError,
          );
        }

        //Successfully loaded trip! Redirect to Feed
        throw new Error(InitTripCases.ToFeed);

        //Try get the trip details, if there's no trip, go to onboarding
      } catch (error: any) {
        const clientDesign: IClientDesign | null = await fetchOnboardingData();
        //FIXME:
        // fetchClientDesign();

        const paramRedirectSrc: string | null = ParamsService.getRedirectionSource();
        if (paramRedirectSrc) StorageService.local.save.redirectionSource(paramRedirectSrc);
        switch (error.message) {
          case InitTripCases.ToFeed:
            Mixpanel.initAndIdentify();
            Mixpanel.register({
              [MP_Props.Desktop]: isDesktop,
              [MP_Props.client]: clientDesign?.name || 'bridgify',
              [MP_Props.property]: ParamsService.getProperty() || "none",
              [MP_Props.testing]: ParamsService.getInHouseTest() ? "yes" : "no",
              [MP_Props.tokenless]: clientDesign?.TOKENLESS_ONBOARDING === true,
              [MP_Props.redirected]:
                paramRedirectSrc || StorageService.local.get.redirectionSource() || undefined,
            });
            Mixpanel.track(MP_Events.AppOpen, {
              [MP_Props.tripCreated]: true,
              [MP_Props.firstTime]: false,
            });
            navigatePageWithWelcomeScreen(Routes.feed);
            break;
          case InitTripCases.ToOnboarding:
            Mixpanel.initAndIdentify();
            Mixpanel.register({
              [MP_Props.Desktop]: isDesktop,
              [MP_Props.client]: clientDesign?.name || 'bridgify',
              [MP_Props.property]: ParamsService.getProperty() || "none",
              [MP_Props.testing]: ParamsService.getInHouseTest() ? "yes" : "no",
              [MP_Props.tokenless]: clientDesign?.TOKENLESS_ONBOARDING === true,
              [MP_Props.redirected]:
                paramRedirectSrc || StorageService.local.get.redirectionSource() || undefined,
            });
            Mixpanel.track(MP_Events.AppOpen, {
              [MP_Props.tripCreated]: false,
              [MP_Props.firstTime]: true,
            });
            navigatePageWithWelcomeScreen(Routes.onBoarding);
            break;
          // case InitTripCases.ErrorNoToken:
          default:
            toast.error('OOPS! The link is broken. Please make sure the link is correct');
            // navigatePageWithWelcomeScreen(Routes.page404);
            break;
        }
      }
    }

    initTripDetails();
  }, []);

  const handleSelectTrip = async (itineraryUUID) => {
    const { data: { itinerary } } = await BridgifyInstance.get(`/itineraries/${itineraryUUID}`);
    localStorage.setItem('trip', JSON.stringify(itinerary));
    const convertedData = convertToOldFeedData({itinerary}, itinerary.design)
    await setTripDetailsWrapper(convertedData, false);
    StorageService.local.save.clientDesign(convertedData.design);
    localStorage.setItem('destination', itinerary.destination);
    console.log('itinerary', itinerary)
    console.log('convertedData', convertedData)
    history.push("/feed", itinerary);
  }
  
  

  return (
    // <ClientContext.Provider value={{ ClientDesign: client, updateClientDesign }}>
    // @ts-ignore
    <ThemeProvider theme={theme}>
      <TripDetailsContext.Provider
        value={{
          tripDetails,
          setTripDetailsWrapper,
          updateTripDetails,
          navigatePageWithWelcomeScreen,
          convertToOldFeedData,
          setTripDetails
        }}
      >
        {children}
      </TripDetailsContext.Provider>
    </ThemeProvider>
    // </ClientContext.Provider>
  );
}