import tripInterests from '../../assets/static/tripInterests/tripInterests';
import { HASH, Langs } from './enums';

export const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const WELCOME_SCREEN_DURATION = 1500;

export function isValidLang(langString: string): boolean {
  //@ts-ignore
  return Object.values(Langs).includes(langString);
}

export function isEmpty(obj: Object) {
  return Object.keys(obj).length === 0;
}
export function formatDateto_YYYY_MM_DD_HH_MM_SS(date_ob: Date): string {
  // adjust 0 before single digit date
  const date = ('0' + date_ob.getDate()).slice(-2);
  // current month
  const month = ('0' + (date_ob.getMonth() + 1)).slice(-2);
  // current year
  const year = date_ob.getFullYear();
  // current hours
  const hours = date_ob.getHours();
  // current minutes
  const minutes = date_ob.getMinutes();
  // current seconds
  const seconds = date_ob.getSeconds();
  // prints date & time in YYYY-MM-DD HH:MM:SS format
  return year + '-' + month + '-' + date + ' ' + hours + ':' + minutes + ':' + seconds;
}

export function convert24HTimeToAMPM(time: string): string {
  let [hour, mins] = time.split(':');
  const hourValue: number = +hour;
  if (isNaN(hourValue)) return time;

  const convertedHour: number = hourValue % 12 || 12;
  const ampm: 'AM' | 'PM' = hourValue < 12 || hourValue === 24 ? 'AM' : 'PM';

  return `${convertedHour}:${mins} ${ampm}`;
}

export function setHash(hash: HASH, id?: string | number): void {
  window.location.hash = `${hash}${id && `-${id}`}`;
}

export function clearHash(): void {
  window.location.hash = '';
}

export function inCurrentHash(hash: HASH, id?: string): boolean {
  return window.location.hash === `${hash}${id ? `-${id}` : ''}`;
}
export function clearedHash(): boolean {
  return window.location.hash === '';
}

export function turnSpacesToUnderline(str: string): string {
  return str.split(' ').join('_');
}

export function convetSetOfInterestIntoArray(setOfinterests: Set<number>): Array<string> {
  let arrayWithNames: Array<string> = [];
  setOfinterests.forEach((item: number) => arrayWithNames.push(tripInterests.English[item].title));
  return arrayWithNames;
}
