import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import StorageService from '../../core/services/storage';
import { delay } from '../../core/utils/common';
import { TripItemsDic } from '../../core/utils/dictionaries';
import { CardBorderColors, TRIP_ITEMS } from '../../core/utils/enums';
import { useTripDetails } from '../../providers/TripDetailsProvider';
import DaysSlider from '../pages/Feed/DaysSlider';
import TimelineBox from '../pages/Feed/TimelineBox';
import { useFeed } from '../pages/Feed/useFeed';
import AccomandationItem from './AccomandationItem';
import FeedTopBar from './FeedTopBar';
import useResponsive from '../hooks/useResponsive';

interface IProps {
  actionIcons: IActionIcons[];
  onAttractionDelete: (index: number) => Promise<void>;
}
export default function Timeline({ actionIcons, onAttractionDelete }: IProps) {
  const {
    state: { currentDayIndex, showMapView },
    dispatch,
  } = useFeed();
  
  const {
    tripDetails: {
      tripDays,
      ClientDesign: { TOKENLESS_ONBOARDING },
      ClientDesign: { PrimaryColor, SecondaryColor },
    },
  } = useTripDetails();

  const [touching, setTouching] = useState<boolean>(false);
  const [isMapView, setIsMapView] = useState<boolean>(showMapView);
  const [closed, setClosed] = useState<boolean>(false);
  const [axis, setAxis] = useState<boolean | null>(null);

  const responsive = useResponsive(800);

  const attractionsRef = useRef<any>();
  const prevScrollX = useRef<number>(0);
  const prevScrollY = useRef<number>(0);

  const handleCloseAttractions = () => {
    dispatch({ type: "OPEN_MAP_VIEW", payload: true });
    setClosed(true);
    setTouching(false);
    setAxis(null);
    attractionsRef.current.style.transition = "transform 300ms ease";
    attractionsRef.current.style.transform = "translateX(-100%)";
  }

  const handleTouchMove = (e) => {
    let matrix: number = 0;
    if(!closed) {
      const resultX = prevScrollX.current - e.touches[0].clientX;
      prevScrollX.current = e.touches[0].clientX;

      const resultY = prevScrollY.current - e.touches[0].clientY;
      prevScrollY.current = e.touches[0].clientY;

      if(!touching) {
        if(Math.abs(resultY) > Math.abs(resultX)) {
          setAxis(true);
        } else {
          setAxis(false);
        }
      }
      setTouching(true);

      if(axis) return;

      matrix = new WebKitCSSMatrix(attractionsRef.current.style.transform).m41;

      if(resultX > 60) {
        handleCloseAttractions();
        return;
      }

      if((matrix - resultX) < 0 && (!axis && axis !== null)) {
        attractionsRef.current.style.transform = `translateX(${matrix - resultX}px)`;
      }
    }

    if(matrix < -220) {
      handleCloseAttractions();
    }
  }

  const handleTouchEnd = () => {
    if(closed) return;
    attractionsRef.current.style.transition = "transform 300ms ease";
    attractionsRef.current.style.transform = "translateX(0)";
    setTouching(false);
    setAxis(null);
  }

  const handleShowAttractions = () => {
    attractionsRef.current.style.transform = `translateX(${closed || !showMapView ? 0 : "-100%"})`;
    dispatch({ type: "OPEN_MAP_VIEW", payload: !closed });
    setClosed(!closed);
  }

  useEffect(() => {
   if(showMapView) {
    attractionsRef.current.style.transition = "transform 300ms ease";
    attractionsRef.current.style.transform = "translateX(-100%)";
    setIsMapView(true)
   }else{
    attractionsRef.current.style.transition = "transform 300ms ease";
    attractionsRef.current.style.transform = "translateX(0)";
    setIsMapView(false)
   }
  }, [showMapView]);

  /**Trigger the scroll listener for open email-verification dialog, only on the following conditions:
   * 1. It's a TOKENLESS client
   * 2. The user hasn't verified his email yet
   */
  const triggerScrollListener: boolean =
    TOKENLESS_ONBOARDING && !StorageService.local.get.validatedEmail();

  async function OpenEmailValidationDialog() {
    if (TOKENLESS_ONBOARDING && !StorageService.local.get.validatedEmail()) {
      await delay(2000);
      dispatch({ type: 'OPEN_EMAIL_DIALOG', payload: true });
    }
  }

  let ACTIVITIES_COUNTER: number = 0;

  const RenderComponent = (item: ITripDay['items'][0], index: number) => {
    const { type } = item;
    
    const Component = TripItemsDic[type].component;

    const cardBorderColor =
      item.cardBorderColor === CardBorderColors.PaidAttraction
        ? PrimaryColor.bgColor
        : item.cardBorderColor === CardBorderColors.FreeAttraction
        ? SecondaryColor.bgColor
        : '#9E9E9E';

    if (type !== TRIP_ITEMS.Commute && isMapView) {
      ACTIVITIES_COUNTER++;
    }
    return (
      <TimelineBox
        last={index === tripDays[currentDayIndex]?.items.length - 1}
        key={item._id}
        boxType={type}
        cardBorderColor={cardBorderColor}
      >
        <Component
          item={item}
          ACTIVITIES_COUNTER={ACTIVITIES_COUNTER}
          TIMELINE
          onDelete={() => onAttractionDelete(index)}
          last={index === tripDays[currentDayIndex]?.items.length - 1}
        />
      </TimelineBox>
    );
  }; 

  return (
    <Container
      onScrollCapture={triggerScrollListener ? OpenEmailValidationDialog : undefined}
    >
      <TripDetailsContainer>
        <FeedTopBar actionIcons={actionIcons} handleShowAttractions={handleShowAttractions} />
        <DaysSlider />
      </TripDetailsContainer>
      {(responsive && closed) && <ArrowBtn 
        onClick={handleShowAttractions}
        style={closed ? {
          right: "initial",
          left: -10
        } : {
          top: "80%",
          transform: `translateY(-80%) scale(${closed ? 1 : -1})`,
          padding: "18px 5px 15px 10px"
        }}
        >
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M4.90869 9C4.90869 9.1705 4.99489 9.33401 5.14831 9.45457C5.30174 9.57513 5.50983 9.64286 5.72681 9.64286H15.2055L11.6925 12.402C11.5389 12.5227 11.4526 12.6864 11.4526 12.8571C11.4526 13.0279 11.5389 13.1916 11.6925 13.3123C11.8461 13.433 12.0545 13.5008 12.2717 13.5008C12.489 13.5008 12.6973 13.433 12.851 13.3123L17.7596 9.45514C17.8358 9.39543 17.8963 9.32449 17.9375 9.24639C17.9788 9.16828 18 9.08456 18 9C18 8.91544 17.9788 8.83171 17.9375 8.75361C17.8963 8.67551 17.8358 8.60457 17.7596 8.54486L12.851 4.68771C12.6973 4.567 12.489 4.49919 12.2717 4.49919C12.0545 4.49919 11.8461 4.567 11.6925 4.68771C11.5389 4.80843 11.4526 4.97215 11.4526 5.14286C11.4526 5.31357 11.5389 5.47729 11.6925 5.598L15.2055 8.35714H5.72681C5.50983 8.35714 5.30174 8.42487 5.14831 8.54543C4.99489 8.66599 4.90869 8.8295 4.90869 9ZM0.818115 18C0.601138 18 0.393047 17.9323 0.239621 17.8117C0.0861942 17.6912 0 17.5276 0 17.3571V0.642857C0 0.472361 0.0861942 0.308848 0.239621 0.188288C0.393047 0.0677294 0.601138 0 0.818115 0C1.03509 0 1.24318 0.0677294 1.39661 0.188288C1.55004 0.308848 1.63623 0.472361 1.63623 0.642857V17.3571C1.63623 17.5276 1.55004 17.6912 1.39661 17.8117C1.24318 17.9323 1.03509 18 0.818115 18Z" fill="#707070"/>
        </svg>
      </ArrowBtn>}
      <ItemsList 
        ref={attractionsRef}
        collapse={showMapView}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        style={touching ? {} : {
          transition: "transform 300ms ease"
        }}
      >
        {!showMapView ? <ItemsContainer style={touching ? {
          overflowY: touching && axis ? "scroll" : "hidden"
        } : {}}>
          <AccomandationItem />
          {tripDays[currentDayIndex]?.items.map((dayItinerary, index) =>
            RenderComponent(dayItinerary, index),
          )}
        </ItemsContainer> : null}
      </ItemsList>
    </Container>
  );
}

const ItemsContainer = styled.div`
  background-color: #ffffffe6;
  padding: 1rem;
  border-radius: 10px;
  z-index: 1000;
  overflow: scroll;
  height: calc(100vh - 200px);
  @media screen and (max-width:1000px) {
    margin-top: 150px;
    /* width: 100vw; */
    margin-bottom: 40px;
    height: calc(100vh - 208px);
  }
`;

const ArrowBtn = styled.div`
  width: fit-content;
  height: fit-content;
  background-color: white;
  border-radius: 20px;
  position: fixed;
  right: 0;
  padding: 15px 5px 12px 18px;
  top: 40%;
  transform: translateY(-40%);
  z-index: 99;
`;

const Container = styled.div`
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  @media screen and (max-width:1000px) {
    overflow-y: hidden;
  }

  @media screen and (max-width: 580px) {
    width: 100vw;
  }
`;

const TripDetailsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;

  @media screen and (max-width:1000px) {
      position: fixed;
      z-index: 999;
      width: 100vw;
  }
`;
const ItemsList = styled('div')<{ collapse: boolean }>`
  height: 100%;
  /* padding:1rem;
    background-color: #ffffffb0; */
  z-index: ${(p) => (p.collapse ? '0' : '2')};
  width: min-content;
`;
