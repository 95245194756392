import { TransitionProps } from '@mui/material/transitions';
import { useState, createContext, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import StorageService from '../../core/services/storage';
import {replaceEventNewApi} from '../../core/services/trip';
import { clearHash } from '../../core/utils/common';
import { BookedStatuses } from '../../core/utils/enums';
import { useTripDetails } from '../../providers/TripDetailsProvider';
import { FeedProvider, useFeed } from './Feed/useFeed';



export function ButtonProvider(): typeof exports {
  const history = useHistory();
  const { tripDetails, updateTripDetails } = useTripDetails();
  const {
    itemsIDs,
    tripPreferences: { interests },
  } = tripDetails;
  const {
    state: {isOpenRemoveExplore, currentDayIndex, selectedReplace, selectedAddToTrip, isOpenExplore },
    dispatch,

  } = useFeed();
  const {
    handleCloseExplore, handleOpenExplore, handleOpenReplaceAttr,
    handleCloseReplaceAttr, setShowExploreDialog
  } = FeedProvider();

  const [selectedEvenToAdd, setSelectedEventToAdd] = useState<any>();
  const [openReplaceDialog, setOpenReplaceDialog] = useState<boolean>(false);
  const [openReplaceAttrDialog, setOpenReplaceAttrDialog] = useState<boolean>(false);
  const [showReplaceAttrDialog, setShowReplaceAttrDialog] = useState<boolean>(false);



  function handleBack() {
    try {
      // handleCloseReplaceAttr()
      dispatch({ type: 'OPEN_REMOVE_EXPLORE', payload: false });
      setShowReplaceAttrDialog(false)
      dispatch({ type: 'OPEN_EXPLORE', payload: false });
      dispatch({ type: 'SELECTED_ITEM_REPLACE', payload: null });
      dispatch({ type: 'IS_SELECTED_ATR', payload: false });
      setShowExploreDialog(false)
      clearHash();
      history.goBack();
    } catch(err) {
      console.error(err);      
    }
  }
  async function handleReplace(item) {
    await replaceEventNewApi(selectedReplace._id, item.uuid);
    dispatch({ type: 'OPEN_EXPLORE', payload: false});
    dispatch({ type: 'UPDATE_MARKERS_ATTRACTIONS', payload: item});
    dispatch({ type: 'IS_REPLACE' });
    StorageService.session.save.currentDay(currentDayIndex);
      dispatch({ type: 'CURRENT_DAY_INDEX', payload: currentDayIndex });

    updateTripDetails();
  }
  async function handleSelectToAdd(item) {
    setSelectedEventToAdd(item)
    dispatch({ type: 'SELECTED_ITEM_ADD_TO_TRIP', payload: item });
    if (selectedReplace !== null) {
      await replaceEventNewApi(selectedReplace._id, item.uuid);
      updateTripDetails();
    } else {
      setOpenReplaceAttrDialog(true);
      // handleOpenReplaceAttr()
      dispatch({ type: 'OPEN_REMOVE_EXPLORE', payload: true });
      setShowReplaceAttrDialog(true)
    }
  }
  async function handleRemove(item) {
    try{
      if(selectedEvenToAdd){
        await replaceEventNewApi(item._id, selectedEvenToAdd.uuid);
        dispatch({ type: 'UPDATE_MARKERS_ATTRACTIONS', payload: item});
        updateTripDetails();
        setOpenReplaceAttrDialog(false);
        handleBack();
      }
    }catch(err){
      console.log(err)
    }
  }


  const handleOpenReplaceDialog = () => {
    if (selectedReplace) {
      setOpenReplaceDialog(true);
    } else {
      handleBack();
    }
  };
  const handleCloseReplaceDialog = () => {
    setOpenReplaceDialog(false);
    handleBack();
  };



  const exports = {
    handleReplace,
    handleSelectToAdd,
    handleRemove,
    handleOpenReplaceDialog,
    handleCloseReplaceDialog,
    handleBack,
    selectedEvenToAdd,
    openReplaceAttrDialog,
    openReplaceDialog,
    showReplaceAttrDialog
  };
  return exports;
}
