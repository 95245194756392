import { useEffect, useState } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import {
  PaymentMethod as IPaymentMethod,
  PaymentMethodResult as IPaymentMethodResult,
  StripeError as IStripeError,
} from '@stripe/stripe-js';
import {
  CreateCart,
  addItemToCart,
  getCart,
  makeAnOrder,
  putCartCustomer,
  payOrder,
  getCustomerSchema,
  setAttractionStatus,
  CompleteNoPayment,
} from '../../../../core/services/musement';
import { useBookingTicketsContext } from '../BookingTickets/useBookingTickets';
import { useTripDetails } from '../../../../providers/TripDetailsProvider';
import { delay } from '../../../../core/utils/common';
import { useFeed } from '../../Feed/useFeed';

export default function usePaymentDialog(): typeof exports {
  const {
    state: {
      selectedPickup,
      products,
      language,
      selectedDate,
      timeSlot: { time },
    },
  } = useBookingTicketsContext();
  const {
    tripDetails: {
      ClientDesign: { MERCHANT_FLOW, name: client },
    },
  } = useTripDetails();
  const {
    state: {
      //@ts-ignore
      selectedItemBook: { _id, uuid, requiresConfirmation },
    },
  } = useFeed();
  const stripe = useStripe();
  const elements = useElements();
  const {
    updateTripDetails,
    tripDetails: { _id: trip_id },
  } = useTripDetails();

  const [error, setError] = useState<IStripeErrors>(null);
  const [cardComplete, setCardComplete] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [paymentMethod, setPaymentMethod] = useState<IPaymentMethod | null>(null);
  const [billingDetails, setBillingDetails] = useState<IBillingDetails>({
    // email: "test@test.com",
    // firstname: "TEST",
    // lastname: "TEST",
    email: '',
    firstname: '',
    lastname: '',
  });
  const [completedProcess, setCompletedProcess] = useState<boolean>(false);
  const [progressValue, setProgressValue] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [extraFields, setExtraFields] =
    useState<{ [key in TExtraCustomerFields]: ISchema_Property }>();
  const [cartUUID, setCardUUID] = useState<string>();
  const merchantPaymentPasswordState = useState<string>('');
  // const [merchantPaymentPassword, setMerchantPaymentPassword] = useState<string>('')
  let pickup: IPickupDetails | undefined = undefined;

  if (selectedPickup) {
    const { latitude: lat, longitude: lng, uuid, name } = selectedPickup;
    pickup = { name, uuid, lng, lat };
  }

  useEffect(() => {
    /**
     * Submitting the order into musement system.
     * Also, The function fetching the required user's details for the payment form.
     */
    async function initPaymentDialog() {
      try {
        //1. Create a card
        const createdCartUUID: string = await CreateCart();
        //2. add items to cart
        await addProductsToCart(createdCartUUID);
        //OPTIONAL--> 3 validate cart is ok
        await getCart(createdCartUUID);

        //OPTIONAL--> 4 To get all information required for your cart
        const cartCustomerSchema: ISchema = await getCustomerSchema(createdCartUUID);
        const Overlay_extraCustomerField_ID: TExtraCustomerFields | undefined =
          cartCustomerSchema?.properties['extra_customer_data']?.required[0];
        const requiredCustomerFields: ISchema_Property = Overlay_extraCustomerField_ID
          ? cartCustomerSchema?.properties['extra_customer_data']?.properties[
              Overlay_extraCustomerField_ID
            ]
          : undefined;
        if (requiredCustomerFields) {
          const { properties } = requiredCustomerFields;
          //Create a map of all required properties
          // const propertiesMap = required?.map((field: string) => {
          //   return properties ? (properties[field]) : null
          // })
          // update the state if there's any need
          // if (propertiesMap !== undefined && propertiesMap?.length !== 0) {
          //@ts-ignore
          setExtraFields(properties);
        }
        setCardUUID(createdCartUUID);
        setLoading(false);
      } catch (error) {
        const errorObj: IStripeError = {
          type: 'api_connection_error',
          message: 'OOPS! We had trouble creating your order, please try again later!',
        };
        setError(errorObj);
      }
    }
    initPaymentDialog();
  }, []);

  function resetFields() {
    setError(null);
    setProcessing(false);
    setPaymentMethod(null);
    setBillingDetails({
      email: '',
      firstname: '',
      lastname: '',
    });
  }

  interface IAddProduct {
    type: TProductType;
    product_identifier: string;
    quantity: string;
    pickup?: string; // pickup uuid
    language?: ILanguage['code']; // language code
  }

  async function addProductsToCart(createdCartUUID: string) {
    await Promise.all(
      products.map(async (product) => {
        const { amount = 0, product_id, type } = product;
        if (amount > 0) {
          const productDetails: IAddProduct = {
            type,
            product_identifier: product_id,
            quantity: `${amount}`,
            pickup: pickup ? pickup.uuid : undefined,
            language: language?.code,
          };
          await addItemToCart(createdCartUUID, productDetails);
        }
      }),
    );
  }

  /**
   * Creating an order in Musement system and proceeding to payment if it's not an affilite mode
   * @param paymentDetails
   */
  async function submitMusementOrder(paymentDetails?: IPaymentMethod): Promise<void> {
    try {
      //1. assign customer to the cart
      setProgressValue(10);
      if (!cartUUID) return;
      await putCartCustomer(cartUUID, billingDetails);
      setProgressValue(30);

      //5. make an order
      const order: IOrder = await makeAnOrder(cartUUID);
      setProgressValue(70);

      const { uuid: order_uuid } = order;
      let paymentResponse: IPaymentResponse | undefined = undefined;
      // 6. pay order
      if (MERCHANT_FLOW) {
        // no-payment musement path
        await CompleteNoPayment(order_uuid);
      } else if (paymentDetails) {
        // pay with musement API
        const payOrderBody: IMusementSubmitOrder = { order_uuid, stripe_token: paymentDetails.id };
        paymentResponse = await payOrder(payOrderBody);
      }
      setProgressValue(99);
      await delay(500);

      setProgressValue(100);

      setProcessing(false);
      setCompletedProcess(true);

      await setAttractionStatus({
        item_id: _id,
        order_id: order_uuid,
        status: paymentResponse?.status === 'PENDING' ? 'PENDING' : 'BOOKED',
        pickup,
        activity_datetime: `${selectedDate?.day} ${time || ''}`,
        trip_id,
        client,
        merchant_flow: MERCHANT_FLOW,
      });
      await updateTripDetails();
    } catch (error) {
      // throw new Error('OOPS! payment has failed, please try again later!');
      const errorObj: IStripeError = {
        type: 'api_error',
        message: 'OOPS! payment has failed, please try again later!',
      };
      setError(errorObj);
      setProcessing(false);
      setPaymentMethod(null);
    }
  }

  const MERCHANT_PASSWORD = process.env.REACT_APP_MUSEMENT_MERCHANT_PASSWORD;
  async function handleSubmit(e) {
    e.preventDefault();

    if (MERCHANT_FLOW) {
      if (merchantPaymentPasswordState[0] === MERCHANT_PASSWORD) {
        setProcessing(true);
        submitMusementOrder();
      } else return;
    } else {
      // Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded
      if (!stripe || !elements) return;

      if (error) {
        //@ts-ignore
        elements.getElement('card').focus();
        return;
      }

      if (cardComplete) {
        setProcessing(true);
      }

      const payload: IPaymentMethodResult = await stripe.createPaymentMethod({
        type: 'card',
        //@ts-ignore
        card: elements.getElement(CardElement),
        billing_details: {
          name: billingDetails.firstname,
          email: billingDetails.email,
        },
      });

      // setProcessing(false);
      if (payload.error) {
        setError(payload.error);
      } else {
        setPaymentMethod(payload.paymentMethod);
        submitMusementOrder(payload.paymentMethod);
      }
    }
  }

  function setBillingDetailsDynamic(e, field: string) {
    setBillingDetails({ ...billingDetails, [field]: e.target.value });
  }

  function setExtraBillingDetailsDynamic(e, field: string) {
    if (billingDetails.extra_customer_data) {
      setBillingDetails({
        ...billingDetails,
        extra_customer_data: {
          ...billingDetails.extra_customer_data,
          [uuid]: {
            ...billingDetails.extra_customer_data[uuid],
            [field]: e.target.value,
          },
        },
      });
    } else {
      setBillingDetails({
        ...billingDetails,
        extra_customer_data: {
          [uuid]: {
            [field]: e.target.value,
          },
        },
      });
    }
  }

  const exports = {
    handleSubmit,
    resetFields,
    paymentMethod,
    billingDetails,
    setBillingDetails,
    setCardComplete,
    error,
    setError,
    processing,
    stripe,
    setBillingDetailsDynamic,
    progressValue,
    completedProcess,
    setCompletedProcess,
    loading,
    setLoading,
    extraFields,
    setExtraBillingDetailsDynamic,
    merchantPaymentPasswordState,
  };
  return exports;
}

type IStripeErrors =
  | IStripeError
  | undefined
  | null
  | {
      type: 'validation_error';
      code: string;
      message: string;
    };

interface IMusementSubmitOrder {
  order_uuid: string;
  stripe_token: string;
}
// interface IUsePaymentDialog {
//   handleSubmit: (event: any) => Promise<void>;
//   resetFields: () => void;
//   paymentMethod: IPaymentMethod | null;
//   billingDetails: IBillingDetails;
//   merchantPaymentPasswordState: [string, Dispatch<SetStateAction<string>>];
//   setBillingDetails: Dispatch<SetStateAction<IBillingDetails>>;
//   setCardComplete: Dispatch<SetStateAction<boolean>>;
//   setError: Dispatch<SetStateAction<IStripeErrors>>;
//   error: IStripeErrors;
//   processing: boolean;
//   stripe: Stripe | null;
//   setBillingDetailsDynamic: (e: any, field: string) => void;
//   setExtraBillingDetailsDynamic: (e: any, field: string) => void;
//   progressValue: number;
//   completedProcess: boolean;
//   setCompletedProcess: Dispatch<SetStateAction<boolean>>;
//   loading: boolean;
//   setLoading: Dispatch<SetStateAction<boolean>>;
//   extraFields: { [key in TExtraCustomerFields]: ISchema_Property } | undefined
// }
