import Interest from '../Interest'
import { Langs } from '../../../../core/utils/enums';
import { InterestsLangDic } from '../../../../core/utils/dictionaries';
import styled from 'styled-components/macro';
import {useSelector, useDispatch} from 'react-redux'
import { useTripDetails } from '../../../../providers/TripDetailsProvider';
import StorageService from "../../../../core/services/storage";
import { HearthSVG } from '../../../../assets/images/svgs';
import toast from 'react-hot-toast';
import '@rmwc/textfield/styles';

const Interests = () => {
  // const randomNumberRef = useRef(Math.random()).current;

  const onboarding = useSelector((state: any) => state.onboarding);
  const {design} = useSelector((state: any) => state.clientDesign);
  const dispatch = useDispatch()
  const {
    tripDetails: {
      ClientDesign: { irrelevantInterests: ClientIrrelevantInterests, TertiaryColor: { textColor } },
    },
  } = useTripDetails();
  let interests: { [key: number]: TTripInterest } = InterestsLangDic[Langs.english];

  function handleSelectInterest(id: any) {
   try{
     dispatch({ type: 'INTERESTS', payload: id });
   } catch{
    toast.error('Oops! We had trouble by getting the categories. Please try again!');
   }
  }

  let allInteres =  Object.keys(interests);

  return (
    <Container>
      <ContainerTitleAndSub>
        <TitleContainer>
          {HearthSVG(29, design?.PrimaryColor?.bgColor)}
          <h2 style={{margin: 0}}>Your interests</h2>
        </TitleContainer>
        <Paragraph>
          Select your favorite categories that are highly relevant for the itinerary planning
        </Paragraph>
      </ContainerTitleAndSub>
      <InterestsGeneralContainer>
        {/* {randomNumberRef < .5 ? */}
          <InterestsContainer>
            {allInteres.map((key: string, index: number) => {
              const Ckey = +key;
              const { title, img, group_cat } = interests[key];
              return (
                <Interest
                  key={`${key}${title}`}
                  id={group_cat}
                  img={img}
                  onClick={() => handleSelectInterest(group_cat)}
                  selected={onboarding.selected_interests}
                  title={title}
                  color={design?.PrimaryColor?.bgColor}
                />
              );
            })}
          </InterestsContainer> {/*: */} 
      </InterestsGeneralContainer>
    </Container>
  )
}
export default Interests;

const Container = styled.div`
  width: fit-content;
  height: 500px;
  max-width: 60%;
  padding: 40px 80px;
  margin-top: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #2C5282;
  border-radius: 8px;
  animation: leftFadeIn 300ms ease;
  -webkit-animation: leftFadeIn 300ms ease;

  @media screen and (max-width: 580px) {
    width: 100vw;
    padding: 0;
    margin-top: 0;
    height: fit-content;
    align-items: flex-start;
    max-width: initial;
    border: none;
  }
  
  @keyframes leftFadeIn {
    from {
      transform: translateX(100%);
      -webkit-transform: translateX(100%);
    }
    to {
      transform: translateX(0);
      -webkit-transform: translateX(0);
    }
  }
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: .5em;
  width: 80%;
  align-items: left;
  margin-bottom: 10px;

  @media screen and (max-width: 580px) {
    width: 100vw;
  }
`;

const ContainerTitleAndSub = styled.div`
  align-items: left;
  margin-bottom: 20px;
  width: 80%;
  margin-left: 20px;
`;

const Paragraph = styled.p`
  text-align: left;
  margin: 0px;
`;

const InterestsGeneralContainer = styled.div`

`;

const InterestsContainer = styled.div`
  padding-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-height: 420px;
  gap: 20px;
  overflow-y: auto;
  
  @media screen and (max-width: 580px) {
    margin: 0 auto;
    display: grid;
    overflow-y: initial;
    max-height: initial;
    grid-template-columns: repeat(2, 1fr);
  }
`;

const InterestsContainerTwo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  grid-row: 1fr;
  overflow-y: auto;
  max-height: 420px;

  @media screen and (max-width: 580px) {
    width: 100%;
    overflow-y: initial;
    max-height: initial;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`;