import { Endpoints } from '../utils/endpoints';
import Calls from './calls';

const {
  bridgify: { post,patch },
} = Calls;
interface ISendEmail {
  traveler_email: string;
  traveler_id:string;
  itinerary_id: string
}

export async function sendVerificationEmail(payload: ISendEmail) {
  return patch(Endpoints.sendEmailVerfication, payload);
  
}

interface ISendCode  {
  verification_code: string;
  traveler_id:string;
  itinerary_id:any;
}
export async function sendVerificationCode(payload: ISendCode) {
  return post(Endpoints.sendEmailVerfication, payload);
}

interface ILoginToExistingTrip {
  email: string;
  verification_code: string;
}
export async function OnboardingLoginToExistingTrip(payload: ILoginToExistingTrip): Promise<
  { token: ITripDetails_Response["token"], trip: ITripDetails_Converted["_id"] }[]> {
  // throw new Error("s");
  return post(Endpoints.getUserToken, payload, true);
}
interface IResendCode {
  email: string;
  client: IClientDesign["name"];
}
export async function resendCode(payload: IResendCode): Promise<void> {
  // throw new Error("s");
  return post(Endpoints.getUserTokenWithMail, payload, true);
}