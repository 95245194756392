import styled from "styled-components"
import { Select, MenuItem, FormControl } from "@mui/material"
import { convert24HTimeToAMPM } from "../../../../core/utils/common"
import { useBookingTicketsContext } from "./useBookingTickets"
import { Dispatch, useState } from "react";
import { SetStateAction } from "react";

interface IProps {
  // handleSelectTimeSlot: (slot: ISlot) => void;
  selectedTimeSlot?: ISlot;
  setSelectedTimeSlot: Dispatch<SetStateAction<ISlot | undefined>>
  slots: ISlot[];
}

export function TimeSelection({ slots, selectedTimeSlot, setSelectedTimeSlot }: IProps) {
  // const { state: { timeSlotIndex } } = useBookingDialogContext();
  // const [selectedTimeSlot, setSelectedTimeSlot] = useState<ISlot>(slots[0])

  // function handleSelectTimeSlot(timeSlot: ISlot) {
  //   setSelectedTimeSlot(timeSlot);
  //   // providerDispatch({ type: "TIMESLOT_INDEX", payload: TimeSlotIndex })
  //   // providerDispatch({ type: "PRODUCTS", payload: slots[TimeSlotIndex].products })
  //   // providerDispatch({ type: "TIME", payload: slots[TimeSlotIndex].time })
  // }

  const SINGLE_TIMESLOT = slots.length <= 1;
  return (
    <>
      <TimeSlotsContainer>
        {!SINGLE_TIMESLOT ?
          <>
            <TimeSlotsLabel>Time:</TimeSlotsLabel>
            <StyledFormControl variant="outlined">
              <StyledSelect value={selectedTimeSlot?.time || slots[0].time}>
                {slots?.map((timeslot: ISlot, index) => {
                  const { time } = timeslot
                  return (
                    <MenuItem
                      key={`ts ${time}` + index}
                      value={timeslot.time}
                      onClick={() => setSelectedTimeSlot(timeslot)}>
                      {convert24HTimeToAMPM(time)}
                    </MenuItem>
                  )
                })}
              </StyledSelect>
            </StyledFormControl>
          </>
          : <TimeSlotsLabel>
            Time: {convert24HTimeToAMPM(slots[0].time)}
          </TimeSlotsLabel>
        }
      </TimeSlotsContainer>
    </>
  )
}

const StyledSelect = styled(Select)`
&&{
  &.MuiInputBase-input{
    padding: 0.7rem;
  }
}
`;

const StyledFormControl = styled(FormControl)`
&&{
  width: 100%;
  min-width: fit-content;
  margin-bottom: 1rem;
}
`;

const TimeSlotsLabel = styled.div`
  margin-top: 0.5rem;
  margin-right: 1rem;
`;

const TimeSlotsContainer = styled.div`
  margin-bottom: 1rem;
`;


