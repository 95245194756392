import * as Sentry from '@sentry/react';
import { AxiosInstance, AxiosRequestConfig, AxiosStatic } from 'axios';
import { instancesDic } from './instances';
import { getLoginMusementToken } from './musement';
import StorageService from './storage';

type IData = { [key: string]: string } | null | any;

const { REACT_APP_AUTH_TOKEN: BRIDGIFY_AUTH_TOKEN } = process.env;

//////////////////////////////====>General<====//////////////////////////////////////////////////

const general_get = async (path: string) => get(path, 'general');

const general_post = async (path: string, data?: IData) => post(path, 'general', data);

//////////////////////////////====>Bridgify<====//////////////////////////////////////////////////

const bridgify_get = async (path: string, tokenless?: boolean) =>
  get(path, 'bridgify', {
    headers: {
      Authorization: `Bearer ${tokenless ? BRIDGIFY_AUTH_TOKEN : StorageService.local.get.token()}`,
    },
  });

const bridgify_get_new = async (path: string) => get(path, 'bridgify',);

const bridgify_post_new=async (path:string,data?:any)=>{
  return post(path, 'bridgify', data);
}

const bridgify_patch=async(path:string,data:any)=>{
  return patch(path,"bridgify",data);
}

const bridgify_post = async (path: string, data?: IData, tokenless?: boolean) =>
  post(path, 'bridgify', data, {
    headers: {
      Authorization: `Bearer ${tokenless ? BRIDGIFY_AUTH_TOKEN : StorageService.local.get.token()}`,
    },
  });

//////////////////////////////====>Musement<====//////////////////////////////////////////////////

async function musement_get(path: string) {
  const accessToken = await getLoginMusementToken();
  return get(path, 'musement', { headers: { Authorization: `Bearer ${accessToken}` } });
}

async function musement_post(path: string, data?: IData) {
  const accessToken = await getLoginMusementToken();
  return post(path, 'musement', data, { headers: { Authorization: `Bearer ${accessToken}` } });
}

async function musement_put(path: string, data?: IData) {
  const accessToken = await getLoginMusementToken();
  return put(path, 'musement', data, { headers: { Authorization: `Bearer ${accessToken}` } });
}

async function musement_delete(path: string) {
  const accessToken = await getLoginMusementToken();
  return deletee(path, 'musement', { headers: { Authorization: `Bearer ${accessToken}` } });
}

//////////////////////////////====>Calls<====//////////////////////////////////////////////////

export async function get(path: string, instanceType: TInstances, config?: AxiosRequestConfig) {
  const instance: AxiosInstance | AxiosStatic = instancesDic[instanceType];
  try {
    const response = await instance.get(path, config);
    return response.data;
  } catch (error: any) {
    Sentry.captureException(error);
    throw new Error(error);
  }
}

async function patch(path:string,instanceType: TInstances, data?: IData,config?: AxiosRequestConfig,){
  try {
    const instance: AxiosInstance | AxiosStatic = instancesDic[instanceType];
    const response = await instance.patch(path, data, config);
    return response.data;
  } catch (error: any) {
    Sentry.captureException(error);
    throw new Error(error);
  }
}

async function post(
  path: string,
  instanceType: TInstances,
  data?: IData,
  config?: AxiosRequestConfig,
) {
  try {
    const instance: AxiosInstance | AxiosStatic = instancesDic[instanceType];
    const response = await instance.post(path, data, config);
    return response.data;
  } catch (error: any) {
    Sentry.captureException(error);
    throw new Error(error);
  }
}
async function put(
  path: string,
  instanceType: TInstances,
  data?: IData,
  config?: AxiosRequestConfig,
) {
  try {
    const instance: AxiosInstance | AxiosStatic = instancesDic[instanceType];
    const response = await instance.put(path, data, config);
    return response.data;
  } catch (error: any) {
    Sentry.captureException(error);
    throw new Error(error);
  }
}
async function deletee(path: string, instanceType: TInstances, config?: AxiosRequestConfig) {
  try {
    const instance: AxiosInstance | AxiosStatic = instancesDic[instanceType];
    const response = await instance.delete(path, config);
    return response.data;
  } catch (error: any) {
    Sentry.captureException(error);
    throw new Error(error);
  }
}
async function put_data(path: string, instanceType: TInstances, config?: AxiosRequestConfig) {
  try {
    const instance: AxiosInstance | AxiosStatic = instancesDic[instanceType];
    const response = await instance.put(path, config);
    return response.data;
  } catch (error: any) {
    Sentry.captureException(error);
    throw new Error(error);
  }
}

const Calls = {
  bridgify: {
    get: bridgify_get,
    post: bridgify_post,
    get_data:bridgify_get_new,
    post_data:bridgify_post_new,
    patch:bridgify_patch,
    delete_event:deletee,
    put_data: put_data
  },
  musement: {
    get: musement_get,
    post: musement_post,
    put: musement_put,
    delete: musement_delete,
  },
  general: {
    get: general_get,
    post: general_post,
  },
};

export default Calls;
// type TCall = 'get' | 'post' | 'put' | 'delete';
// export async function call(type: TCall, path: string, instanceType: TInstances, config?: AxiosRequestConfig) {
//   try {
//     const instance: AxiosInstance | AxiosStatic = instancesDic[instanceType];
//     const callsDic :{[key in TCall]:Function}={
//       "get": instance.get,
//       "post": instance.post,
//       "put": instance.put,
//       "delete": instance.delete,
//     }
//     const call = callsDic[type];
//     const response = await call(path, config)
//     return response.data
//   } catch (error) {
//     Sentry.captureException(error);
//     throw new Error(error);
//   }
// }
