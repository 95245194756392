import styled from "styled-components/macro";
export interface IRank {
  value?: number;
  count?: number;
  color?: string | undefined;
}

// function numberWithCommas(value: number): string {
//   return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
// }

export default function Rank({ count, value, color }: IRank) {
  const NO_REVIEWS: boolean = (!count || count === 0 || !value || value === 0)
  const ReviewsLabel = (
    <RankContainer>
      {value && <>
        <svg width="19" height="18" viewBox="0 0 15 14" fill={color} xmlns="http://www.w3.org/2000/svg">
          <path d="M6.52889 0.487005L4.74357 4.10687L0.749139 4.68922C0.0328215 4.79311 -0.254252 5.67621 0.265215 6.182L3.15509 8.99806L2.47158 12.9761C2.34855 13.6951 3.10588 14.2337 3.74018 13.8975L7.31356 12.0192L10.8869 13.8975C11.5212 14.231 12.2786 13.6951 12.1555 12.9761L11.472 8.99806L14.3619 6.182C14.8814 5.67621 14.5943 4.79311 13.878 4.68922L9.88356 4.10687L8.09823 0.487005C7.77835 -0.158228 6.85151 -0.16643 6.52889 0.487005Z" fill={color}/>
        </svg>
        {value}
      </>}
    </RankContainer>
  )

  return NO_REVIEWS ? null : ReviewsLabel;
}

const RankContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  font-size:1rem;
  font-weight: 500;
  gap: 5px;
  line-height:17px;
  color:#0E1738;
  margin-right: 20px;
  width: fit-content;
`;