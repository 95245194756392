import { Box } from "@mui/material";

export function HideOnDesktop({ children }): JSX.Element {
  return (
    <Box sx={{ display: { sm: 'none', xs: 'block' } }} >
      {children}
    </Box>
  )
}
export function ShowOnDesktop({ children }): JSX.Element {
  return (
    <Box  sx={{ display: { sm: 'block', xs: 'none' } }} >
      {children}
    </Box>
  )
}