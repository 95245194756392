import {
  AttractionStates,
  BookedStatuses,
  Directions,
  INTERESTS,
  Langs,
  OnBoardingStates,
  TRIP_ITEMS,
  TRIP_TYPES,
} from './enums';
import TripTypesLang from '../../assets/static/tripTypes/tripTypes';
import TripInterestsLang from '../../assets/static/tripInterests/tripInterests';
import Item from '../../components/pages/Item/Item';
import Commute from '../../components/pages/Feed/Commute';
import { Icons } from '../../assets/images/images';
import AccomandationItem from '../../components/shared/AccomandationItem';

export const LanguagesDic: { [key in Langs]: Directions } = {
  [Langs.hebrew]: Directions.RightToLeft,
  [Langs.english]: Directions.LeftToRight,
};

export const TripTypesDic: { [key in TRIP_TYPES]: string } = {
  [TRIP_TYPES.Leisurely]: 'Leisurely',
  [TRIP_TYPES.Moderate]: 'Moderate',
  [TRIP_TYPES.SeeItAll]: 'See-it-all',
};

export const tripTypesLangsDic: { [key in Langs]: any } = {
  [Langs.english]: TripTypesLang.English,
  [Langs.hebrew]: TripTypesLang.Hebrew,
};

export const InterestsLangDic = {
  [Langs.english]: TripInterestsLang.English,
  [Langs.hebrew]: TripInterestsLang.Hebrew,
};

export const OnBoardingStatesDic = {
  [OnBoardingStates.WelcomePage]: 'Welcome',
  [OnBoardingStates.TripPlanningPage]: 'Planning',
  [OnBoardingStates.TripTypesPage]: 'Pace',
  [OnBoardingStates.InterestsPage]: 'Interests',
};

export const interestsDic: { [key in INTERESTS]: string } = {
  [INTERESTS.Art]: 'Art',
  [INTERESTS.Shopping]: 'Shopping',
  [INTERESTS.HiddenGems]: 'Hidden Gems',
  [INTERESTS.HistoricSites]: 'Historic Sites',
  [INTERESTS.Nightlife]: 'Nightlife',
  [INTERESTS.Beach]: 'Beach',
  [INTERESTS.Watersports]: 'Watersports',
  [INTERESTS.Architecture]: 'Architecture',
  [INTERESTS.Nature]: 'Nature',
  [INTERESTS.Museums]: 'Museums',
  [INTERESTS.SportingEvents]: 'Sporting Events',
  [INTERESTS.Popular]: 'Popular',
  [INTERESTS.WalkingAndBiking]: 'Walking & Biking',
  [INTERESTS.UrbanParks]: 'Urban Parks',
  [INTERESTS.Religion]: 'Religion',
  [INTERESTS.ShowsAndPerformances]: 'Shows / Performances',
  [INTERESTS.Festivals]: 'Festivals',
  [INTERESTS.Cuisine]: 'Cuisine',
  [INTERESTS.Amusements]: 'Amusements',
  [INTERESTS.OutdoorActivities]: 'Outdoor Activities',
  [INTERESTS.Culture]: 'Culture',
  [INTERESTS.LocalEvents]: 'Local Events',
  [INTERESTS.LocalMarkets]: 'Local Markets',
  [INTERESTS.StreetFood]: 'Street Food',
  [INTERESTS.GuidedTours]: 'Guided Tours',
  [INTERESTS.LGBT]: 'LGBT',
  [INTERESTS.Music]: 'Music',
  [INTERESTS.WellnessAndWellbeing]: 'Wellness and Wellbeing',
};

// interface ItripItem {
//   component: any;
//   convertFunction: any;// Covert response from the server into a proper JS object with the relevant properties

// }

// //each attraction owns a 'type' field (1-3)
// export const TripItemsDic: { [key in TRIP_ITEMS]: ItripItem } = {
//   [TRIP_ITEMS.Attraction]: {
//     component: Attraction,
//     convertFunction: convertResponseToAttraction,
//   },
//   [TRIP_ITEMS.Restaurant]: {
//     component: Restaurant,
//     convertFunction: convertResponseToRestaurant,
//   },
//   [TRIP_ITEMS.Commute]: {
//     component: Commute,
//     convertFunction: convertResponseToCommute,
//   },
// };

// interface IClientsProperties {
//   startingPage: OnBoardingStates;
//   accommodation_name?: string;
//   accommodation_address?: string;
// }

interface ItripItem {
  component: any;
  icon: string;
  // convertFunction: any;// Covert response from the server into a proper JS object with the relevant propertie
}
const { landmark, restaurant, car, hotel } = Icons;

export const TripItemsDic: { [key in TRIP_ITEMS | 'HOTEL']: ItripItem } = {
  [TRIP_ITEMS.Attraction]: {
    component: Item,
    icon: landmark,
    // convertFunction: convertResponseToAttraction,
  },
  [TRIP_ITEMS.Restaurant]: {
    component: Item,
    icon: restaurant,
    // convertFunction: convertResponseToRestaurant,
  },
  [TRIP_ITEMS.Commute]: {
    component: Commute,
    icon: car,
    // convertFunction: convertResponseToCommute,
  },
  HOTEL: {
    component: AccomandationItem,
    icon: hotel,
    // convertFunction: convertResponseToCommute,
  },
};

export const CurrencySymbolMap: { [key in TCurrencies]: string } = {
  EUR: '€',
  USD: '$',
  GBP: '£',
  CHF: 'CHF',
  CAD: 'C$',
  AUD: 'AU$',
  AED: 'د.إ',
  ARS: 'ARS',
  AZN: 'ман',
  BGN: 'лв',
  BHD: '.د.ب',
  CLP: 'CLP$',
  CNY: '¥',
  COP: 'COL$',
  CVE: 'Esc',
  CZK: 'Kč',
  DKK: 'DKK',
  DOP: 'RD$',
  EGP: 'ج.م',
  FJD: 'FJ$',
  GEL: 'ლ',
  HKD: 'HK$',
  HRK: 'kn',
  HUF: 'Ft',
  IDR: 'Rp',
  ILS: '₪',
  INR: '₹',
  ISK: 'Íkr',
  JMD: 'J$',
  JOD: 'JD',
  JPY: '¥',
  KRW: '₩',
  KWD: 'د.ك',
  KZT: '₸',
  MDL: 'L',
  MXN: 'Mex$',
  MYR: 'RM',
  NAD: 'N$',
  NOK: 'NOK',
  NZD: 'NZ$',
  OMR: 'ر.ع.',
  PLN: 'zł',
  QAR: 'ر.ق',
  RON: 'lei',
  RUB: 'руб',
  SAR: '﷼',
  SEK: 'kr',
  SGD: 'S$',
  THB: '฿',
  TND: 'د.ت',
  TRY: '₺',
  TWD: 'NT$',
  UAH: '₴',
  XOF: 'CFA',
  ZAR: 'R',
};

export const MusementToItemButtonStateMapper: { [key in BookedStatuses]: AttractionStates } = {
  [BookedStatuses.NotBooked]: AttractionStates.BookNow,
  [BookedStatuses.Booked]: AttractionStates.Navigate,
  [BookedStatuses.Pending]: AttractionStates.BookNow,
  [BookedStatuses.Cancelled]: AttractionStates.BookNow,
};

export const TimeUnitsDic = {
  D: 'day',
  H: 'hour',
  M: 'minute',
};


