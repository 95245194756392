import { useEffect, useState, useMemo } from "react";
import {useSelector, useDispatch} from 'react-redux';
import styled from "styled-components";
import {pageIndex} from '../../../Actions/onboardingActions';
import Interests from './Steps/Interests';
import GetStarted from './Steps/GetStarted';
import TripDetails from './Steps/TripDetails';
import Stepper from './OnboardingComponents/Stepper';
import TermsDialog from '../Terms/TermsDialog'
import BottomModal from "./OnboardingComponents/BottomModal";
import { Button } from '@rmwc/button';
import '@rmwc/textfield/styles';
import ArrowSVG from "../../../assets/images/icons/arrow.svg";
import Loading from "./OnboardingComponents/Loading";
import {useConvertOnBoarding} from './convertOnBoarding'
import useResponsive from "../../hooks/useResponsive";
import useWindowSize from "../../hooks/useWindowSize";
import Mixpanel, { MP_Events } from "../../../core/services/mixpanel";
import interestsJSON from "../../../assets/static/tripInterests/en_interests.json";
import ParamsService from "../../../core/services/params";

// Already have a trip components
import WelcomeBack from "./AlreadyHaveATrip/WelcomeBack";
import TravelerItineraries from "./AlreadyHaveATrip/TravelerItineraries";

const Onboarding = () => {
  const [canContinue, setCanContinue] = useState<boolean>(false);
  const [modalHasTrip, setModalHasTrip] = useState<boolean>(false);
  const [openTerms, setOpenTerms] = useState<boolean>(true);
  const [spinner, setSpinner] = useState<boolean>(false);

  const onboarding = useSelector((state: any) => state.onboarding);
  const {design} = useSelector((state: any) => state.clientDesign);

  const dispatch = useDispatch();

  const {finishTrip} = useConvertOnBoarding();
  const responsive = useResponsive(580);
  const [windowHeight] = useWindowSize();

  const handleCloseTerms = () => {
    setOpenTerms(false);
  }

  const closeHasATrip = () => {
    setModalHasTrip(false);
  }

  const mixpanelConfig = useMemo(() => ({
    client: onboarding.selected_hotel?.city.name || onboarding.selected_hotel?.city,
    property: ParamsService.getProperty() || "",
    interests: Object.values(interestsJSON)
      .filter((interest) => onboarding.selected_interests
        .map(group_cat => group_cat.join())
        .includes(interest.group_cat.join())
      ).map(interest => interest.title),
    pace: onboarding.tripPace,
    status: onboarding.pageIndex >= 2 ? "yes" : "no"
  }), [onboarding]);

  const nextPage = async() => {
    if(onboarding.pageIndex === 2) {
      setSpinner(true);
      Mixpanel.track(MP_Events.Onboarding, mixpanelConfig);
      await finishTrip();
      setSpinner(false);
    }
    dispatch(pageIndex(onboarding.pageIndex + 1));
  }

  const prevPage = () => {
    dispatch(pageIndex(onboarding.pageIndex - 1));
  }

  const handleCloseWindow = (e) => {
    e.preventDefault();
    e.returnValue = '';
    Mixpanel.track(MP_Events.Onboarding, mixpanelConfig);
  }

  useEffect(() => {
    window.addEventListener("beforeunload", handleCloseWindow);
    return () => window.removeEventListener("beforeunload", handleCloseWindow);
  }, []);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0
    });
  }, [onboarding.pageIndex]);

  useEffect(() => {
    setCanContinue(false);
    switch(onboarding.pageIndex) {
      case 0:
        setCanContinue(true);
        break;
      case 1:
        if(
          onboarding.selected_hotel &&
          onboarding.travelers.length > 0 &&
          (onboarding.dateRange[0] && onboarding.dateRange[1])
        ) {
          setCanContinue(true);
        }
        break;
      case 2:
        if(onboarding.selected_interests.length > 0) {
          setCanContinue(true);
        }
        break;
      default:
        setCanContinue(false);
    }
  }, [onboarding]);

  return (
    <Container className="onboarding-container">
      <TopContainer>
        <PrevBtn
          src={ArrowSVG}
          style={{
            width: 30,
            visibility: onboarding.pageIndex === 0 ? "hidden" : "visible",
            pointerEvents: onboarding.pageIndex === 0 ? "none" : "all"
          }}
          onClick={prevPage}
        />
        <Logo src={design?.Logo} alt="" />
        {!responsive && <span></span>}
      </TopContainer>
      {onboarding.pageIndex === 0 && <GetStarted />}
      {onboarding.pageIndex === 1 && <TripDetails />}
      {onboarding.pageIndex === 2 && <Interests />}
      <FooterContainer>
        {!localStorage.getItem("accepted_terms") && <TermsDialog openTerms={openTerms} handleCloseTerms={handleCloseTerms}/>}
        <Button 
          className="mdc-button mdc-button--raised material-button" 
          style={{
            backgroundColor: design?.PrimaryColor?.bgColor,
            opacity: !canContinue ? .5 : 1,
            pointerEvents: !canContinue ? "none" : "all",
            marginTop: 5
          }} 
          onClick={nextPage}
        >
          Next
        </Button>
        {onboarding.pageIndex === 0 && <HasTrip onClick={() => setModalHasTrip(true)}>I already have an itinerary</HasTrip>}
        <Stepper color={design?.PrimaryColor?.bgColor} />
      </FooterContainer>
      {modalHasTrip && <BottomModal openBottomModal={modalHasTrip} closeBottomModal={closeHasATrip}>
        {onboarding.hasTripPageIndex === 0 && <WelcomeBack />}
        {onboarding.hasTripPageIndex === 1 && <TravelerItineraries />}
      </BottomModal>}
      {spinner && <Loading logo={design?.Logo} color={design?.PrimaryColor?.bgColor} text="We are building an amazing itinerary for you" />}
    </Container>
  )
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: 100vh;
  margin: 0;
  background-color: white;
  overflow-x: hidden;
`;

const TopContainer = styled.div`
  display: flex;
  width: 90vw;
  padding: 20px;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 0rem;
`;

const FooterContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    width: 100%;
    height: fit-content;
`;

const Logo = styled.img`
  width: 80px;
  height: auto;
  text-align: center;
`;

const PrevBtn = styled.img`
  font-size: 22px;
  cursor: pointer;
`;

const HasTrip = styled.p`
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
`;

export default Onboarding;

