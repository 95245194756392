import { useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {useSelector, useDispatch} from 'react-redux'
import DatePicker from '../OnboardingComponents/DatePicker'
import '@rmwc/textfield/styles';
import TravelerTypes from '../OnboardingComponents/TravelerTypes';
import PaceSlider from '../OnboardingComponents/PaceSlider';
import HotelsList from '../OnboardingComponents/HotelsList';
import { LocationSVG, CalendarSVG, ManSVG } from "../../../../assets/images/svgs";
import StorageService from '../../../../core/services/storage';
import { setSelectedHotel, saveDateRange } from '../../../../Actions/onboardingActions';
import toast from 'react-hot-toast';

const TripDetails = () => {
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);

  const onboarding = useSelector((state: any) => state.onboarding);
  const {design} = useSelector((state: any) => state.clientDesign);
  const dispatch = useDispatch();

  const property = localStorage.getItem("property") || "";
  const hotelByProp = StorageService.local.get.item('hotel');

  useEffect(() => {
    if(hotelByProp) {
      dispatch(setSelectedHotel(hotelByProp));
    }
  }, []);

  const handleRange = (dates) => {
    try{
      const formatDay1 = moment(dates[0]).utc().format('DD/MM/YYYY');
      const formatDay2 = moment(dates[1]).utc().format('DD/MM/YYYY');
      localStorage.setItem('selected_dates', JSON.stringify([formatDay1, formatDay2]));
      dispatch(saveDateRange([dates[0]?.toDateString(), dates[1]?.toDateString()]));
      setShowDatePicker(false);
    }catch{
      toast.error('Oops! We had trouble by getting the dates. Please try again!');
    }
  }

  const handleChangeRange = (dates) => {
    try{
      dispatch(saveDateRange([dates[0]?.toDateString(), dates[1]?.toDateString()]));
    }catch{
      toast.error('Oops! We had trouble by getting the dates. Please try again!');
    }
  }

  return (
    <Container>
      <BoxContainer>
        <Column>
          <TopWrapper>
            {LocationSVG(20, design?.PrimaryColor?.bgColor)}
            <Title>Where?</Title>
          </TopWrapper>
          <Subtitle>{property ? property : "Select your destination"}</Subtitle>
        </Column>
        {!property && <HotelsList />}
      </BoxContainer>
      <BoxContainer>
        <Column>
          <TopWrapper>
            {CalendarSVG(20, design?.PrimaryColor?.bgColor)}
            <Title>When?</Title>
          </TopWrapper>
          <Subtitle>Select start and end dates</Subtitle>
        </Column>
        <DatesContainer>
          <DateBox onClick={() => setShowDatePicker(true)} color={design?.PrimaryColor?.bgColor}>
            {onboarding.dateRange[0] ? moment(onboarding.dateRange[0]).utc().format('DD/MM/YYYY') : "Start Date"}
          </DateBox>
          <DateBox onClick={() => setShowDatePicker(true)} color={design?.PrimaryColor?.bgColor} style={{pointerEvents: !onboarding.dateRange[0] ? "none" : "all"}}>
            {onboarding.dateRange[1] ? moment(onboarding.dateRange[1]).utc().format('DD/MM/YYYY') : "End Date"}
          </DateBox>
        </DatesContainer>
        {showDatePicker && <DatePicker 
          fromDate={new Date()}
          // max={5}
          color={design?.PrimaryColor?.bgColor} 
          range={[new Date(onboarding.dateRange[0]) || null, onboarding.dateRange[1] ? new Date(onboarding.dateRange[1]) : null]}
          onRange={handleRange}
          onChange={handleChangeRange}
          openDatePicker={showDatePicker} 
          handleCloseDatePicker={() => setShowDatePicker(false)} 
        />}
      </BoxContainer>
      <BoxContainer>
        <Column>
          <TopWrapper>
            {ManSVG(17, design?.PrimaryColor?.bgColor)}
            <Title>Travelers</Title>
          </TopWrapper>
          <Subtitle>Who will you traveling with?</Subtitle>
        </Column>
        <TravelerTypes />
      </BoxContainer>
      <BoxContainer>
        <TopWrapper>
          <Title>What's the trip pace?</Title>
        </TopWrapper>
        <PaceSlider color={design?.PrimaryColor?.bgColor} />
      </BoxContainer>
    </Container>
  )
}

const Container = styled.div`
  padding: 40px 80px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  border: 1px solid #2C5282;
  border-radius: 8px;
  animation: leftFadeIn 300ms ease;
  -webkit-animation: leftFadeIn 300ms ease;
  margin: 60px;
  gap: 40px;

  @media screen and (max-width: 580px) {
    padding: 0;
    margin: 0;
    width: 80vw;
    height: 100%;
    justify-content: space-between;
    margin-bottom: 0;
    border: none;
    gap: 0;
  }
  
  @keyframes leftFadeIn {
    from {
      transform: translateX(100%);
      -webkit-transform: translateX(100%);
    }
    to {
      transform: translateX(0);
      -webkit-transform: translateX(0);
    }
  }
`;

const BoxContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
  gap: 80px;

  :last-child {
    margin-bottom: 0;
  }

  :only-child {
    background-color: red; 
  }

  @media screen and (max-width: 580px) {
    flex-direction: column;
    justify-content: center;
    gap: 0;
  }
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

const Column = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`;

const Title = styled.h3`
  margin: 0;
  white-space: nowrap;

  @media screen and (max-width: 580px) {
    white-space: initial;
  }
`;

const Subtitle = styled.p`
  margin-bottom: 0;
  margin-top: 5px;
  white-space: nowrap;

  @media screen and (max-width: 580px) {
    white-space: initial;
  }
`;

const DatesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  gap: 20px;
`;

const DateBox = styled.div`
  padding: 0 30px 0 10px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #1E293B;
  border-radius: 8px;
  cursor: pointer;
`;

export default TripDetails;