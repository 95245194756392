import { Langs } from '../utils/enums';

const params = new URLSearchParams(window.location.search);

const getRedirectionSource = (): string | null => params.get('rd') || null;

const getToken = (): string | null => params.get('rt') || null;
const getTEST = (): boolean => params.get('test') !== null;

const getPresent = (): boolean => {
  return params.get('present') !== null;
};
const getReusable = (): boolean => params.get('reusable') !== null;

const getInHouseTest = (): boolean => {
  const test = !!params.get("inhousetest");
  if(test) {
    localStorage.setItem("inhousetest", "1");
  }
  return test;
};

//@ts-ignore
const getLanguage = (): Langs => params.get('lng') || null;

const getClientParam = (): string | null => params.get('client');
const getPropertyParam = (): string | null => params.get('property');
const getPropertyIdParam = (): string | null => params.get('property_id');
const getItineraryParam = (): string | null => params.get('rt');

export function getClientSubdomain(): string | null {
  let hostname = window.location.hostname;
  const URLArray = hostname.split('.'); //(5) ["renas", "dev", "bridgify", "io"] or ["renas", "bridgify", "io"]
  let ClientName = URLArray[0]; //renas in both cases (prod & dev)
  return ClientName !== 'app' && ClientName !== 'localhost' ? ClientName : null;
}

// changing client based on the hostname
function getClient(): string | undefined {
  let finalClientName: string | null = null;
  finalClientName=getClientParam()
  return finalClientName!;
}
function getProperty(): string | undefined {
  let finalProperty: string | null;
  finalProperty = getPropertyParam();
  if(finalProperty) {
    localStorage.setItem('property', finalProperty);
  }
  return finalProperty!;
}

function getPropertyId(): string | null {
  let finalProperyId: string | null = null;
  finalProperyId = getPropertyIdParam();

  if(finalProperyId) {
    localStorage.setItem('propertyID', finalProperyId);
  }

  return finalProperyId;
}

function getItineraryId(): string | null {
  let itineraryId: string | null = null;
  itineraryId = getItineraryParam();

  if(itineraryId) {
    localStorage.setItem('rt', itineraryId);
  }

  return itineraryId;
}
const ParamsService = {
  getToken,
  getLanguage,
  getClient,
  getRedirectionSource,
  getTEST,
  getPresent,
  getInHouseTest,
  getReusable,
  getProperty,
  getPropertyId,
  getItineraryId
};

export default ParamsService;