import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@rmwc/button";
import styled from "styled-components";
import '@rmwc/textfield/styles';

import { pageVerificationEmail } from "../../../../Actions/emailVerification";

const VerificationSuccess = ({closeBottomModal}) => {
  const {design} = useSelector((state: any) => state.clientDesign);

  const dispatch = useDispatch();

  useEffect((): any => {
    return () => dispatch(pageVerificationEmail(0));
  }, []);

  return <Container>
    <HelloIMG src={"https://bridgify-frontend-s3.s3.eu-central-1.amazonaws.com/Trip-Trailer-Vector.png"} alt="" />
    <Title>Great! We are all set!</Title>
    <Paragraph>
    Your trip itinerary is now confirmed, check your email to see all the details.
    </Paragraph>
    <Button 
      className="mdc-button mdc-button--raised material-button"
      style={{backgroundColor: design?.PrimaryColor?.bgColor}}
      onClick={closeBottomModal}
    >
      Awesome
    </Button>
  </Container>
}

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const HelloIMG = styled.img`

`;

const Title = styled.h3`
  text-align: center;
  margin-bottom: 0;
`;

const Paragraph = styled.p`
  width: 80%;
  text-align: center;
`;

export default VerificationSuccess;