import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import ReactDOM from 'react-dom';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit'
import allReducers from './Reducers'

import './index.css';
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://ab7ee45282584ae3ad7b8a6ce7f1fadf@o508821.ingest.sentry.io/5864583',
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.NODE_ENV || 'test',
    // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
    tracesSampleRate: 1.0,
  });
}

const store = configureStore({
  reducer: allReducers,
  devTools: true
});

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
      <Toaster />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);