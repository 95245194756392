export const openVerificationModal = (payload) => {
    return {
        type: "OPEN_MODAL",
        payload
    }
}
export const pageVerificationEmail = (payload) => {
    return {
        type: "PAGE_VERIFICATION_INDEX",
        payload
    }
}
export const setEmailVerification = (payload) => {
    return {
        type: "EMAIL",
        payload
    }
}
export const setTraveler = (payload) => {
    return {
        type: "TRAVELER_ID",
        payload
    }
}
export const setItinerary = (payload) => {
    return {
        type: "ITINERARY_ID",
        payload
    }
}
