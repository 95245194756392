import React from 'react';
import {useDispatch, useSelector} from 'react-redux'
import styled from "styled-components";
import Button from '@mui/material/Button';

interface IProps {
    openTerms: boolean;
    handleCloseTerms: () => void;
}

const Terms = ({handleCloseTerms}: IProps) => {
  const {design} = useSelector((state: any) => state.clientDesign);
  const handleAcceptTerms = () => {
    localStorage.setItem("accepted_terms", "1");
    handleCloseTerms()
  }

  return (
        <Background>
            <Container>
                <Logo src={design?.Logo} />
                <TextTerms>By clicking Agree and Continue, I hereby:</TextTerms>
                <div>
                <TextTerms>Agree and consent to the <LinkText>User Agreement</LinkText> it's policies and <LinkText>Privacy Policy</LinkText></TextTerms>
                </div>
                <TextTerms>Specifically, and expressly consent to the use of website tracking methods, including cookies and to the safe and secure transmission of your personal outside the European Economic Area in accordance with the Privacy Policy</TextTerms>
                <ButtonTerms onClick={handleAcceptTerms} variant="contained" style={{backgroundColor: design?.PrimaryColor?.bgColor}}>
                    Agree and continue
                </ButtonTerms>
            </Container>
        </Background>
  );
}

const Container = styled.div`
    padding: 20px;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    z-index: 2000;
    display: flex;
    width: 500px;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media screen and (max-width: 580px) {
        width: 85%;
    }
`;
const Background = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  z-index: 1900;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, .4);
`;
const TextTerms = styled.p`
    text-align: center;
    justify-content: center;
    margin: 10px;
    
    @media screen and (max-width: 580px) {
        margin: 5px;
    }
`;

const ButtonTerms = styled(Button)`
    display: flex;
    position: absolute;
    bottom: -30px;
    width: 252px;
    height: 56px;
    justify-content: center;
    align-items: center;
    text-align: center;
    justify-self: center;
    z-index: 2000;
    border-radius: 16px;
`

const LinkText = styled.a`
    text-decoration: underline;
`
const Logo = styled.img`
    height: 80px;
    width: 80px;
    margin-bottom: 20px;
    object-fit: contain;
`;

export default Terms;