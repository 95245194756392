import styled from 'styled-components';

//Explore Attraction Cards, with a converted data
export default function FeedFooter() {
  const PUBLIC_URL = process.env.PUBLIC_URL;
  const privacyUrl = 'https://terms-and-privacy.s3.eu-central-1.amazonaws.com/Bridgify+-+Privacy+Notice++20220411+final.pdf';
  const termsURL = 'https://terms-and-privacy.s3.eu-central-1.amazonaws.com/Bridgify+-+Terms+of+Service+Leonardo+20220508.pdf';
  return (
    <Container>
      {/* <HR /> */}
      <TermsOfUse>
        <Alink target="_blank" rel="noopener noreferrer" href={termsURL}>
          Terms Of Service
        </Alink>
        &nbsp;&nbsp;&#8226;&nbsp;&nbsp;
        <Alink target="_blank" rel="noopener noreferrer" href={privacyUrl}>
          Privacy Policy
        </Alink>
      </TermsOfUse>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  font-weight: 300;
  color: gray;
`;

const TermsOfUse = styled.div`
  text-align: center;
`;

const Alink = styled.a`
  font-size: 0.8rem;
  text-decoration: none;
  color: gray;
`;

const HR = styled.hr`
  width: 30%;
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
`;