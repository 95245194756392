const initialState = {
  pageIndex: 0,
  selected_hotel: null,
  selected_interests: [],
  tripPace: 1,
  travelers: [],
  hasTrip: false,
  dateRange: [null, null],
  terms: false,
  hasTripPageIndex: 0,
  travelerItineraries: [],
}

function reducer(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case 'HOTEL_SELECTED': 
      return { ...state, selected_hotel: payload };
    case 'INTERESTS':
      const interests: any = [...state.selected_interests];
      const itemIndex = interests.findIndex((item) => item === payload);
      interests.includes(payload) ? interests.splice(itemIndex, 1) : interests.push(payload);
      return { ...state, selected_interests: interests }; 
    case 'PAGE_INDEX':
      return { ...state, pageIndex: payload};
    case 'TRIP_PACE':
      return { ...state, tripPace: payload};
    case 'TRAVELERS':
      const travelers: any = [...state.travelers];
      const travelerIndex = travelers.findIndex((item) => item === payload);
      travelers.includes(payload) ? travelers.splice(travelerIndex, 1) : travelers.push(payload);
      return { ...state, travelers: travelers };
    case 'HAS_TRIP':
      return { ...state, hasTrip: !state.hasTrip };
    case 'DATE_RANGE':
      return { ...state, dateRange: payload };
    case 'TERMS':
      return { ...state, terms: payload };
    case 'HAS_TRIP_PAGE_INDEX':
      return { ...state, hasTripPageIndex: payload };
    case 'TRAVELER_INTINERARIES':
      return { ...state, travelerItineraries: payload };
    default:
      return state;
  }
}

export default reducer;