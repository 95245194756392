import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

import LogoSVG from "../../../assets/images/bridgify-logo.svg";
import TermsBackgroundJPG from "../../../assets/images/terms_background.jpg";

const Terms = () => {
  const location = useLocation<any>();

  const handleAcceptTerms = () => {
    localStorage.setItem("accepted_terms", "1");
  }

  const isLeonardo = location.state.leonardo;

  return(
    <Container>
      <Logo src={LogoSVG} alt="logo" />
      <Background src={TermsBackgroundJPG} alt="" />
      <Title>Your Personalised Trip Itinerary</Title>
      <TermsAndPrivacyBox >
        <TermsAndPrivacyTitle>By clicking Agree and Continue, I hereby:</TermsAndPrivacyTitle>
        <Ul>
          <ListItem>
            Agree and consent to the <a href={`https://terms-and-privacy.s3.eu-central-1.amazonaws.com/Bridgify+-+Terms+of+Service+Leonardo+20220508${isLeonardo ? " leonardo" : ""}.pdf`} target="_blank">User Agreement</a> it's policies and <br /> 
            <a href={`https://terms-and-privacy.s3.eu-central-1.amazonaws.com/Bridgify+-+Privacy+Notice++20220411+final${isLeonardo ? " leonardo" : ""}.pdf`} target="_blank">Privacy Policy</a>
          </ListItem>
          <ListItem>
            Specifically, and expressly consent to the use of website<br />
            tracking methods, including cookies and to the safe and secure<br />
            transmission of your personal outside the European Economic<br />
            Area in accordance with the Privacy Policy
          </ListItem>
        </Ul>
        <Link to="/onboarding" onClick={handleAcceptTerms}>
          <Button>Agree and Continue</Button>
        </Link>
      </TermsAndPrivacyBox>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  margin: 0 auto;
`;

// const DynamicImage = styled.img`
//   object-fit: cover;
//   width: 100%;
//   height: 200px;
//   z-index: 999;
// `;

const Background = styled.img`
  opacity: .4;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  pointer-events: none;
  z-index: -1;
`;

const Title = styled.h1`
  margin-bottom: 20px;
  font-size: 28px;
  font-weight: 600;
  color: #330e5a;
  text-align: center;

  @media screen and (max-width: 580px) {
    font-size: 18px;
    margin-bottom: 10px;
  }
`;

const TermsAndPrivacyBox = styled.div`
  padding: 20px;
  border: 2px solid #330e5a;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;

  @media screen and (max-width: 580px) {
    width: 85vw;
  }
`;

const TermsAndPrivacyTitle = styled.p`
  width: 100%;
  text-align: left;
  margin: 5px;
  @media screen and (max-width: 580px) {
    font-weight: 700;
  }
`;

const ListItem = styled.li`
  line-height: 30px;
  margin-bottom: 20px;
  && a {
    text-decoration: underline;
    color: black;
  }

  @media screen and (max-width: 580px) {
    text-align: center;
    list-style: none;
    line-height: 24px;
  }
`;

const Button = styled.button`
  background-color: #330e5a;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  color: #fff;
`;

const Logo = styled.img`
  position: absolute;
  width: 200px;
  top: 20px;
  left: 20px;

  @media screen and (max-width: 580px) {
    width: 130px;
  }
`;

const Ul = styled.ul`
  padding-left: 40px;
  @media screen and (max-width: 580px) {
    padding-left: 0;
  }
`;

export default Terms;