import BackIcon from '@mui/icons-material/ArrowBackIos';
import { AppBar, Dialog, Slide, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { TransitionProps } from '@mui/material/transitions';
import { forwardRef, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { Icons } from '../../assets/images/images';
import tripInterests from '../../assets/static/tripInterests/tripInterests';
import { replaceEventNewApi } from '../../core/services/trip';
import { clearHash } from '../../core/utils/common';
import { BookedStatuses } from '../../core/utils/enums';
import { useTripDetails } from '../../providers/TripDetailsProvider';
import { FeedProvider, useFeed } from './Feed/useFeed';
import Item from './Item/Item';

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

interface IProps {
  openReplaceAttrDialog: boolean;
  handleReplace: (e: any) => void;
  handleRemove: (e: any) => void;
  handleSelectToAdd: (e: any) => void;
  handleCloseExplore: () => void;
  handleCloseReplaceAttr: () => void;
  handleOpenExplore: () => void;
}

export default function ReplaceAttrDialog({
  openReplaceAttrDialog,
  handleReplace,
  handleRemove,
  handleSelectToAdd,
  handleCloseExplore,
  handleCloseReplaceAttr,
  handleOpenExplore,
}: IProps) {
  const history = useHistory();
  const { tripDetails, updateTripDetails } = useTripDetails();
  const {
    itemsIDs,
    tripPreferences: { interests },
  } = tripDetails;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    state: { currentDayIndex, selectedReplace, selectedAddToTrip },
    dispatch,
  } = useFeed();

  const [loading, setLoading] = useState<boolean>(true);
  const [showFavorites, setShowFavorites] = useState<boolean>(false);
  const [selectedInterest, setSelectedInterest] = useState<number>(interests[0]);
  const [selectedEvent, setSelectedEvent] = useState<any>();
  const [groupAttractionByCat, setGroupAttractionByCat] = useState<any>();
  const [openReplaceDialog, setOpenReplaceDialog] = useState<boolean>(false);

  useEffect(() => {
    //  let token = localStorage.getItem('OauthToken')

    if (openReplaceAttrDialog) {
      getData();
      setLoading(false);
    } else {
      setLoading(true);
      setGroupAttractionByCat([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openReplaceAttrDialog]);

  const getData = async () => {
    let mapEvents = JSON.parse(localStorage.getItem('trip')!).sections[currentDayIndex].events;
    let convertedAttraction = convertAttractionToOld(mapEvents);
    groupCategoriesAtr(convertedAttraction);
    if (convertedAttraction) {
      setLoading(false);
    }
  };

  const convertAttractionToOld = (atr) => {
    try {
      console.log(atr);
      const oldAttractions: ITripItem_Attraction[] = atr
        .filter((atr) => atr.type !== 'CMT')
        .map((a) => {
          let tags = [tripInterests!.English[a.categories[0]]?.title];
          return {
            _id: a.id,
            _eventID: '',
            type: 1,
            source: a.inventory_supplier,
            location: { lat: a.starting_geolocation.lat, lng: a.starting_geolocation.lng },
            address: a.address,
            name: a.title,
            rank: { value: Number(a.rating), count: Number(a.number_of_reviews) },
            about: a.description,
            tags: tags,
            imgURL: a.main_photo_url,
            orderPage: a.order_webpage,
            isFree: false,
            uuid: a.place_uuid,
            placeUUID: a.place_uuid,
            bookingStatus: a.was_booked,
            pickup: false,
            activityDatetime: '',
            orderUuid: '',
            cardBorderColor: 3,
          };
        });
      return oldAttractions;
    } catch (err) {
      console.log(err);
    }
  };

  const groupCategoriesAtr = (atr) => {
    let filteredAtr = atr.filter((a) => a.tags.length > 0);
    let justFirstTag = filteredAtr.map((a) => {
      const firstTag = a.tags[0];
      a.tags = [firstTag];
      return a;
    });
    let grupealos = groupByKey(justFirstTag, 'tags');
    const arr = Object.entries(grupealos);
    setGroupAttractionByCat(arr);
  };

  function groupByKey(array, key) {
    return array.reduce((hash, obj) => {
      if (obj[key] === undefined) return hash;
      return Object.assign(hash, { [obj[key]]: (hash[obj[key]] || []).concat(obj) });
    }, {});
  }

  function handleBack() {
    // dispatch({ type: 'TOGGLE_SHOW_ATR_DIALOG', payload: false });
    handleCloseReplaceAttr()
    handleCloseExplore()
    clearHash();
    // history.goBack();
  }

  function isAttractionAlreadyInTrip(attractionID: number) {
    return itemsIDs.includes(attractionID);
  }

  return (
    <Dialog
      keepMounted 
      fullScreen={fullScreen}
      maxWidth="xl"
      open={openReplaceAttrDialog}
      onBackdropClick={handleBack}
      TransitionComponent={Transition}
    >
      <StyledAppBar>
        <StyledToolbar>
          <TitleAndBackBtn>
            <StyledBackIcon onClick={handleBack} />
            <div>Select activity to remove</div>
          </TitleAndBackBtn>

        </StyledToolbar>
      </StyledAppBar>
      <Container>
        {loading ? (
          <div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Skeleton
                variant="rectangular"
                height={30}
                width={100}
                animation="wave"
                style={{ borderRadius: '10px', marginRight: '5px' }}
              />
              <Skeleton
                variant="rectangular"
                height={30}
                width={100}
                animation="wave"
                style={{ borderRadius: '10px', marginRight: '5px' }}
              />
              <Skeleton
                variant="rectangular"
                height={30}
                width={100}
                animation="wave"
                style={{ borderRadius: '10px', marginRight: '5px' }}
              />
            </div>
            <SkeletonCardsContainer>
              <br />
              <Skeleton
                variant="rectangular"
                height={300}
                width={300}
                animation="wave"
                style={{ borderRadius: '10px' }}
              />
              <br />
              <Skeleton
                variant="rectangular"
                height={300}
                width={300}
                animation="wave"
                style={{ borderRadius: '10px' }}
              />
              <br />
              <Skeleton
                variant="rectangular"
                height={300}
                width={300}
                animation="wave"
                style={{ borderRadius: '10px' }}
              />
            </SkeletonCardsContainer>
            {/* <CircularProgress /> */}
          </div>
        ) : (
          <>
            {groupAttractionByCat?.map((a) => (
              <InterestsSliderContainer key={`interest${a[0]}`}>
                <InterestTitle>{a[0]}</InterestTitle>
                <ItemsSlider>
                  {a[1].map((b) => (
                    <Item
                      handleOpenExplore={handleOpenExplore}
                      handleSelectToAdd={handleSelectToAdd}
                      item={b}
                      handleRemove={handleRemove}
                      handleReplace={handleReplace}
                      key={`atr${b._id}`}
                      componentLocation={'Explore'}
                      isAttractionAlreadyInTrip={true}
                    />
                  ))}
                </ItemsSlider>
              </InterestsSliderContainer>
            ))}
          </>
        )}
      </Container>
    </Dialog>
  );
}
const ItemsSlider = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-x: scroll;
`;

const InterestsSliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
`;
const TitleAndBackBtn = styled.div`
  display: flex;
  flex-direction: row;
`;
const FavoritesButton = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
`;
const StyledToolbar = styled(Toolbar)`
  && {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;
const WishlistLabel = styled.div`
  font-size: 0.8rem;
  margin-right: 5px;
`;
const SkeletonCardsContainer = styled.div`
  margin: 0px auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const StyledAppBar = styled(AppBar)`
  && {
    background-color: ${(p) => p.theme.primary.bgColor};
    color: ${(p) => p.theme.primary.textColor};
    font-size: 1.2rem;
    text-align: center;
    position: sticky;
  }
`;
const Container = styled.div`
  padding: 0.5rem;
  overflow: scroll;
  box-sizing: border-box;
  min-height: 80vh;
  min-width: 300px;
  width: 100%;
  align-items: center;
`;

const InterestTitle = styled.h3`
  margin-left: 1rem;
`;
const StyledBackIcon = styled(BackIcon)`
  && {
    color: white;
    cursor: pointer;
  }
`;

