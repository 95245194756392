import { TRIP_ITEMS } from './enums';

export const navigationHandler = (location: ILocation) => {
  const { lat, lng } = location;
  const appleDevices = ['iPhone', 'iPad', 'iPod'];
  const linkPrefix = appleDevices.some((device) => navigator.platform === device)
    ? 'maps://maps'
    : 'https://www';

  window.open(`${linkPrefix}.google.com/maps?saddr=My+Location&daddr=${lat},${lng}`);
};

export function navigateToOrdersPage(orderPageLink: string) {
  window.open(orderPageLink);
}

export function scrollWindowToTop(): void {
  window.scrollTo(0, 0);
}

export function InsertAllTripItemsIDsIntoMap(TripDetails: any): Set<number> {
  let IDsSet = new Set();
  TripDetails.tripDays.forEach((tripDay: ITripDay) => {
    tripDay.items.forEach((item: ITripDay['items'][0]) => {
      if (item.type === TRIP_ITEMS.Attraction) {
        //@ts-ignore
        IDsSet.add(item._eventID);
      }
    });
  });
  //@ts-ignore
  return IDsSet;
}
