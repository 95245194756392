import BackIcon from '@mui/icons-material/ArrowBackIos';
import { AppBar, Dialog, Slide, Toolbar, useMediaQuery, useTheme } from '@mui/material';

import { TransitionProps } from '@mui/material/transitions';
import { forwardRef, useEffect, useState } from 'react';
import styled from 'styled-components/macro';

  interface IProps {
    item: any;
    openDialogBookedAttr: boolean;
    handleCloseBookedAttr: () => void;
  }
  export default function BookedAttrDialog({
    item,
    openDialogBookedAttr,
    handleCloseBookedAttr,
  }: IProps) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog
        fullScreen={fullScreen}
        maxWidth="xl"
        open={openDialogBookedAttr}
        onBackdropClick={handleCloseBookedAttr}

      >
        <StyledAppBar>
          <StyledToolbar>
            <TitleAndBackBtn>
              <StyledBackIcon onClick={handleCloseBookedAttr} />
              <div>Your Activity</div>
            </TitleAndBackBtn>
  
          </StyledToolbar>
        </StyledAppBar>
        <Container>
          
            <>
            <Content>
                <Img src={item.imgURL} alt=""></Img>
                
                <Title>{item.name}</Title>
                <Description>{item.about}</Description>
                <Address>Address: {item.address}</Address>
                <BtnContainer>
                  <ButtonTicketInfo href={item.orderPage} target="_blank">Ticket Information</ButtonTicketInfo>
                </BtnContainer>
            </Content>
            </>
        </Container>
      </Dialog>
    );
  }

  const TitleAndBackBtn = styled.div`
    display: flex;
    flex-direction: row;
  `;
  const Content = styled.div`
   
  `;
  const BtnContainer = styled.div`
    background-color: ${(p) => p.theme.primary.bgColor};
    display: block;
    text-align: center;
    padding: 1rem 0;
  `;
  const Title = styled.h3`
   text-align: center;
  `;
  const Description = styled.p`
   text-align: center;
  `;
  const Address = styled.p`
   text-align: center;
  `;
  const ButtonTicketInfo = styled.a`
     background-color: ${(p) => p.theme.primary.bgColor};
      color: ${(p) => p.theme.primary.textColor};
      font-size: 1.2rem;
      text-decoration: none;
      text-align: center;
      cursor: pointer;
      width: 100%;
  `;
  const Img = styled.img`
       height: 16rem;
       width: 18rem;
       display:block; 
       margin: 0 auto;
      `;

  const StyledToolbar = styled(Toolbar)`
    && {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  `;

  const SkeletonCardsContainer = styled.div`
    margin: 0px auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  `;
  const StyledAppBar = styled(AppBar)`
    && {
      background-color: ${(p) => p.theme.primary.bgColor};
      color: ${(p) => p.theme.primary.textColor};
      font-size: 1.2rem;
      text-align: center;
      position: sticky;
    }
  `;
  const Container = styled.div`
    padding: 0.5rem;
    /* overflow: scroll; */
    box-sizing: border-box;
    min-height:100%;
    min-width: 300px;
    width: 25rem;
    align-items: center;
  `;
  
  const StyledBackIcon = styled(BackIcon)`
    && {
      color: white;
      cursor: pointer;
    }
  `;
  
  