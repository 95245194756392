import { createContext, useContext } from "react";
import { useEffect, useState } from "react";

interface ILayout {
  isDesktop: boolean
}
// type TLayout = 'MOBILE' | 'DESKTOP'
// interface ILayout {
//   layout: TLayout;
// }
const width: number = window.innerWidth;
const DESKTOP_SIZE: boolean = width >= 1024

const LayoutContext = createContext<ILayout>({ isDesktop: DESKTOP_SIZE });

export const useLayout = () => useContext(LayoutContext);

export function LayoutProvider({ children }) {
  // const [isMobile, setIsMobile] = useState<TLayout>('MOBILE')
  const [isDesktop, setIsDesktop] = useState<boolean>(DESKTOP_SIZE)

  function handleWindowSizeChange() {
    setIsDesktop(DESKTOP_SIZE)
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, [isDesktop]);

  return (
    <LayoutContext.Provider value={{ isDesktop }}>
      {children}
    </LayoutContext.Provider>
  );
}
