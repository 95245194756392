import BackIcon from '@mui/icons-material/ArrowBackIos';
import { AppBar, Dialog, Slide, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import moment from 'moment';
import { forwardRef, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import StorageService from '../../core/services/storage';
import {
  getAttractionByCityId,
  getAttractionCity,
  getAttractionData,
  getAttractionDataById,
  replaceEventNewApi,
} from '../../core/services/trip';
import { Skeleton } from '@mui/material';
import { clearHash } from '../../core/utils/common';
import ReplaceDialog from '../pages/Item/ReplaceDialog';
import Spinner from '../shared/Spinner/Spinner';
import { BookedStatuses } from './../../core/utils/enums';
import { useTripDetails } from './../../providers/TripDetailsProvider';
import { FeedProvider, useFeed } from './Feed/useFeed';
import Item from './Item/Item';
import ReplaceAttrDialog from './ReplaceAttrDialog';
import { ButtonProvider } from './useButtonProps';

function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2-lat1);  // deg2rad below
  var dLon = deg2rad(lon2-lon1); 
  var a = 
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
    Math.sin(dLon/2) * Math.sin(dLon/2)
    ; 
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
  var d = R * c; // Distance in km
  return d;
}

function deg2rad(deg) {
  return deg * (Math.PI/180)
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function Explore({  handleOpenExplore, handleCloseExplore, handleOpenRemoveExplore, handleOpenReplaceAttr, handleCloseReplaceAttr}) {
  const history = useHistory();
  const { tripDetails, updateTripDetails } = useTripDetails();
  const {
    itemsIDs,
    tripPreferences: { interests },
  } = tripDetails;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    state: {isOpenRemoveExplore, currentDayIndex, selectedReplace, selectedAddToTrip, isOpenExplore },
    dispatch
  } = useFeed();

  const {
    handleReplace,
    handleSelectToAdd,
    handleRemove,
    handleOpenReplaceDialog,
    handleCloseReplaceDialog,
    handleBack,
    openReplaceDialog,
    showReplaceAttrDialog
  } = ButtonProvider();

  const { onAttractionDelete } = FeedProvider();
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedInterest, setSelectedInterest] = useState<number>(interests[0]);
  const [selectedEvent, setSelectedEvent] = useState<any>();
  // const [selectedEvenToAdd, setSelectedEventToAdd] = useState<any>();
  const [groupAttractionByCat, setGroupAttractionByCat] = useState<any>();
  // const [openReplaceDialog, setOpenReplaceDialog] = useState<boolean>(false);
  // const [openReplaceAttrDialog, setOpenReplaceAttrDialog] = useState<boolean>(false);
  const [match, setMatch] = useState<boolean>(false);
  const itinerary = JSON.parse(localStorage.getItem('trip')!);

  useEffect(() => {
    dispatch({ type: 'SET_ITINERARY', payload: itinerary });
    if (isOpenExplore) {
      getData();
    } else {
      setGroupAttractionByCat([]);
      setLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenExplore]);
  const hotel = StorageService.local.get.item('hotel');

const getData = async () => {
    const onbording = StorageService.local.get.item('OnboardingData');
    let city: any;
    if (onbording.onboarding_data.type === 'hospitality') {
      city = hotel?.city.name || hotel?.city;
    } 
    if (onbording.onboarding_data.type === 'property') {
      city = hotel?.city.name || hotel?.city;
    } 
      
    let getAtr;
    let filterAtr; 
    let allTripEvents: any[] = flattenTripEvents();
    getAtr = await getAttractionCity(city);
    if(getAtr.err === null){
      setLoading(false);
      let convertedAttraction = convertAttractionToOld(getAtr.data.attractions);
      for (let i = 0; i < allTripEvents.length; i++) {
        filterAtr =  convertedAttraction!.filter(a => a.name !== allTripEvents[i].title)
      }
      if(filterAtr.length){
        groupCategoriesAtr(filterAtr);
      }else{
        setMatch(false)
      }
    }else{
      setLoading(false);
      setMatch(false)
      console.log('aca hay error')
    }
  };

  let mapEvents = JSON.parse(localStorage.getItem('trip')!)?.sections;
  function getAllEventsFromTrip() {
    let flat: any[] = [];
    
    for (var i = 0; i < mapEvents.length; i++) {
         let sections = mapEvents[i].events
         let filterSections: any= sections.filter(a => a.type !== 'CMT')
        flat.push(filterSections)
    }
    return flat;
}


function flattenTripEvents() {
  let allEvents = getAllEventsFromTrip()
  var flat = [];
  for (var i = 0; i < allEvents.length; i++) {
      flat = flat.concat(allEvents[i]);
  }
  return flat;
}

  const getTimeSlots = () => {
    let mapEvents = JSON.parse(localStorage.getItem('trip')!).sections[currentDayIndex].events;
    mapEvents.forEach((event, i) => {
      if (event.title === selectedReplace.name) {
        setSelectedEvent(i)
      }
    });
    let data = mapEvents.filter(
      (a) => a.title === selectedReplace.name,
    );
    let duration = data[0].duration.split(':')[0];
    let starting_time = data[0].starting_time;
    let end_time = moment(starting_time, 'HH:mm:ss').add(duration, 'hours').format('HH:mm:ss');
    return { start_time: starting_time, end_time: end_time };
  };

  const convertAttractionToOld = (atr) => {
    console.log(atr);
    try {
      let oldAttractions: ITripItem_Attraction[] = atr
      .filter((atr) => atr.geolocation !== null && atr.categories.length > 0)
      .map((a) => {
        return {
          _id: a.external_id,
          _eventID: '',
          type: 1,
          source: a.inventory_supplier,
          location: { lat: a.geolocation.lat, lng: a.geolocation.lng },
          address: a.address,
          distance: selectedReplace ? getDistanceFromLatLonInKm(selectedReplace.location.lat, selectedReplace.location.lng, a.geolocation.lat, a.geolocation.lng) : null,
          name: a.title,
          rank: { value: Number(a.rating), count: Number(a.number_of_reviews) },
          about: a.description,
          tags: a.categories,
          imgURL: a.main_photo_url,
          orderPage: a.order_webpage,
          isFree: a.is_free,
          uuid: a.place_uuid,
          placeUUID: a.place_uuid,
          bookingStatus: a.was_booked,
          pickup: a.hotel_pickup,
          activityDatetime: '',
          orderUuid: '',
          cardBorderColor: 3,
        };
      });

      if(selectedReplace) {
        oldAttractions = oldAttractions.sort((a: any, b: any) => a?.distance - b?.distance);
      }

      if(oldAttractions.length === 0){
        setMatch(false)
      }else{
        setMatch(true)
      }

      return oldAttractions;
    } catch (err) {
      console.log(err);
    }
  };

  const groupCategoriesAtr = (atr) => {
    let justFirstTag = atr.map((a) => {
      const firstTag = a.tags[0];
      a.tags = [firstTag];
      return a;
    });
    let grupealos = groupByKey(justFirstTag, 'tags');
    const arr = Object.entries(grupealos);
    // console.log('arr', arr);
    setGroupAttractionByCat(arr);
  };

  function groupByKey(array, key) {
    return array.reduce((hash, obj) => {
      if (obj[key] === undefined) return hash;
      return Object.assign(hash, { [obj[key]]: (hash[obj[key]] || []).concat(obj) });
    }, {});
  }

  const deleteAttr = () => {
    // onAttractionDelete(selectedReplace);
    let mapEvents = JSON.parse(localStorage.getItem('trip')!)?.sections[currentDayIndex].events;
    mapEvents.forEach((event, i) => {
      if (event.title === selectedReplace.name) {
        onAttractionDelete(i);
      }
    });
    handleCloseReplaceDialog();
  };

  function isAttractionAlreadyInTrip(attractionID: number) {
    return itemsIDs.includes(attractionID);
  }

  function handleInterestChange(index: number) {
    setSelectedInterest(interests[index]);
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      maxWidth="xl"
      open={isOpenExplore}
      onBackdropClick={handleBack}
      TransitionComponent={Transition}
    >
      <StyledAppBar>
        <StyledToolbar>
          <TitleAndBackBtn>
            <StyledBackIcon onClick={handleOpenReplaceDialog} />
            <div>
              {selectedReplace ? 'Select item to replace current one' : 'Select activity to add'}
            </div>
          </TitleAndBackBtn>
        </StyledToolbar>
      </StyledAppBar>
      <Container>
        {loading ? (
          <>
            <div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Skeleton
                  variant="rectangular"
                  height={30}
                  width={100}
                  animation="wave"
                  style={{ borderRadius: '10px', marginRight: '5px' }}
                />
                <Skeleton
                  variant="rectangular"
                  height={30}
                  width={100}
                  animation="wave"
                  style={{ borderRadius: '10px', marginRight: '5px' }}
                />
                <Skeleton
                  variant="rectangular"
                  height={30}
                  width={100}
                  animation="wave"
                  style={{ borderRadius: '10px', marginRight: '5px' }}
                />
              </div>
              <SkeletonCardsContainer>
                <br />
                <Skeleton
                  variant="rectangular"
                  height={300}
                  width={300}
                  animation="wave"
                  style={{ borderRadius: '10px' }}
                />
                <br />
                <Skeleton
                  variant="rectangular"
                  height={300}
                  width={300}
                  animation="wave"
                  style={{ borderRadius: '10px' }}
                />
                <br />
                <Skeleton
                  variant="rectangular"
                  height={300}
                  width={300}
                  animation="wave"
                  style={{ borderRadius: '10px' }}
                />
              </SkeletonCardsContainer>
              {/* <CircularProgress /> */}
            </div>
            <Spinner />
          </>

        ) : (
          <>
            {match ? (groupAttractionByCat?.map((a) => (
              <InterestsSliderContainer key={`interest${a[0]}`}>
                <InterestTitle>{a[0]}</InterestTitle>
                <ItemsSlider>
                  {a[1].map((b) => (
                    <Item
                      showReplaceAttrDialog={showReplaceAttrDialog}
                      handleOpenExplore={handleOpenExplore}
                      handleSelectToAdd={handleSelectToAdd}
                      item={b}
                      handleRemove={handleRemove}
                      handleReplace={handleReplace}
                      key={`atr${b._id}`}
                      componentLocation={'Explore'}
                      isAttractionAlreadyInTrip={isAttractionAlreadyInTrip(a._id)}
                    />
                  ))}
                </ItemsSlider>
              </InterestsSliderContainer>
            ))) : (<h3>No attractions found.</h3>) }

          </>
        )}
      </Container>
      {/* </Page > */}
      {openReplaceDialog && selectedReplace ? (
        <ReplaceDialog
          deleteAttr={deleteAttr}
          handleBack={handleBack}
          openReplaceDialog={openReplaceDialog}
          handleCloseReplaceDialog={handleCloseReplaceDialog}
        />
      ) : null}
      {showReplaceAttrDialog && !selectedReplace ? (
        <ReplaceAttrDialog
          handleOpenExplore={handleOpenExplore}
          handleCloseReplaceAttr={handleCloseReplaceAttr}
          openReplaceAttrDialog={showReplaceAttrDialog}
          handleSelectToAdd={handleSelectToAdd}
          handleRemove={handleRemove}
          handleReplace={handleReplace}
          handleCloseExplore={handleCloseExplore}
        />
      ) : null}
    </Dialog>
  );
}

const ItemsSlider = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-x: scroll;
`;

const InterestsSliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
`;
const TitleAndBackBtn = styled.div`
  display: flex;
  flex-direction: row;
`;
const FavoritesButton = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
`;
const StyledToolbar = styled(Toolbar)`
  && {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;
const WishlistLabel = styled.div`
  font-size: 0.8rem;
  margin-right: 5px;
`;
const SkeletonCardsContainer = styled.div`
  margin: 0px auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const StyledAppBar = styled(AppBar)`
  && {
    background-color: ${(p) => p.theme.primary.bgColor};
    color: ${(p) => p.theme.primary.textColor};
    font-size: 1.2rem;
    text-align: center;
    position: sticky;
  }
`;
const Container = styled.div`
  padding: 0.5rem;
  overflow: scroll;
  box-sizing: border-box;
  min-height: 80vh;
  min-width: 300px;
  width: 100%;
  align-items: center;
`;


const InterestTitle = styled.h3`
  margin-left: 1rem;
`;

const StyledBackIcon = styled(BackIcon)`
  && {
    color: white;
    cursor: pointer;
  }
`;


