import { useEffect, useState } from "react";

const useResponsive = (widthPX: number) => {
  const [isSize, setIsSize] = useState<boolean>(window.innerWidth < widthPX);

  const handleResize = () => {
    if(window.innerWidth < widthPX) {
      setIsSize(true);
    } else {
      setIsSize(false);
    }
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isSize;
}

export default useResponsive;