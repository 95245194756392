import axios, { AxiosInstance, AxiosStatic } from 'axios';

const { REACT_APP_API_ENDPOINT: BRIDGIFY_API, REACT_APP_MUSEMENT_URL: MUSEMENT_API } = process.env;

export const BridgifyInstance = axios.create({
  baseURL: BRIDGIFY_API,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const MusementInstance = axios.create({
  baseURL: MUSEMENT_API,
  headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/json',
    'X-Musement-Currency': 'USD',
  },
});

export const instancesDic: { [key in TInstances]: AxiosInstance | AxiosStatic } = {
  bridgify: BridgifyInstance,
  musement: MusementInstance,
  general: axios,
};


