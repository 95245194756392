import BookingFlowDialog from "../pages/Booking/BookingInfo/BookingFlow"
import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import useResponsive from '../hooks/useResponsive'
import styled from 'styled-components/macro';
import ParamsService from '../../core/services/params';
import { LoaderTypes } from '../../core/utils/enums';
import { useTripDetails } from '../../providers/TripDetailsProvider';
import { ShowOnDesktop } from '../shared/Layout';
import Map from '../shared/Map/Map';
import Page from '../shared/Page';
import Timeline from '../shared/Timeline';
import IFrameBookingDialog from './Booking/IFrameBookingDialog';
import Explore from './Explore';
import { FeedContext, FeedProvider, useFeed } from './Feed/useFeed';
import { useLocation } from "react-router-dom";
import Mixpanel, { MP_Events, MP_Props } from "../../core/services/mixpanel";
import locationSVG from '../../assets/images/icons/location.svg';
import emailSVG from '../../assets/images/icons/email.svg';
import StorageService from "../../core/services/storage";
import BottomModal from '../pages/Onboarding/OnboardingComponents/BottomModal'
import SetEmailVerification from './Feed/VerificationSteps/SetEmailVerification'
import SetCodeVerification from './Feed/VerificationSteps/SetCodeVerification'
import AttractionsPreviewSlider from '../shared/Map/AttractionsPreviewSlider'
import VerificationSuccess from './Feed/VerificationSteps/VerificationSuccess'

export default function Feed() {
  const {
    dispatch,
    state,
    state: { selectedItemBook, openEmailDialog, showBookingFlowDialog },
    actionIcons, setShowExploreDialog, setShowReplaceAttrDialog,
    onAttractionDelete,
  } = FeedProvider();
  const {tripDetails} = useTripDetails();
  const {
    tripDetails: { _id, tripDays },
  } = useTripDetails();
  const {
    state: { currentDayIndex, isReplace }
  } = useFeed();

  const [render, setRender] = useState(false)
  const location = useLocation();
  const {verificationPageindex} = useSelector((state: any) => state.verificarionEmail);
  const makeDispatch = useDispatch()
  const mobileView = useResponsive(900)

  useEffect(() => {
    if(location.state) {
      dispatch({type: "SET_ITINERARY", payload: location.state});
    }
    setRender(!render)
  }, [tripDetails, currentDayIndex, isReplace]);
  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.onpopstate = () => {
      window.history.go(1); // navigate to explore screen to add itinerary to particular day
    };
  }, []);
  function handleOpenExplore() {
    setShowExploreDialog(true);
    dispatch({ type: 'OPEN_EXPLORE', payload: true });
    dispatch({ type: 'SELECTED_ITEM_REPLACE', payload: null });
    Mixpanel.track(MP_Events.Explore, {
      [MP_Props.testing]: ParamsService.getInHouseTest() ? "yes" : "no"
    });
  }
  function handleOpenRemoveExplore() {
    setShowExploreDialog(true)
    dispatch({ type: 'OPEN_EXPLORE', payload: true });
    dispatch({ type: 'IS_SELECTED_ATR', payload: true });
    // history.push(Routes.explore, { day: dayData });
  }
  function handleCloseExplore(){
    dispatch({ type: 'OPEN_EXPLORE', payload: false });
    dispatch({ type: 'SELECTED_ITEM_REPLACE', payload: null });
    dispatch({ type: 'IS_SELECTED_ATR', payload: false });
    // dispatch({ type: 'REPLACE_EXPLORE', payload: false });
    setShowExploreDialog(false)
  }
  function handleCloseExploreReplace(){
    dispatch({ type: 'OPEN_EXPLORE', payload: false });
    dispatch({ type: 'SELECTED_ITEM_REPLACE', payload: null });
    dispatch({ type: 'IS_SELECTED_ATR', payload: false });
    dispatch({ type: 'REPLACE_EXPLORE', payload: false });
    setShowExploreDialog(false)
  }
  function handleOpenReplaceAttr(){
    dispatch({ type: 'OPEN_REMOVE_EXPLORE', payload: true });
    setShowReplaceAttrDialog(true)
  }
  function handleCloseReplaceAttr(){
    dispatch({ type: 'OPEN_EXPLORE', payload: false });
    dispatch({ type: 'OPEN_REMOVE_EXPLORE', payload: false });
    dispatch({ type: 'SELECTED_ITEM_REPLACE', payload: null });
    dispatch({ type: 'IS_SELECTED_ATR', payload: false });
    setShowReplaceAttrDialog(false)
  }
  function handleClose() {
    window.history.back();
    dispatch({ type: 'SELECTED_ITEM_TICKETS', payload: undefined });
    dispatch({ type: 'TOGGLE_BOOKING_FLOW_DIALOG' });
    // dispatch({ type: 'SELECTED_ITEM_BOOK', payload: undefined });
  }
  const PRESENT_MODE = ParamsService.getPresent();
  if(location.pathname === '/feed') {
    document.body.style.overflow = 'hidden';
  }
  let dayData = tripDays[currentDayIndex];
  const center = selectedItemBook?.location || dayData?.items[0]?.location || dayData?.items[1]?.location;
  const [centerMap, setCenterMap] = useState(center);
  const [currentCenter, setCurrentCenter] = useState(center);
  const [openDialogVerification, setOpenDialogVerification] = useState(PRESENT_MODE ? false : true);
  const [animations, setAnimations] = useState(openEmailDialog ? "closeEmail" : "openEmail");
  const [emailVerified, setEmailVerified] = useState(false);
  const [closedManually, setClosedManually] = useState(false);
  const emailValidated = StorageService.local.get.validatedEmail();
  const getCenterLocation = () => {
    setCenterMap(center);
  }
  const handleEmailVerification = () => {
    setClosedManually(false);
    dispatch({ type: 'OPEN_EMAIL_DIALOG', payload: true });
    setOpenDialogVerification(true)
  }

  const handleCloseEmailVerification = () => {
    setOpenDialogVerification(false)
  }
  return (
    <Page
      loaderType={LoaderTypes.WelcomePage}
      footer={{ position: 'relative' }}
      style={{ padding: 0 }}
    >
      <FeedContext.Provider value={{ state, dispatch }}>
        <ShowOnDesktop>
          {/* <MapSideButtons>
            {actionIcons.map(({ icon, onIconClick, text }: IActionIcons, i) => (
              <MapSideButton variant="contained" onClick={onIconClick} key={`actionTopBarFeed${i}`}>
                <Img src={icon} alt="action" />
                <ButtonTitle>{text}</ButtonTitle>
              </MapSideButton>
            ))}
          </MapSideButtons> */}
        </ShowOnDesktop>
        <Container>
          <Timeline actionIcons={actionIcons} onAttractionDelete={onAttractionDelete} />
        </Container>
        <MapContainer>
          <Map centerMap={centerMap} setCenterMap={setCenterMap} setCurrentCenter={setCurrentCenter}/>
        </MapContainer>
        {!mobileView && <AttractionsPreviewSlider />}
        {/* Only if it's a Tiqets attraction */}
        {(selectedItemBook?.source === 'Tiqets' ||
          selectedItemBook?.source === 'SportsEvents365' ||
          selectedItemBook?.source === 'Eventim') &&
          selectedItemBook?.uuid && <IFrameBookingDialog />}
         
         {openDialogVerification && 
         <BottomModal openBottomModal={openDialogVerification} closeBottomModal={handleCloseEmailVerification}>
            {verificationPageindex === 0 && <SetEmailVerification closeBottomModal={handleCloseEmailVerification}/>}
            {verificationPageindex === 1 && <SetCodeVerification  />}
            {verificationPageindex === 2 && <VerificationSuccess closeBottomModal={handleCloseEmailVerification}/>}
        </BottomModal>}
         
        {showBookingFlowDialog && selectedItemBook?.uuid && <BookingFlowDialog />}

        <Explore handleOpenExplore={handleOpenExplore} handleCloseExplore={handleCloseExplore} handleOpenRemoveExplore={handleOpenRemoveExplore} handleOpenReplaceAttr={handleOpenReplaceAttr} handleCloseReplaceAttr={handleCloseReplaceAttr}/>

        <ContainerBtn>
          <IconEmailAndLoc onClick={getCenterLocation} src={locationSVG}/>
          {(!emailVerified && !emailValidated && !PRESENT_MODE) && <IconEmailAndLoc onClick={handleEmailVerification} src={emailSVG}/>}
        </ContainerBtn>
      </FeedContext.Provider>
    </Page>
  );
}
const Img = styled.img`
  height: 40px;
  width: 40px;
`;
const ContainerBtn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  gap: 10px;
  top: 40%;
  right: 10px;
  @media (max-width: 840px) {
    /* bottom: 150px; */
    top: 50%;
  }
`;
const IconEmailAndLoc = styled.img`
    cursor: pointer;
    background-color: ${p => p.theme.primary.bgColor};
    /* padding: 10px; */
    object-fit: none;
    width: 50px;
    height: 50px;
    border: 3px solid white;
    border-radius: 50%;
`;
const ButtonTitle = styled.span`
  font-size: 12px;
`;
const MapSideButton = styled(Button)`
  && {
    background: #FCFCFC;
    color: black;
    border-radius: 10px 0px 0px 10px;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    text-transform: unset;
    padding: 3px 18px 3px 28px;
  }
`;
const MapSideButtons = styled.div`
  position: absolute;
  right: 0;
  top: 30%;
  z-index: 3;
  display: flex;
  flex-direction: column;
`;
const Container = styled.div`
  position: absolute;
  height: 100%;
`;
const MapContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  box-sizing: border-box;
`;
