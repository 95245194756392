import styled from "styled-components/macro";
import MapContainer from "../../../../shared/Map/MapContainer";
import { MapContainerDiv, ActivityProperty, SeperationLine } from "../../../../shared/StyledComponents/Musement";
import { useFeed } from "../../../Feed/useFeed";
import CancellationPolicy from "../CancellationPolicy";

enum MUSEMENT_COMP3 {
  ReducedPrice,
  When,
  Where,
  MeetingPoint,
  BookingFee,
  CancellationPolicy
}


interface IDetail {
  title: string | null;
  content: JSX.Element | null;
}

export default function PartThree() {
  const { state: { selectedItemTickets:
    //@ts-ignore
    { reducedPrice_HTML, when_HTML, meetingPoint, location, location: { lng, lat }, where_HTML, bookingFee = false }
  } } = useFeed();

  const Component3Dic: { [key in MUSEMENT_COMP3]: IDetail } = {
    [MUSEMENT_COMP3.ReducedPrice]: {
      title: 'Reduced Price', content: reducedPrice_HTML ? (
        <div dangerouslySetInnerHTML={{ __html: reducedPrice_HTML }} />
      ) : null
    },
    [MUSEMENT_COMP3.When]: {
      title: 'When', content: when_HTML ? (
        <div dangerouslySetInnerHTML={{ __html: when_HTML }} />
      ) : null
    },
    [MUSEMENT_COMP3.Where]: {
      title: 'Where', content: where_HTML ? (
        <div dangerouslySetInnerHTML={{ __html: where_HTML }} />
      ) : null
    },
    [MUSEMENT_COMP3.MeetingPoint]: {
      title: meetingPoint ? 'Meeting Point' : null, content: (
        <>
          {meetingPoint && <div dangerouslySetInnerHTML={{ __html: meetingPoint }} />}
          {lat && lng ?
            <MapContainerDiv>
              <MapContainer location={location} />
            </MapContainerDiv>
            : null}
        </>)
    },
    [MUSEMENT_COMP3.BookingFee]: {
      title: 'Booking Fee', content: bookingFee ?
        <>We know. Nobody likes extra fees. <br />
          But not only do they cover the cost of managing your booking, they let us give you 24/7 customer service and a free app to access your tickets and discover more city sights.
        </> : <>Good news! No extra fees are applied to this booking.</>

    },
    [MUSEMENT_COMP3.CancellationPolicy]: {
      title: 'Cancellation Policy', content: <CancellationPolicy />
    },
  }




  return (
    <>
      {Array.from(Array(Object.keys(MUSEMENT_COMP3).length / 2).keys())
        .map(key => {
          const { title, content } = Component3Dic[key]
          return content ? (
            <div key={title}>
              <ActivityProperty >
                {title && <Title>{title}</Title>}
                <Content>{content}</Content>
              </ActivityProperty>
              <SeperationLine />
            </div>
          ) : null
        })}
    </>
  )
}

const Title = styled.div`
  font-weight: 700;
  font-size: 1rem;
  margin-bottom:0.3rem
`;
const Content = styled.div`
  // font-weight: 700;
  font-size: 0.9rem;
  margin-bottom:1rem
`;

