import { useEffect, useRef } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import { tripPace } from "../../../../Actions/onboardingActions";

import useResponsive from "../../../hooks/useResponsive";

interface IProps {
  color: string;
}

const PaceSlider = ({color}: IProps) => {
  const circleClickedRef = useRef(false);
  const circleRef = useRef<any>();
  const sliderBarRef = useRef<any>();

  const responsive = useResponsive(580);

  const onboarding = useSelector((state: any) => state.onboarding);
  const dispatch = useDispatch();

  const handlePaceSlider = (e) => {
    if(!circleClickedRef.current) return;
    const {right} = sliderBarRef.current.getBoundingClientRect();
    const xAxis = right - e.clientX;
    if(!(xAxis > 0) || !(xAxis < sliderBarRef.current.offsetWidth)) {
      if(xAxis <= 0 || xAxis >= sliderBarRef.current.offsetWidth) {
        return;
      }
    }
    const sliderWidthDivideBy3 = sliderBarRef.current.offsetWidth / 3;
    if(xAxis < sliderWidthDivideBy3) {
      dispatch(tripPace(2));
    } else if(xAxis > sliderWidthDivideBy3 && xAxis < sliderWidthDivideBy3 * 2) {
      dispatch(tripPace(1));
    } else {
      dispatch(tripPace(0));
    }
    circleRef.current.style.left = xAxis + "px";
  }

  const handleTouchPaceSlider = (e) => {
    if(!circleClickedRef.current) return;
    const widthResult = window.innerWidth - sliderBarRef.current.offsetWidth;
    const xAxis = e.targetTouches[0].clientX - (widthResult / 2);
    if(!(xAxis > 0) || !(xAxis < sliderBarRef.current.offsetWidth)) {
      if(xAxis <= 0 || xAxis >= sliderBarRef.current.offsetWidth) {
        return;
      }
    }
    const sliderWidthDivideBy3 = sliderBarRef.current.offsetWidth / 3;
    if(xAxis < sliderWidthDivideBy3) {
      dispatch(tripPace(0));
    } else if(xAxis > sliderWidthDivideBy3 && xAxis < sliderWidthDivideBy3 * 2) {
      dispatch(tripPace(1));
    } else {
      dispatch(tripPace(2));
    }
    circleRef.current.style.left = xAxis + "px";
  }

  const handleMouseUp = () => {
    if(circleClickedRef.current) {
      circleClickedRef.current = false;
    }
  }

  useEffect(() => {
    window.addEventListener("mousemove", handlePaceSlider);
    return () => window.removeEventListener("mousemove", handlePaceSlider);
  }, []);

  useEffect(() => {
    window.addEventListener("mouseup", handleMouseUp);
    return () => window.removeEventListener("mouseup", handleMouseUp);
  }, []);

  useEffect(() => {
    window.addEventListener("touchmove", handleTouchPaceSlider);
    return () => window.removeEventListener("touchmove", handleTouchPaceSlider);
  }, []);

  useEffect(() => {
    window.addEventListener("touchend", handleMouseUp);
    return () => window.removeEventListener("touchend", handleMouseUp);
  }, []);

  useEffect(() => {
    if(onboarding.tripPace === 0) {
      circleRef.current.style.left = responsive ? "0%" : "100%";
    } else if(onboarding.tripPace === 1) {
      circleRef.current.style.left = "50%";
    } else {
      circleRef.current.style.left = responsive ? "100%" : "0%";
    }
  }, []);

  return(
    <PaceSliderContainer>
      <PaceSliderBar ref={sliderBarRef}>
        <PaceSliderCircle
          ref={circleRef} 
          color={color} 
          onMouseDown={() => circleClickedRef.current = true} 
          onTouchStart={() => circleClickedRef.current = true} 
        />
      </PaceSliderBar>
      <PaceStatuses>
        <PaceStatus>Easy <br /> going</PaceStatus>
        <PaceStatus>See it <br /> all</PaceStatus>
      </PaceStatuses>
    </PaceSliderContainer>
  )
}

const PaceSliderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
`;

const PaceSliderBar = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #CBD5E1;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scaleX(-1);

  @media screen and (max-width: 580px) {
    transform: initial;
  }
`;

const PaceSliderCircle = styled.div`
  position: absolute;
  width: 25px;
  height: 25px;
  background-color: ${(p) => p.color};
  border: 3px solid white;
  border-radius: 50%;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
`;

const PaceStatuses = styled.div`
  pointer-events: none;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
`;

const PaceStatus = styled.p`
  padding: 15px;
  width: 25px;
  height: 25px;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  white-space: nowrap;
  border-radius: 50%;
  background-color: #F1F5F9;
`;

export default PaceSlider;