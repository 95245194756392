import { Button, Step, StepContent, StepLabel, Stepper, } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import useBookTickets, { BookingTicketsContext } from "./useBookingTickets";
import styled from "styled-components/macro";
import PaymentDialog from "../PaymentDialog/PaymentDialog";


export default function BookingTicketsPage() {

  const BookingTicketsState = useBookTickets()
  const { activeStep, steps, stepsDic, handleBack, state } = BookingTicketsState

  return (
    <BookingTicketsContext.Provider value={BookingTicketsState}>
      <Container >
        <StyledStepper activeStep={activeStep} orientation="vertical">
          {steps?.map((label, index) => {
            const { title, Content, selectedOption } = stepsDic[label]
            const EDIT_OPTION: boolean = (index < activeStep && activeStep !== 0 && (index === activeStep - 1))
            const DISPLAY_SELECTED_OPTION: boolean = index < activeStep
            return (
              <Step key={label} >
                <StepLabel >
                  <StepTitlesContainer >
                    <TextContainer>
                      {title}
                      {DISPLAY_SELECTED_OPTION && <SelectedText>
                        {selectedOption ? selectedOption : ''}
                      </SelectedText>}
                    </TextContainer>
                    {EDIT_OPTION && <Button onClick={handleBack} disabled={activeStep === 0}>
                      <StyledEditIcon />
                    </Button>}
                  </StepTitlesContainer>
                </StepLabel>
                <StyledStepContent >
                  <Content />
                </StyledStepContent>
              </Step>
            )
          })}
        </StyledStepper>
      </Container>
      {state.showPaymentModal && <PaymentDialog />}
    </BookingTicketsContext.Provider>
  );
}


const StyledStepper = styled(Stepper)`
&&{
  width:85%;
}
`;
const Container = styled.div`

`

const StyledEditIcon = styled(EditIcon)`
  height:1.3rem;
  border: 1px solid black;
  padding: 1px 5px;
  border-radius:8px;
  color:black;
`;
const StyledStepContent = styled(StepContent)`
  text-align:center;
`;

const StepTitlesContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;
const TextContainer = styled.div`
  /* ... */
`;
const SelectedText = styled.div`
  font-size: 0.75rem;
  font-weight: 500;
  color: #11721A;
`;