function encode(token: string) {
  return btoa(token);
}

function decode(token: string) {
  return atob(token);
}

const encrypt = { encode, decode };

export default encrypt;
