import { BRIDGIFY_DEFAULT_JSON } from '../../providers/TripDetailsProvider';
import { CardBorderColors, TRIP_ITEMS } from '../utils/enums';

const LOAD_IMAGES: boolean =
  process.env.NODE_ENV === 'production' || process.env.REACT_APP_LOAD_IMAGES !== undefined;

export function convertTripDay(day: IDayItinerary): ITripDay {
  const convertedTripItems = day.itinerary_item.map((item) => {
  
    switch (item.type) {
      case TRIP_ITEMS.Attraction:
        //@ts-ignore
        return convertAttraction(item);
      case TRIP_ITEMS.Restaurant:
        //@ts-ignore
        return convertResturant(item);
      case TRIP_ITEMS.Commute:
        //@ts-ignore
        return convertCommute(item);
      default:
        return {};
    }
  });
 
  return {
    _id: day.id,
    date: day.date,
    //@ts-ignore
    items: convertedTripItems,
  };
}
function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function convertTripDetails(response: any): any {
  const { td, tp, day_itinerary, id, design, trip_favorites } = response;
  const { start_date, number_of_days } = td;
  const daysArray = day_itinerary
    .sort((dayA, dayB) => dayA.index - dayB.index)
    .map((day: IDayItinerary) => convertTripDay(day));

  const sortedTripPreferences = {
    ...tp,
    interests: tp.interests.sort((a, b) => a - b),
  };


  const lastDate_Formatted = addDays(start_date, number_of_days).toISOString().slice(0, 10);

  const tripAccommodationWithFinishDate = {
    ...td,
    finish_date: lastDate_Formatted,
  };

  const convertedClientDesign: IClientDesign = design
    ? convertClientDesign(design)
    : BRIDGIFY_DEFAULT_JSON;
  const convertedTripDetails: ITripDetails_Converted = {
    _id: id,
    tripAccommodation: tripAccommodationWithFinishDate,
    tripPreferences: sortedTripPreferences,
    tripDays: daysArray,
    ClientDesign: convertedClientDesign,
    favorites: trip_favorites || []
  };

  return convertedTripDetails;
}

function getCardBorderColor(type: TRIP_ITEMS, isFree: boolean) {
  return type !== TRIP_ITEMS.Attraction
        ? CardBorderColors.Other
        : isFree
          ? CardBorderColors.FreeAttraction
          : CardBorderColors.PaidAttraction;
}

const convertToOld = (event) => {
  return {
      id:event.id,
      location_lat:event.starting_geolocation.lat,
      location_lng:event.starting_geolocation.lng,
      address:event.address,
      number_of_reviews:event.number_of_reviews,
      source:event.inventory_supplier,
      rating:event.rating,
      name:event.title,
      duration: event.duration,
      about:event.description,
      tags:event.categories,
      main_photo_url:event.main_photo_url,
      order_page:event.order_webpage,
      is_free:false,
      place_uuid: event.place_uuid,
      external_id:event.place_external_id,
      order_uuid:""
  }
}

export function convertAttraction(attractionItem: any): ITripItem_Attraction {
  // const { booked_status, event_details } = attractionItem;
  const eventDetails: any = convertToOld(attractionItem);

  const {
    id,
    location_lat,
    location_lng,
    address,
    number_of_reviews,
    source,
    rating,
    name,
    about,
    tags,
    duration,
    main_photo_url,
    order_page,
    is_free,
    place_uuid,
    external_id,
    order_uuid,
  } = eventDetails;

  return {
    _id: attractionItem.id,
    _eventID: id,
    source,
    location: {
      lat: location_lat,
      lng: location_lng,
    },
    address,
    duration,
    rank: {
      count: +number_of_reviews,
      value: +rating,
    },
    name,
    about,
    tags,
    imgURL: LOAD_IMAGES ? main_photo_url : '',
    orderPage: order_page,
    placeUUID: place_uuid,
    type: TRIP_ITEMS.Attraction,
    isFree: is_free,
    uuid: external_id,
    bookingStatus: attractionItem.was_booked,
    orderUuid: order_uuid,
    cardBorderColor: getCardBorderColor(TRIP_ITEMS.Attraction, is_free),
  };
}

export const convertDuration = (duration: string, oneLetter: boolean) => {
  const [hours, minutes] = duration.split(":").map(Number);

  if(hours) {
    return `${hours}${minutes ? "." + Math.floor(minutes / 10) : ""}${oneLetter ? 'H' : " hour"}${!oneLetter && hours > 1 ? "s" : ""}`;
  } else {
    return `${minutes}${oneLetter ? " M" : " minutes"}`;
  }
}

export function convertAttractionEventDetails(
  eventDetails: EventDetails_Attraction,
): ITripItem_Attraction {
  const {
    id,
    location_lat,
    location_lng,
    address,
    number_of_reviews,
    rating,
    name,
    about,
    tags,
    main_photo_url,
    order_page,
    is_free,
    place_uuid,
    external_id,
    pickup,
    activity_datetime,
    source,
  } = eventDetails;
  return {
    _id: id,
    _eventID: id,
    source,
    location: {
      lat: location_lat,
      lng: location_lng,
    },
    duration:"5",
    address: address,
    rank: {
      count: +number_of_reviews,
      value: +rating,
    },
    name: name,
    about: about,
    tags: tags,
    placeUUID: place_uuid,
    imgURL: LOAD_IMAGES ? main_photo_url : '',
    orderPage: order_page,
    type: TRIP_ITEMS.Attraction,
    isFree: is_free,
    uuid: place_uuid || external_id,
    pickup,
    activityDatetime: activity_datetime,
    cardBorderColor: getCardBorderColor(TRIP_ITEMS.Attraction, is_free),

  };
}

function convertResturant(resturantItem: any): ITripItem_Resturant {
  return {
    _id: resturantItem.id,
    location: {
      lat: resturantItem.starting_geolocation.lat,
      lng: resturantItem.starting_geolocation.lng,
    },
    address: resturantItem.address,
    rank: {
      count: Number(resturantItem.number_of_reviews),
      value: Number(resturantItem.rating),
    },
    duration:resturantItem.duration,
    name: resturantItem.title,
    about: resturantItem.description,
    tags: resturantItem.categories,
    placeUUID: resturantItem.place_uuid,
    uuid: resturantItem.place_uuid,
    imgURL: LOAD_IMAGES ? resturantItem.main_photo_url : '',
    orderPage: resturantItem.order_page,
    type: TRIP_ITEMS.Restaurant,
    cardBorderColor: getCardBorderColor(TRIP_ITEMS.Restaurant, false)
  };
}

export function convertCommute(commuteItem: any): ITripItem_Commute {
  const {  id, title} = commuteItem;
let displayTitle
  let splitTitle:string[]=title.split(",")
   displayTitle=splitTitle[0]+splitTitle[1]
  return {
    _id: id,
    type: TRIP_ITEMS.Commute,
    commuteTime:
    {Walking:displayTitle,Driving:""},
  };
}

export function convertClientDesign(response: IClientDesignResponse): IClientDesign {
  const {
    hotels_list,
    city_list,
    hotel_selection,
    date_selection,
    city_selection,
    tokenless_onboarding,
    merchant_flow,
    name,
    PrimaryColor_bg,
    PrimaryColor_text,
    Logo,
    SecondaryColor_bg,
    SecondaryColor_text,
    TertiaryColor_text,
    TertiaryColor_bg,
    irrelevant_interests,
  } = response;
  return {
    name,
    Logo,
    //sort Hotels so coming_soon properties will be first
    hotelList: hotels_list
      ? hotels_list.sort((a, b) => (a.coming_soon === b.coming_soon ? 0 : b.coming_soon ? -1 : 1))
      : undefined,
    cityList: city_list ? city_list : undefined,
    TOKENLESS_ONBOARDING: tokenless_onboarding === true,
    CITY_SELECTION: city_selection === true,
    HOTEL_SELECTION: hotel_selection === true,
    DATE_SELECTION: date_selection === true,
    MERCHANT_FLOW: merchant_flow === true,
    irrelevantInterests: irrelevant_interests ? irrelevant_interests : undefined,
    PrimaryColor: {
      bgColor: PrimaryColor_bg,
      textColor: PrimaryColor_text,
    },
    SecondaryColor: {
      bgColor: SecondaryColor_bg,
      textColor: SecondaryColor_text,
    },
    TertiaryColor: {
      textColor: TertiaryColor_text,
      bgColor: TertiaryColor_bg,
    },
    Images: {
      Image1: {
        imgURL: response.Image1_URL,
        text: response.Image1_text,
      },
      Image2: {
        imgURL: response.Image2_URL,
        text: response.Image2_text,
      },
      Image3: {
        imgURL: response.Image3_URL,
        text: response.Image3_text,
      },
      Image4: {
        imgURL: response.Image4_URL,
        text: response.Image4_text,
      },
      Image5: {
        imgURL: response.Image5_URL,
        text: response.Image5_text,
      },
      Image6: {
        imgURL: response.Image6_URL,
        text: response.Image6_text,
      },
    },
  };
}

export function convertMusementActivity({
  about_html,
  features,
  free_cancellation,
  highlights,
  included,
  not_included,
  info,
  where_text,
  when_text,
  languages,
  meeting_point_html,
  reduced,
  voucher_access_usage,
  latitude,
  longitude,
  title,
  cover_image_url,
  operational_days,
  order_box_elements,
  uuid,
  max_confirmation_time,
  booking_type,
  service_fee,
}: IActivity): IActivity_Converted {
  const langsList: string[] = languages.map((lang) => lang.name);
  const openDateActivity: boolean = booking_type === 'NO-CALENDAR-FIXED-END';
  const maxConfirmationTime: number = +max_confirmation_time[1];
  //activity requires pending if is NaN of if there's confirmation time
  const requiresConfirmation: boolean = isNaN(maxConfirmationTime) || maxConfirmationTime > 0;
  const mandatoryPickupService = order_box_elements.some((item) => item.includes('pickup'));
  const bookingFee: boolean = service_fee?.value > 0 || false;
  return {
    uuid,
    WhatToExpect_HTML: about_html,
    features,
    free_cancellation,
    DoThisBecause: highlights || null,
    whatsIncluded: included || null,
    whatsNotIncluded: not_included || null,
    WhatToRemember_HTML: info,
    where_HTML: where_text,
    when_HTML: when_text,
    languages: langsList,
    meetingPoint: meeting_point_html,
    reducedPrice_HTML: reduced,
    voucher_access_usage,
    title,
    availability: operational_days,
    cover_image_url,
    location: { lat: latitude, lng: longitude },
    openDateActivity,
    mandatoryPickupService,
    requiresConfirmation,
    bookingType: booking_type,
    bookingFee,
  };
}