import { useState } from "react";
import { TextField } from "@rmwc/textfield";
import { Button } from "@rmwc/button";
import { useHistory } from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux'
import {hasTripPageIndex, setTravelerItineraries} from '../../../../Actions/onboardingActions'
import styled from "styled-components";
import { useTripDetails } from '../../../../providers/TripDetailsProvider';
import { BridgifyInstance } from "../../../../core/services/instances";
import '@rmwc/textfield/styles';
import useResponsive from "../../../hooks/useResponsive";
import toast from 'react-hot-toast';
import BaloonPNG from "../../../../assets/images/baloon.png";

const WelcomeBack = () => {
  const [email, setEmail] = useState<string>("");
  const history = useHistory();
  const {setTripDetailsWrapper, convertToOldFeedData} = useTripDetails();
  let dispatch = useDispatch();

  const responsive = useResponsive(580);

  const {design} = useSelector((state: any) => state.clientDesign);

  const handleSelectTrip = async (itineraryUUID) => {
    try{
      const { data: { itinerary } } = await BridgifyInstance.get(`/itineraries/${itineraryUUID}`);
      localStorage.setItem('trip', JSON.stringify(itinerary));
      const convertedData = convertToOldFeedData({itinerary}, itinerary.design);
      await setTripDetailsWrapper(convertedData, false);
      history.push("/feed", itinerary);
  }catch{
    toast.error('Oops! We had trouble with your itinerary. Please try again!');
  }
  }

  async function handleSendEmail() {
    try {
      const { data: { traveler } }: any = await BridgifyInstance.get(`/itineraries/travelers/${email}`);

      if(traveler.itineraries.length === 1) {
        const { data: { itinerary } } = await BridgifyInstance.get(`/itineraries/${traveler.itineraries[0].uuid}`);

        handleSelectTrip(itinerary.uuid);
      } else {
        dispatch(setTravelerItineraries(traveler.itineraries));
        dispatch(hasTripPageIndex(1));
      }
    } catch (err: any) {
      toast.error('Oops! We had trouble with your email. Please enter a valid email!');
      console.log(err.response);
    }
  }

  function handleEmailChange(value: string): void {
    setEmail(value);
  }

  return <Container>
    <HelloIMG src={BaloonPNG} alt="" />
    <Title>welcome back</Title>
    <Paragraph>
      In order to view your previously saved itineraries {!responsive && <br />}
      please insert your email address here:
    </Paragraph>
    <TextField 
      className="material-input" 
      type="email"
      placeholder="traveler@example.com"
      onChange={(e: any) => handleEmailChange(e.target.value)}
      outlined
      style={{
        width: "80%", 
        height: 50,
        marginTop: 25,
        marginBottom: 15,
      }}
      value={email} 
    />
    <Button onClick={handleSendEmail} className="mdc-button mdc-button--raised material-button" style={{marginBottom: 40, backgroundColor: design.PrimaryColor.bgColor}}>Next</Button>
  </Container>
}

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const HelloIMG = styled.img`

`;

const Title = styled.h3`
  text-align: center;
  margin-bottom: 0;
`;

const Paragraph = styled.p`
  text-align: center;
  width: 95%;
`;

export default WelcomeBack;