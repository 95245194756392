import { useEffect } from "react";
import styled from "styled-components";
import {useSelector, useDispatch} from "react-redux";
import moment from 'moment';
import { useHistory } from "react-router-dom";
import { useTripDetails } from '../../../../providers/TripDetailsProvider';
import { BridgifyInstance } from "../../../../core/services/instances";
import { hasTripPageIndex } from "../../../../Actions/onboardingActions";
import toast from 'react-hot-toast';

const TravelerItineraries = () => {
    const {travelerItineraries} = useSelector((state: any) => state.onboarding);
    const history = useHistory()
    const {setTripDetailsWrapper, convertToOldFeedData} = useTripDetails();

    const dispatch = useDispatch();

    const handleSelectTrip = async (itineraryUUID) => {
        try{
            const { data: { itinerary } } = await BridgifyInstance.get(`/itineraries/${itineraryUUID}`);
            localStorage.setItem('trip', JSON.stringify(itinerary));
            const convertedData = convertToOldFeedData({itinerary}, itinerary.design);
            await setTripDetailsWrapper(convertedData, false);
            history.push("/feed", itinerary);
        }catch{
            toast.error('Oops! We had trouble with your itinerary. Please try again!');
        }
    }

    useEffect((): any => {
        return () => dispatch(hasTripPageIndex(0));
    }, []);

    return(
        <Container>
            <Title>Yes! we know you</Title>
            <Paragraph>Please select from one of your previously selected itineraries:</Paragraph>
            <ItinerariesContainer>
                {travelerItineraries.map((itinerary) => {
                    return <Itinerary onClick={() => handleSelectTrip(itinerary.uuid)} key={itinerary.uuid}>
                        <Destination>{itinerary.destination}</Destination>
                        <Date>{moment(itinerary.starting_date).utc().format('DD/MM/YYYY')}-{moment(itinerary.starting_date).add(Number(itinerary.number_of_days), 'days').utc().format('DD/MM/YYYY')}</Date>
                    </Itinerary>
                })}
            </ItinerariesContainer>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const Title = styled.h3`
    text-align: center;
    margin-bottom: 0;
`;

const Paragraph = styled.p`
    text-align: center;
    line-height: 28px;
`;

const ItinerariesContainer = styled.div`
    width: 95%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    max-height: 350px;
    overflow-y: auto;
    overflow-x: hidden;
    gap: 20px;
`;

const Itinerary = styled.div`
    width: 90%;
    padding: 5px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid grey;
    border-radius: 8px;
    cursor: pointer;
`;

const Destination = styled.p`

`;

const Date = styled.p`

`;

export default TravelerItineraries;