import axios from 'axios';
import { IOnBoarding_New } from '../../components/pages/Onboarding/interfaces';
import { IExploreAttractions } from '../@types/feed';
import { Endpoints } from '../utils/endpoints';
import StorageService from '../../core/services/storage';
import Calls from './calls';
import { convertAttractionEventDetails } from './converts';

const {
  bridgify: { get, post,get_data,post_data,delete_event, put_data },
} = Calls;

const BASE_URL = process.env.REACT_APP_API_ENDPOINT;
const GRANT_TYPE = "grant_type";
const CLIENT_CREDENTIALS = "client_credentials";
const SCOPE = "scope";
const READ_WRITE = "read write";
const CLIENT_ID = "client_id";
const SECRET = "client_secret";
const API_ENDPOINT = `${BASE_URL}/attractions/products/?itinerary=True&city_name=`;
const API_ENDPOINT_BY_ID=`${BASE_URL}/attractions/products/?itinerary=True&city_id=`;
const BRIDGIFY_API = BASE_URL;
//Defined as a POST request because of its payload
export const createTrip = async (payload, tokenless: boolean) =>
  post(Endpoints.createTrip, payload, tokenless);

export const deleteEvent = async (payload: any) => post(Endpoints.deleteEvent, payload);

export const getDayItinerary = async (path: string) => get(path);

export const getInterestById = async (path: string) => get(path);

export const addAttractionToDayItinerary = async (path: string, payload: any) => post(path, payload);

export const getTripDetails = async (): Promise<ITripDetails_Response> => get(Endpoints.tripDetails);

export const deleteEventNewApi=async(eventId:string)=>delete_event(`${Endpoints.deleteItineraryEvents}?event_id=${eventId}`,'bridgify')

export const replaceEventNewApi=async(eventId:string, atr_id:string)=>put_data(`${Endpoints.deleteItineraryEvents}?event_id=${eventId}&attraction_id=${atr_id}`,'bridgify')

export const getSavedItinerary = async (token:string):Promise<any>=>{
  return get(Endpoints.getItinerary+token+'/');
}
export const getOnboardingData=async(clientName:string):Promise<any>=>get_data(Endpoints.planTrip+clientName+'/')

export const getOnboardingDataByProp=async(clientName:string, property:string):Promise<any>=>get_data(`${Endpoints.planTrip+clientName}?property=${property}`)

export const getOnboardingDataByPropId=async(clientName:string, property_id:string):Promise<any>=>get_data(`${Endpoints.planTrip+clientName}?property_id=${property_id}`)



// Todo
// export const getOnBoardingData = async (): Promise<IOnBoarding_New> => get("dfkjlsdfklj");

export const createTrip_New = async (payload, tokenless?: boolean): Promise<any>  =>
  post(Endpoints.createTrip, payload, tokenless);

export const postOnBoardingData = async (clientName: string, data: any): Promise<any> => {
  return post_data(Endpoints.planTrip+clientName + '/', data);
}
//

export const getClientDesign = async (name: string, tokenless?: boolean) =>
  post(Endpoints.clientDesign, { name }, tokenless);


interface IAllSetteled {
  status: 'fulfilled' | 'rejected';
  value: any[];
}

export async function getExploreAttractions(interests: number[]) {
  // ): { [index: number]: Array<EventDetails_Attraction> } {
  const requests = interests.map((interest: number) =>
    getInterestById(Endpoints.moreAttractions + interest),
  );
  let mappedAttractions: IExploreAttractions = {};
  try {
    const response: any = await Promise.allSettled(requests);
    for (let i = 0; i < response.length; i++) {
      //Make sure there's no crash in case of failure of a single Promise
      //The mapped attractions who successed will be stored
      try {
        const promiseSettledResponse: IAllSetteled = response[i];
        if (promiseSettledResponse.status === 'fulfilled') {
          const promiseValue: EventDetails_Attraction[] = promiseSettledResponse.value;
          if (!promiseValue || promiseValue.length < 1) continue;

          const interestAttractions = mapAttractionOfInterestsToArray(promiseValue);

          if (!interestAttractions) continue;
          mappedAttractions = {
            ...mappedAttractions,
            [interests[i]]: interestAttractions,
          };
        }
      } catch (error) {
        continue;
      }
    }
  } catch (error) {
    // console.error("getAttractionPerInterest", error);
  }

  return mappedAttractions;
}

function mapAttractionOfInterestsToArray(
  attractionsArray: EventDetails_Attraction[],
): ITripItem_Attraction[] {
  const convertedArray: ITripItem_Attraction[] = attractionsArray.map(
    (attraction: EventDetails_Attraction) => {
      const convertedAttraction = convertAttractionEventDetails(attraction);
      return convertedAttraction;
    },
  );
  return convertedArray;
}

// export const replaceEventNewApi = async (eventId:string, atr_id:string) => {
//     try {
//    const data = await axios.put(`${Endpoints.deleteItineraryEvents}?event_id=${eventId}&attraction_id=${atr_id}`);
//     const err = null;
//     return { data, err };
//   } catch (err) {
//     const data = null;
//     return { err, data };
//   }
// };
export const getAttractionData = async (city: string, time) => {
  try {
    let token = StorageService.local.get.item('OauthToken');
    let dates = JSON.parse(localStorage.getItem('date')!);
    const {start, end} =  dates
    const {start_time, end_time} = time
    //  const { data } = await axios.get(API_ENDPOINT + city + '&limit=50' + "&date_from=" + start + "&date_to=" + start + "&time_from=" + start_time + "&time_to=" + end_time);
    const { data } = await axios.get(`${API_ENDPOINT}${city}&limit=50&date_from=${start}&date_to=${start}&time_from=${start_time}&time_to=${end_time}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const err = null;
    return { data, err };
  } catch (err) {
    const data = null;
    return { err, data };
  }
};
export const getAttractionCity = async (city: string) => {
  try {
    let token = StorageService.local.get.item('OauthToken');
    let dates = localStorage.getItem('date');
    // const {start, end} =  dates;
    // const { data } = await axios.get(API_ENDPOINT + city + '&limit=50');
    const { data } = await axios.get(`${API_ENDPOINT}${city}&date_from=${dates}&date_to=${dates}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const err = null;
    return { data, err };
  } catch (err) {
    const data = null;
    return { err, data };
  }
};

export const getAttractionDataById = async (id: string, time) => {
  try {
    let token = StorageService.local.get.item('OauthToken')
    let dates = JSON.parse(localStorage.getItem('dates')!);
    const {start, end} =  dates
    const {start_time, end_time} = time
    // const { data } = await axios.get(API_ENDPOINT + city + '&limit=50');
    const { data } = await axios.get(API_ENDPOINT_BY_ID + id + '&limit=50' + "&date_from=" + start + "&date_to=" + start + "&time_from=" + start_time + "&time_to=" + end_time, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const err = null;
    return { data, err };
  } catch (err) {
    const data = null;
    return { err, data };
  }
};

export const getAttractionByCityId = async (id: string) => {
  try {
    let token = StorageService.local.get.item('OauthToken')
    let dates = JSON.parse(localStorage.getItem('dates')!);
    const {start, end} =  dates;
    if(token){
      //  const { data } = await axios.get(API_ENDPOINT_BY_ID + id + '&limit=50' + "&date_from=" + start + "&date_to=" + end );
      const { data } = await axios.get(API_ENDPOINT_BY_ID + id + '&limit=50' + "&date_from=" + start + "&date_to=" + end, {
        headers: {
          Authorization: `Bearer ${token}`
        },
      });
      const err = null;
      return { data, err };
    }else{
      return
    }  
  } catch (err) {
    const data = null;
    return { err, data };
  }
};


export async function getOauthToken() {
  const { REACT_APP_CLIENT_ID, REACT_APP_SECRET } = process.env;
  const params = new URLSearchParams();
  params.append(GRANT_TYPE, CLIENT_CREDENTIALS);
  params.append(SCOPE, READ_WRITE);
  params.append(CLIENT_ID, REACT_APP_CLIENT_ID!);
  params.append(SECRET, REACT_APP_SECRET!);
  const URL = BRIDGIFY_API + Endpoints.token;
  try {
    const response = await axios.post(URL, params, {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    });
    return response;
  } catch (error) {
    console.log(error);
  }
}

