import GoogleMapReact from 'google-map-react';
import Marker from './Marker/Marker';

interface IProps {
  location: ILocation;
  index?: number;
}

const MapContainer = ({ location, index }: IProps) => {
  const API_KEY: string = process.env.REACT_APP_GOOGLE_API_KEY || '';
  return (
    <GoogleMapReact
      center={location}
      yesIWantToUseGoogleMapApiInternals
      zoom={11}
      bootstrapURLKeys={{
        key: API_KEY,
        libraries: ['places', 'geometry', 'drawing', 'visualization'],
      }}
    >
      {/* @ts-ignore */}
      <Marker index={index ? index : 0} lat={location.lat} lng={location.lng} />
    </GoogleMapReact>
  );
  return null
};

export default MapContainer;
