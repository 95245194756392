import { useState, useEffect, useReducer } from "react"
import { Button, LinearProgress, Collapse } from "@mui/material"
import { getGroupsByDate } from "../../../../core/services/musement"
import styled from "styled-components"
import { useBookingTicketsContext } from "./useBookingTickets"
import { ErrorAlert } from '../../../shared/Alerts'
import LanguagesSelection from './LanguagesSelection'
import { TimeSelection } from "./TimeSelection"
import { useFeed } from "../../Feed/useFeed"

type TActions = 'LOADING' | 'ERROR' | 'DATA'
interface IAction {
  type: TActions;
  payload: any;
}
const initialState = {
  loading: true,
  error: false
}
type IState = typeof initialState & {
  data?: IGroup[];
}
export default function Groups() {

  function reducer(state: IState, action: IAction): IState {
    const { payload, type } = action
    switch (type) {
      case 'DATA':
        return { ...state, data: payload };
      case 'ERROR':
        return { ...state, error: payload };
      case 'LOADING':
        return { ...state, loading: payload };
      default:
        return { ...state };
    }
  }

  const [{ error, loading, data, }, dispatch] = useReducer(reducer, initialState)
  const { state: { selectedItemTickets } } = useFeed()

  const { dispatch: providerDispatch, handleNext: NextStep,
    state: { selectedPickup, selectedDate: date },
  } = useBookingTicketsContext();

  const [selectedGroup, setSelectedGroup] = useState<IGroup>()

  useEffect(() => {
    async function initGroups() {
      try {
        if (!selectedItemTickets) throw new Error("No item was found");
        const groups: IGroup[] = await getGroupsByDate(selectedItemTickets.uuid, date?.day, selectedPickup?.uuid);

        dispatch({ type: "DATA", payload: groups });
      } catch (error) {
        dispatch({ type: "ERROR", payload: true })
      } finally {
        dispatch({ type: "LOADING", payload: false })
      }
    }
    initGroups()
  }, [])


  // function handleSelectGroup(group: IGroup) {
  //   // if (selectedGroupIndex === index) return;
  //   setSelectedGroup(group);
  //   // providerDispatch({ type: "SELECTED_GROUP", payload: group })
  //   // handleSelectTimeSlot(0, group.slots)
  // }

  const [selectedTimeSlot, setSelectedTimeSlot] = useState<ISlot | undefined>(selectedGroup?.slots[0])

  // function handleSelectTimeSlot(timeSlot: ISlot) {
  //   // providerDispatch({ type: "TIMESLOT_INDEX", payload: TimeSlotIndex })
  //   // providerDispatch({ type: "PRODUCTS", payload: slots[TimeSlotIndex].products })
  //   // providerDispatch({ type: "TIME", payload: slots[TimeSlotIndex].time })
  // }

  const [selectedLangauge, setSelectedLangauge] = useState<ILanguage | undefined>()

  // function handleSelectLanguage(language: ILanguage): void {
  //   // providerDispatch({ type: "SELECTED_LANGUAGE_INDEX", payload: index })
  //   providerDispatch({ type: "LANGUAGE", payload: language })
  // }

  function handleNext() {
    const timeSlot = selectedTimeSlot || selectedGroup?.slots[0];
    const language = selectedLangauge || selectedGroup?.slots[0]?.languages[0]
    providerDispatch({ type: "SELECTED_GROUP", payload: selectedGroup })
    providerDispatch({ type: "TIMESLOT", payload: timeSlot })
    providerDispatch({ type: "LANGUAGE", payload: language })
    providerDispatch({ type: "PRODUCTS", payload: timeSlot?.products })

    NextStep();
  }

  const TIMESLOTS_OPTIONS_DISPLAY: boolean = selectedItemTickets?.bookingType === "CALENDAR-TIMESLOTS";

  return !error ? (
    !loading ? <>
      {data?.map((group: IGroup) => {
        const { name, slots = [] } = group;
        const SELECTED: boolean = selectedGroup === group
        return (
          <GroupContainer
            key={name}
            selected={SELECTED}
            onClick={() => setSelectedGroup(group)}>
            <ActivityTitle>{name}</ActivityTitle>
            <Collapse in={SELECTED} timeout="auto" unmountOnExit>
              {TIMESLOTS_OPTIONS_DISPLAY &&
                <TimeSelection
                  selectedTimeSlot={selectedTimeSlot}
                  setSelectedTimeSlot={setSelectedTimeSlot}
                  // handleSelectTimeSlot={handleSelectTimeSlot}
                  slots={slots} />}
              {slots[0]?.languages?.length > 0 &&
                <LanguagesSelection selectedLangauge={selectedLangauge}
                  setSelectedLangauge={setSelectedLangauge}
                  // handleSelectLanguage={handleSelectLanguage}
                  languages={slots[0].languages} />}
            </Collapse>

          </GroupContainer>
        )
      })}
      <Button
        onClick={handleNext}
        variant="contained"
        color="primary"
        disabled={selectedGroup === undefined}>
        Continue
      </Button>
    </> : <LinearProgress />

  ) : <ErrorAlert >OOPS! We're Sorry, it seems like all dates are already sold out.</ErrorAlert>
}



const GroupContainer = styled("div") <{ selected: boolean }>`
  padding:5px 10px;
  border: ${props => props.selected ? '3px solid #3f51b5' : '1px solid black'};
  border-radius: 10px;
  margin-bottom:1vh;

  display:flex;
  flex-direction:column;
  justify-content:space-between;
  text-align: left;
`
const ActivityTitle = styled.div`
  font-weight: 600;
`;
