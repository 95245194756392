import { Button, LinearProgress } from "@mui/material"
import { useState, useEffect, useReducer } from "react"
import styled from "styled-components"
import { getDatesByActivityID } from "../../../../core/services/musement"
import { useBookingTicketsContext } from "./useBookingTickets"
import { ErrorAlert } from '../../../shared/Alerts'
import { useTripDetails } from "../../../../providers/TripDetailsProvider"
import { useFeed } from "../../Feed/useFeed"

type TActions = 'LOADING' | 'ERROR' | 'DATA'
interface IAction {
  type: TActions;
  payload: any;
}
const initialState = {
  loading: true,
  error: false
}
type IState = typeof initialState & {
  data?: IDate[];
}

export default function Dates() {
  function reducer(state: IState, action: IAction): IState {
    const { payload, type } = action
    switch (type) {
      case 'DATA':
        return { ...state, data: payload };
      case 'ERROR':
        return { ...state, error: payload };
      case 'LOADING':
        return { ...state, loading: payload };
      default:
        return { ...state };
    }
  }
  const [{ error, loading, data }, dispatch] = useReducer(reducer, initialState)

  const { state: { selectedItemTickets } } = useFeed()
  const { tripDetails: { tripAccommodation: { start_date, finish_date } } } = useTripDetails()
  const { handleNext,
    state: { selectedPickup }, dispatch: providerDispatch } = useBookingTicketsContext()

  // const [error, setError] = useState<string | null>(null)
  // const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function initDates() {
      try {
        if (!selectedItemTickets) throw new Error("No uuid");
        const { uuid } = selectedItemTickets;
        const dates: IDate[] = await getDatesByActivityID(uuid, start_date, finish_date, selectedPickup?.uuid)
        dispatch({ type: "DATA", payload: dates })
        // providerDispatch({ type: "DATES_LIST", payload: dates })
        // setLoading(false)
      } catch (error) {
        dispatch({ type: "ERROR", payload: true })
      } finally {
        dispatch({ type: "LOADING", payload: false })
      }
    };
    initDates()
  }, [])

  function handleSelectDate(date: IDate): void {
    providerDispatch({ type: "SELECTED_DATE", payload: date })
    handleNext();
  }

  return !error ? (
    !loading ?
      <>
        {data?.map((date, index) => {
          const { day, sold_out = false } = date
          const dayWithName = new Date(day)
          const dateString = dayWithName.toDateString()
          const shorthen = dateString.slice(0, dateString.length - 4)
          return (
            <StyledButton
              disabled={sold_out}
              key={index + day}
              value="day"
              onClick={() => handleSelectDate(date)}
              variant="contained"
            >
              {sold_out && <SoldOutLabel >SOLD OUT</SoldOutLabel>}
              (Day {index + 1}) <br />
              {shorthen.substring(0, 3) + ", " + shorthen.substring(4, shorthen.length)}
            </StyledButton >
          )
        })}
      </> : <LinearProgress />
  ) : <ErrorAlert >Sorry, all dates are already sold out</ErrorAlert>
}


const StyledButton = styled(Button)`
  &&{
    background-color: white;
    border: black 1px solid;
    width: auto;
    margin: 1vh;
    font-size:1.5vh;
    color:black;
  }
`

const SoldOutLabel = styled.label`
  position: absolute;
  background-color: #FE4042;
  border-radius: 5px;
  transform: rotateY(0deg) rotate(20deg);
  width: 100%;
  color: white;
  opacity: 0.8;
`;
