import { SvgIconTypeMap } from '@mui/material/';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import React, { useEffect, useRef, useState } from 'react';
import { Icons } from '../../../assets/images/images';
import Mixpanel, { MP_Events, MP_Pages, MP_Props } from '../../../core/services/mixpanel';
import { clearHash, setHash, turnSpacesToUnderline } from '../../../core/utils/common';
import {
  AttractionStates,
  BookedStatuses,
  HASH,
  TRIP_ITEMS
} from '../../../core/utils/enums';
import {
  navigateToOrdersPage,
  navigationHandler,
  scrollWindowToTop
} from '../../../core/utils/feed';
import { useTripDetails } from '../../../providers/TripDetailsProvider';
import { useFeed } from '../Feed/useFeed';
import ItemCard from './ItemCard';
import ItemDialog from './ItemInfoDialog';
import BookedAttrDialog from './BookedAttrDialog';
import ParamsService from '../../../core/services/params';
// import { useLayout } from "../../../providers/LayoutProvider";

export interface IItemButtonsDic {
  text: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  buttonColor: string;
  textColor?: string;
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> | null | string;
  disabled?: boolean;
}

// item: ITripItem_Resturant | ITripItem_Attraction;
interface IProps {
  item: any;
  TIMELINE?: boolean;
  onDelete?(id: number | null): void;
  componentLocation?: 'Feed' | 'Explore';
  isAttractionAlreadyInTrip?: boolean;
  last?: boolean;
  ACTIVITIES_COUNTER?: number;
  handleReplace: (e: any) => void;
  handleRemove: (e: any) => void;
  handleSelectToAdd: (e: any) => void;
  handleOpenExplore: () => void;
  showReplaceAttrDialog?: boolean
}

export default function Item({
  item,
  item: { _id, location, name, orderPage, tags, type },
  last,
  ACTIVITIES_COUNTER,
  onDelete = () => {},
  TIMELINE,
  isAttractionAlreadyInTrip = true,
  componentLocation = 'Feed',
  handleReplace,
  handleRemove,
  handleSelectToAdd,
  handleOpenExplore,
  showReplaceAttrDialog
}: IProps) {
  //@ts-ignore

  if(showReplaceAttrDialog === undefined){
    showReplaceAttrDialog = false
  }
  // const bookingStatus: BookedStatuses = item?.bookingStatus || BookedStatuses.Booked;
  let bookingStatus = item.bookingStatus;
  // let Booked = true;
  //@ts-ignore
  const uuid: string | null = item?.uuid ? item.uuid : null;
  //@ts-ignore
  const source: ITripItem_Attraction['source'] | null = item?.source ? item.source : null;
  //@ts-ignore
  const pickup: IPickupDetails | null = item?.pickup ? item.pickup : null;
  const [isInTrip, setisInTrip] = useState(isAttractionAlreadyInTrip);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const dialogRef = useRef<HTMLHeadingElement>(null);
  const [openInfoDialog, setOpenInfoDialog] = useState(false);
  const [openDialogBookedAttr, setOpenDialogBookedAttr] = useState<boolean>(false);
  const [reRender, setReRender] = useState(false);
  const {
    tripDetails: {
      _id: trip_id,
      tripDays,
      ClientDesign: { PrimaryColor, SecondaryColor, TertiaryColor, name: clientName },
    },
    updateTripDetails,
  } = useTripDetails();

  const {
    dispatch,
    state: { openTiqetsBookingDialog, selectedExplore, selectedReplace, isOpenExplore, isOpenRemoveExplore, isSelectedAtr },
  } = useFeed();
  // const {
  //   
  //  } = FeedProvider();
  // const { isMobile } = useLayout()
  useEffect(() => {
    scrollWindowToTop();
  }, []);

  function handleOpenItemDialog(): void {
    setHash(HASH.Info, _id);
    Mixpanel.track(MP_Events.ItemCard, {
      Page: MP_Pages.Feed,
      tags: tags.join(', '),
      title: name,
      [MP_Props.client]: clientName,
      [MP_Props.property]: ParamsService.getProperty() || "none",
      [MP_Props.testing]: ParamsService.getInHouseTest() ? "yes" : "no"
    });
    setOpenInfoDialog(true);
  }

  function closeInfoDialog() {
    clearHash();
    setOpenInfoDialog(false);
  }

  function handleBooked(e){
    e.stopPropagation();
    setOpenDialogBookedAttr(true)
  }
  function handleCloseBookedAttr(){
    setOpenDialogBookedAttr(false)
  }

  function handleNavigation(event) {
    event.stopPropagation();
    Mixpanel.track(MP_Events.Navigate, {
      Page: MP_Pages.Feed,
      tags: tags.join(', '),
      title: name,
      [MP_Props.client]: clientName,
      [MP_Props.property]: ParamsService.getProperty() || "none",
      [MP_Props.testing]: ParamsService.getInHouseTest() ? "yes" : "no"
    });
    let locationToNavigate: ILocation = location;
    // Pickup?--> navigate to pickup location
    if (pickup) {
      const { lat, lng } = pickup;
      locationToNavigate = { lat, lng };
    }
    navigationHandler(locationToNavigate);
  }

  async function handleBookNow(event) {
    event.stopPropagation();
    if (uuid) {
      Mixpanel.track(MP_Events.BookNow, {
        Page: MP_Pages.Feed,
        tags: tags.join(', '),
        title: name,
        [MP_Props.client]: clientName,
        [MP_Props.property]: ParamsService.getProperty() || "none",
        [MP_Props.testing]: ParamsService.getInHouseTest() ? "yes" : "no"
      });
      dispatch({ type: 'SELECTED_ITEM_BOOK', payload: item });
      setHash(HASH.BookingInfo, turnSpacesToUnderline(name));
      if (
        source === 'Tiqets' ||
        source === 'SportsEvents365' ||
        source === 'Eventim' ||
        source === 'Tickitto'
      ) {
        dispatch({ type: 'TOGGLE_TIQETS_DIALOG' });
      } else if (source === 'Musement') {
        // dispatch({ type: 'TOGGLE_BOOKING_FLOW_DIALOG' });
        navigateToOrdersPage(orderPage);
      } else {
        if (!orderPage) return;

        navigateToOrdersPage(orderPage);
      }
    }
  }
  
  function setAttractionState(): AttractionStates {
    //If musement activity? map depends on the activity state
    if (item.name === 'Free Time' ) return AttractionStates.NotAvailable;
    if (bookingStatus) return AttractionStates.Booked;
    if (isOpenRemoveExplore && isOpenExplore) return AttractionStates.Remove;
    if (isOpenExplore && selectedReplace) return AttractionStates.Replace;
    if (isOpenExplore && !isOpenRemoveExplore && !isSelectedAtr) return AttractionStates.AddToTrip;
    if (isInTrip && item.name !== 'Free Time' && type !== 2 && !isOpenExplore && !isOpenRemoveExplore && source !== "Google Place" && source !== "GoogleMaps" && !bookingStatus) return AttractionStates.BookNow;
    if (source === "GoogleMaps" && !isOpenExplore && !isOpenRemoveExplore) return AttractionStates.Navigate;
    if (source === "Google Place" && !isOpenExplore && !isOpenRemoveExplore) return AttractionStates.Navigate;
    if (type === 2 && !isOpenRemoveExplore && !isOpenExplore) return AttractionStates.Navigate;
    // if (uuid && bookingStatus !== BookedStatuses.NotBooked) 
    //   return MusementToItemButtonStateMapper[bookingStatus];
    
      return AttractionStates.Navigate;
  }
  
  const attractionState: AttractionStates = setAttractionState();

  const ButtonsDic: { [key in AttractionStates]: IItemButtonsDic } = {
    [AttractionStates.AddToTrip]: {
      text: 'Add To Trip',
      buttonColor: PrimaryColor.bgColor,
      textColor: PrimaryColor.textColor,
      onClick: () => handleSelectToAdd(item),
      icon: null,
      disabled: false
    },
    [AttractionStates.Replace]: {
      text: 'Replace Event',
      buttonColor: PrimaryColor.bgColor,
      textColor: PrimaryColor.textColor,
      onClick: () => handleReplace(item),
      icon: null,
      disabled: false
    },
    [AttractionStates.Remove]: {
      text: 'Remove from Trip',
      buttonColor: PrimaryColor.bgColor,
      textColor: PrimaryColor.textColor,
      onClick: () => handleRemove(item),
      icon: null,
      disabled: false
    },
    [AttractionStates.NotAvailable]: {
      text: '',
      buttonColor: PrimaryColor.bgColor,
      textColor: PrimaryColor.textColor,
      onClick: (e: any) => console.log('not available'),
      icon: null,
      disabled: false
    },
    [AttractionStates.BookNow]: {
      text: bookingStatus === BookedStatuses.Pending ? 'Awaiting Approval' : 'Book Now',
      buttonColor: type === TRIP_ITEMS.Attraction ? PrimaryColor.bgColor : SecondaryColor.bgColor,
      textColor: type === TRIP_ITEMS.Attraction ? PrimaryColor.textColor : SecondaryColor.textColor,
      onClick: (e) => handleBookNow(e),
      icon: null,
      disabled: bookingStatus === BookedStatuses.Pending,
    },
    [AttractionStates.Navigate]: {
      text: 'Navigate',
      buttonColor: 'white',
      textColor: 'black',
      onClick: (e) => handleNavigation(e),
      // icon: DirectionsOutlinedIcon
      icon: Icons.navigate,
      disabled: false
    },
    [AttractionStates.Booked]: {
      text: 'Booked',
      textColor: 'white',
      buttonColor: 'purple',
      onClick: (e) => handleBooked(e),
      // icon: DirectionsOutlinedIcon
      icon: null,
      disabled: false
    },
  };

  useEffect(() => {
    setReRender(!reRender);
  }, [isOpenExplore, isOpenRemoveExplore]);

  return (
    <>
      <ItemCard
        item={item}
        ButtonProps={ButtonsDic[attractionState]}
        showSpinner={showSpinner}
        handleOpenItemDialog={handleOpenItemDialog}
        componentLocation={componentLocation}
        onDelete={onDelete}
        ACTIVITIES_COUNTER={ACTIVITIES_COUNTER}
        TIMELINE={TIMELINE}
        last={last}
        handleOpenExplore={handleOpenExplore}
      />
       {openInfoDialog ? 
       <ItemDialog
          item={item}
          openInfoDialog={openInfoDialog}
          ButtonProps={ButtonsDic[attractionState]}
          showSpinner={showSpinner}
          handleClose={closeInfoDialog}
          ACTIVITIES_COUNTER={ACTIVITIES_COUNTER}
        /> : null}
  
      {openDialogBookedAttr ? (
        <BookedAttrDialog
          item={item}
          openDialogBookedAttr={openDialogBookedAttr}
          handleCloseBookedAttr={handleCloseBookedAttr}
        />
      ) : null}
    </>
  );
}