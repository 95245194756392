import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import useResponsive from "../../../hooks/useResponsive";

import { travelers } from "../../../../Actions/onboardingActions";

const TravelerTypes = () => {
  const dispatch = useDispatch();

  const onboarding = useSelector((state: any) => state.onboarding);
  const {design} = useSelector((state: any) => state.clientDesign);

  // const responsive = useResponsive(580);

  const handleSelectTravelerType = (index: number) => {
    dispatch(travelers(index));
  }

  return(
    <Container>
      <TravelerBtn 
        style={{
          background: onboarding.travelers.includes(0) ? design?.PrimaryColor?.bgColor : "transparent",
          color: onboarding.travelers.includes(0) ? design?.PrimaryColor.textColor : "black"
        }} 
        onClick={() => handleSelectTravelerType(0)}
        // onTouchStart={() => responsive && handleSelectTravelerType(0)}
      >
        Adults
      </TravelerBtn>
      <TravelerBtn
        style={{
          background: onboarding.travelers.includes(1) ? design?.PrimaryColor?.bgColor : "transparent",
          color: onboarding.travelers.includes(1) ? design?.PrimaryColor.textColor : "black"
        }} 
        onClick={() => handleSelectTravelerType(1)}
        // onTouchStart={() => responsive && handleSelectTravelerType(1)}
      >
        Children
      </TravelerBtn>
      <TravelerBtn 
        style={{
          background: onboarding.travelers.includes(2) ? design?.PrimaryColor?.bgColor : "transparent",
          color: onboarding.travelers.includes(2) ? design?.PrimaryColor.textColor : "black"
        }} 
        onClick={() => handleSelectTravelerType(2)}
        // onTouchStart={() => responsive && handleSelectTravelerType(2)}
      >
        Infants
      </TravelerBtn>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  gap: 5px;
`;

const TravelerBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  border: 1px solid #1E293B;
  border-radius: 8px;
  background-color: transparent;
  cursor: pointer;
`;

export default TravelerTypes;