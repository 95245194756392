import moment from 'moment';
import { createContext, useContext, useReducer, useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { Icons } from '../../../assets/images/images';
import Mixpanel, { MP_Events, MP_Pages, MP_Props } from '../../../core/services/mixpanel';
import ParamsService from '../../../core/services/params';
import StorageService from '../../../core/services/storage';
import { deleteEventNewApi } from '../../../core/services/trip';
import { setHash } from '../../../core/utils/common';
import { TripItemsDic } from '../../../core/utils/dictionaries';
import { Routes } from '../../../core/utils/endpoints';
import { HASH, TRIP_ITEMS } from '../../../core/utils/enums';
import { useTripDetails } from '../../../providers/TripDetailsProvider';

type TFeedActions =
  | 'CURRENT_DAY_INDEX'
  | 'SELECTED_ITEM_BOOK'
  | 'OPEN_MAP_VIEW'
  | 'RESET_BOOKING'
  | 'SELECTED_ITEM_TICKETS'
  | 'SELECTED_ITEM_PAYMENT'
  | 'TOGGLE_BOOKING_FLOW_DIALOG'
  | 'OPEN_EMAIL_DIALOG'
  | 'TOGGLE_TIQETS_DIALOG'
  | 'SELECTED_ITEM_REPLACE'
  | 'SELECTED_ITEM_EXPLORE'
  | 'OPEN_EXPLORE'
  | 'OPEN_REMOVE_EXPLORE'
  | 'IS_REPLACE'
  | 'UPDATE_MARKERS_ATTRACTIONS'
  | 'REPLACE_EXPLORE'
  | 'OPEN_ITEM_DESCRIPTION'
  | 'IS_SELECTED_ATR'
  | 'SET_ITINERARY'
  | 'SELECTED_ITEM_ADD_TO_TRIP';

interface IAction {
  type: TFeedActions;
  payload?: any;
}

interface IUseFeed {
  state: IState;
  dispatch: React.Dispatch<IAction>;
  handleOpenReplaceAttr?,
  handleCloseReplaceAttr?
}
interface IState {
  currentDayIndex: number;
  selectedItemBook?: ITripItem_Attraction;
  selectedItemTickets?: IActivity_Converted;
  selectedItemPayment?: boolean;
  openEmailDialog: boolean;
  openTiqetsBookingDialog: boolean;
  showMapView: boolean;
  showBookingFlowDialog: boolean;
  selectedReplace: any;
  selectedExplore: any;
  isOpenExplore: boolean;
  isOpenRemoveExplore: boolean;
  isReplace: boolean;
  isSelectedAtr: boolean;
  replaceExplore: boolean;
  isOpenDescription: boolean;
  selectedAddToTrip: any;
  setItinerary: any;
  markersAttraction: any;
}
const initialState: IState = {
  currentDayIndex: StorageService.session.get.currentDay() || 0,
  openEmailDialog: false,
  showMapView: false,
  openTiqetsBookingDialog: false,
  showBookingFlowDialog: false,
  isOpenExplore: false,
  isOpenRemoveExplore: false,
  isSelectedAtr: false,
  replaceExplore: false,
  isOpenDescription: false,
  isReplace: false,
  selectedReplace: '',
  selectedExplore: '',
  selectedAddToTrip: {},
  markersAttraction: {},
  setItinerary: [],
};

export const FeedContext = createContext<IUseFeed>({ state: initialState, dispatch: () => {}});
export const useFeed = () => useContext<IUseFeed>(FeedContext);

export function FeedProvider(): typeof exports {
  const history = useHistory();
  const {
    tripDetails: {
      ClientDesign: { name: clientName },
      _id,
      tripAccommodation: { start_date },
      tripDays,
    },
    updateTripDetails,
  } = useTripDetails();

  const [state, dispatch] = useReducer(reducer, initialState);
  const [showExploreDialog, setShowExploreDialog] = useState(false);
  const [showReplaceAttrDialog, setShowReplaceAttrDialog] = useState(false);
  const { currentDayIndex, showMapView } = state;
  let updatedCurrentDay: number;
  useEffect(() => {
    updatedCurrentDay = currentDayIndex;
  }, [currentDayIndex])

  function reducer(state: IState, action: IAction): IState {
    const { payload, type } = action;
    switch (type) {
      case 'CURRENT_DAY_INDEX':
        return { ...state, currentDayIndex: payload };
      case 'OPEN_EMAIL_DIALOG':
        return { ...state, openEmailDialog: payload };
      case 'SET_ITINERARY':
        return { ...state, setItinerary: payload };
      case 'SELECTED_ITEM_BOOK':
        return { ...state, selectedItemBook: payload };
      case 'SELECTED_ITEM_REPLACE':
        console.log("SELECTED_ITEM_REPLACE", payload);
        return { ...state, selectedReplace: payload };
      case 'SELECTED_ITEM_EXPLORE':
        return { ...state, selectedExplore: payload };
      case 'SELECTED_ITEM_ADD_TO_TRIP':
        return { ...state, selectedAddToTrip: payload };
      case 'SELECTED_ITEM_TICKETS':
        return { ...state, selectedItemTickets: payload };
      case 'SELECTED_ITEM_PAYMENT':
        return { ...state, selectedItemPayment: payload };
      case 'OPEN_MAP_VIEW':
        return { ...state, showMapView: payload };
      case 'OPEN_EXPLORE':
        return { ...state, isOpenExplore: payload };
      case 'OPEN_ITEM_DESCRIPTION':
        return { ...state, isOpenDescription: payload };
      case 'OPEN_REMOVE_EXPLORE':
        return { ...state, isOpenRemoveExplore: payload };
      case 'REPLACE_EXPLORE':
        return { ...state, replaceExplore: payload };
      case 'IS_SELECTED_ATR':
        return { ...state, isSelectedAtr: payload };
      case 'IS_REPLACE':
        return { ...state, isReplace: !state.isReplace };
      case 'UPDATE_MARKERS_ATTRACTIONS':
        return { ...state, markersAttraction: payload };
      case 'TOGGLE_TIQETS_DIALOG':
        return { ...state, openTiqetsBookingDialog: !state.openTiqetsBookingDialog };
      case 'TOGGLE_BOOKING_FLOW_DIALOG':
        return { ...state, showBookingFlowDialog: !state.showBookingFlowDialog };
      case 'RESET_BOOKING':
        return {
          ...state,
          selectedItemBook: undefined,
          selectedItemTickets: undefined,
          selectedItemPayment: undefined,
        };
    }
  }



  async function onAttractionDelete(index: number) {
    let dayIndex = StorageService.session.get.currentDay();
    const tripDay = tripDays[dayIndex];
    //@ts-ignore
    const attraction: ITripItem_Resturant | ITripItem_Attraction = tripDay.items[index];
    try {
      Mixpanel.track(MP_Events.DeleteItem, {
        Page: MP_Pages.Feed,
        tags: attraction?.tags.join(', '),
        title: attraction?.name,
        [MP_Props.client]: clientName,
        [MP_Props.property]: ParamsService.getProperty() || "none",
        [MP_Props.testing]: ParamsService.getInHouseTest() ? "yes" : "no"
      });
      let deleteCall = await deleteEventNewApi(String(attraction._id));

      updateTripDetails();
      toast.success('The attraction was deleted');
    } catch (error) {
      toast.error('Oops! We had trouble with deleting the activity');
    }
  }


  function navigateToMapView(): void {
    Mixpanel.track(MP_Events.MapIcon, {
      [MP_Props.client]: clientName, 
      [MP_Props.property]: ParamsService.getProperty() || "none",
      [MP_Props.testing]: ParamsService.getInHouseTest() ? "yes" : "no"
    });
    history.push(Routes.mapView, { tripDays, currentDayIndex });
  }

  if(state.isOpenExplore && !showExploreDialog){
    handleOpenRemoveExplore();
  }

  // navigate to explore screen to add itinerary to particular day
  function handleOpenExplore() {
    Mixpanel.track(MP_Events.Explore, {
      [MP_Props.client]: clientName, 
      [MP_Props.property]: ParamsService.getProperty() || "none",
      [MP_Props.testing]: ParamsService.getInHouseTest() ? "yes" : "no"
    });
    // const dayData = { index: currentDayIndex, id: tripDays[currentDayIndex]._id }
    setHash(HASH.Explore, '');
    // dispatch({ type: 'TOGGLE_SHOW_EXPLORE', payload: true });
    setShowExploreDialog(true)
    dispatch({ type: 'OPEN_EXPLORE', payload: true });
    dispatch({ type: 'SELECTED_ITEM_REPLACE', payload: null });
    // history.push(Routes.explore, { day: dayData });
  }
  function handleOpenRemoveExplore() {
    setShowExploreDialog(true)
    dispatch({ type: 'OPEN_EXPLORE', payload: true });
    dispatch({ type: 'IS_SELECTED_ATR', payload: true });
    // history.push(Routes.explore, { day: dayData });
  }

  function handleCloseExplore(){
    dispatch({ type: 'OPEN_EXPLORE', payload: false });
    dispatch({ type: 'SELECTED_ITEM_REPLACE', payload: null });
    dispatch({ type: 'IS_SELECTED_ATR', payload: false });
    // dispatch({ type: 'REPLACE_EXPLORE', payload: false });
    setShowExploreDialog(false)
  }
  function handleCloseExploreReplace(){
    dispatch({ type: 'OPEN_EXPLORE', payload: false });
    dispatch({ type: 'SELECTED_ITEM_REPLACE', payload: null });
    dispatch({ type: 'IS_SELECTED_ATR', payload: false });
    dispatch({ type: 'REPLACE_EXPLORE', payload: false });
    setShowExploreDialog(false)
  }
  function handleOpenReplaceAttr(){
    dispatch({ type: 'OPEN_REMOVE_EXPLORE', payload: true });
    setShowReplaceAttrDialog(true);
  }
  function handleCloseReplaceAttr(){
    dispatch({ type: 'OPEN_REMOVE_EXPLORE', payload: false });
    dispatch({ type: 'SELECTED_ITEM_REPLACE', payload: null });
    dispatch({ type: 'IS_SELECTED_ATR', payload: false });
    setShowReplaceAttrDialog(false)
  }
  const handleMapView = () => {
    dispatch({ type: 'OPEN_MAP_VIEW', payload: !showMapView })
    dispatch({ type: 'CURRENT_DAY_INDEX', payload: state.currentDayIndex })
    StorageService.session.save.currentDay(state.currentDayIndex);
}
  const getLocarionMap = () => {
    dispatch({ type: 'OPEN_MAP_VIEW', payload: !showMapView })
    dispatch({ type: 'CURRENT_DAY_INDEX', payload: state.currentDayIndex })
    StorageService.session.save.currentDay(state.currentDayIndex);
}

  //List of Icons placed on the topbar
  const actionIcons: IActionIcons[] = [
    {
      icon: Icons.map,
      onIconClick: handleMapView,
      text: 'Map View',
    },
    {
      icon: Icons.binocular,
      onIconClick: handleOpenExplore,
      text: 'Explore',
    },
  ];



  const RenderComponent = (item: ITripDay['items'][0], index: number) => {
    const key: TRIP_ITEMS = item.type;
    const Component = TripItemsDic[key].component;
    return (
      <Component
        item={item}
        key={item._id}
        onDelete={() => onAttractionDelete(index)}
        last={index === tripDays[currentDayIndex]?.items.length - 1}
      />
    );
  };

  const ItemsList = tripDays[currentDayIndex]?.items.map((dayItinerary, index) =>
    RenderComponent(dayItinerary, index),
  );

  let currentDayLabel = moment(start_date).add(currentDayIndex, 'days').format('MMM DD');

  // function LocationMapper(selectedDayIndex: number): Array<IItemLocation> {
  //     const selectedDay = tripDays[selectedDayIndex]
  //     // const itemsLocations = selectedDay.items
  //     //     .map((item, index: number) => {
  //     //         const { type } = item
  //     //         //@ts-ignore
  //     //         const location: ILocation | null = item.location || null
  //     //         // if (item.type === TRIP_ITEMS.Commute) return null;
  //     //         return location ? { type, index, location } : null
  //     //     })
  //     // //@ts-ignore
  //     // const filteredUndefiend: Array<IItemLocation> = itemsLocations.filter(item => item !== null);
  //     // return filteredUndefiend;
  //     let itemsLocations = []
  //     selectedDay.items.forEach((item, index: number) => {
  //         if (item.type !== TRIP_ITEMS.Commute) {
  //             const { type } = item
  //             const location: ILocation | null = item.location || null
  //             itemsLocations.push([{ type, index, location }])
  //         }
  //     })

  //     //@ts-ignore
  //     const filteredUndefiend: Array<IItemLocation> = itemsLocations.filter(item => item !== null);
  //     return filteredUndefiend;
  // }

  const exports = {
    currentDayLabel,
    ItemsList,
    RenderComponent,
    actionIcons,
    state,
    dispatch,
    navigateToMapView,
    onAttractionDelete,
    reducer,
    showExploreDialog,
    setShowExploreDialog,
    setShowReplaceAttrDialog,
    showReplaceAttrDialog,
    handleOpenExplore,
    handleCloseExplore,
    handleOpenReplaceAttr,
    handleCloseReplaceAttr,
  };
  return exports;
}
