import { useState, useEffect } from "react";
import {useDispatch, useSelector} from 'react-redux';
import { TextField } from "@rmwc/textfield";
import { Button } from "@rmwc/button";
import { useHistory } from "react-router-dom";
import { pageVerificationEmail } from '../../../../Actions/emailVerification'
import { validateCode, validateEmail } from '../../../../core/utils/validations';
import styled from "styled-components";
import { useTripDetails } from '../../../../providers/TripDetailsProvider';
import '@rmwc/textfield/styles';
import { sendVerificationCode, sendVerificationEmail } from '../../../../core/services/emailVerification';
import StorageService from '../../../../core/services/storage';
import toast from 'react-hot-toast';



const SetCodeVerification = () => {
  const [codeValue, setCodeValue] = useState<string>("");
  const [codeValid, setCodeValid] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [travelerId, setTravelerId] = useState<any>();
  const {setTripDetailsWrapper, convertToOldFeedData} = useTripDetails();
  const {email} = useSelector((state: any) => state.verificarionEmail);
  const {design} = useSelector((state: any) => state.clientDesign);
  let dispatch = useDispatch()
  const {
    tripDetails: {
      ClientDesign: { name: client },
      _id: trip_id,
    },
  } = useTripDetails();

  useEffect(() => {
    setTravelerId(StorageService.local.get.item("traveler"));
  }, []);

  async function handleCodeSubmission() {
    try {
      if (!codeValue || !validateCode(codeValue)) {
        throw new Error('not valid code');
      }
      await sendVerificationCode({
        verification_code: codeValue,
        traveler_id:travelerId,
        itinerary_id:trip_id
      });
      dispatch(pageVerificationEmail(2))
    } catch (error) {
      setError('Wrong Code');
      toast.error('Wrong Code! Please try again');
    } 
  }

 async function sendEmailAgain() {
    try {
        if (!validateEmail(email)) {
          throw new Error('not valid email');
        }
        const itinerary = JSON.parse(localStorage.getItem('trip')!);
        let data = await sendVerificationEmail({
          traveler_email: email,
          traveler_id:travelerId,
          itinerary_id: itinerary.uuid
        });
        setTravelerId(data.traveler_id)
        StorageService.local.save.item("traveler", data.traveler_id)
        toast.success('Code has been sent successfully!');
      } catch (error) {
        toast.error('We have a problem by sending the code. Please set your email again');
        setError('Please enter a valid e-mail');
      }
}

  function setEmailAgain() {
    dispatch(pageVerificationEmail(0))
  }

  function handleCodeChange(value: string): void {
    const validcode: boolean = validateCode(value);
    if (validcode) {
      setCodeValue(value);
      setCodeValid(+value >= 1000);
    } else {
      setCodeValue((oldCodeValue) => oldCodeValue);
    }
  }
  return <Container>
    <HelloIMG src={"https://bridgify-frontend-s3.s3.eu-central-1.amazonaws.com/Trip-Trailer-Vector.png"} alt="" />
    <Title>We sent you a code by email</Title>
    <Paragraph>
    Insert your code here
    </Paragraph>
    <TextField 
      className="material-input" 
      type="number"
      value={codeValue}
      onChange={(e:any) => handleCodeChange(e.target.value)}
      placeholder="code"
      outlined 
      style={{
        width: "80%", 
        height: 50,
        marginTop: 25,
        marginBottom: 15
      }}
    />
    <Button 
      className="mdc-button mdc-button--raised material-button"
      onClick={handleCodeSubmission}
      style={{backgroundColor: design?.PrimaryColor?.bgColor}}
    >
      Send
    </Button>
    <DontReciveCode>Didn’t recieve the code? <SpanNoRecive onClick={sendEmailAgain}>Send me again</SpanNoRecive> or <SpanNoRecive onClick={setEmailAgain}>Change email address</SpanNoRecive></DontReciveCode>
  </Container>
}

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const HelloIMG = styled.img`

`;

const Title = styled.h3`
  text-align: center;
  margin-bottom: 0;
`;

const Paragraph = styled.p`
  width: 80%;
  text-align: center;
`;
const DontReciveCode = styled.p`
  width: 80%;
`;

const SpanNoRecive = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

export default SetCodeVerification;