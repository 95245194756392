import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, Slide, useMediaQuery, useTheme } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { IItemButtonsDic } from './Item';
import Map from '../../shared/Map/MapContainer';
import Rank from '../../shared/Rank';
import styled from 'styled-components/macro';
import { HASH } from '../../../core/utils/enums';
import { inCurrentHash } from '../../../core/utils/common';
import Button from '../../shared/Button';
import CloseIcon from '@mui/icons-material/Close';
import { Icons } from "../../../assets/images/images";
import { convertDuration } from '../../../core/services/converts';
import tripInterests from "../../../assets/static/tripInterests/tripInterests";
import OpeningHours from './OpeningHours';
import StorageService from '../../../core/services/storage';
import axios from "axios";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IItem {
  _id;
  uuid: string;
  imgURL;
  location;
  name;
  about: string | null;
  orderPage;
  placeUUID: string | null;
  rank;
  tags;
  type;
  duration: string | null;
}

interface IProps {
  item: IItem;
  ButtonProps?: IItemButtonsDic;
  showSpinner?: boolean;
  openInfoDialog: boolean;
  handleClose: () => void;
  ACTIVITIES_COUNTER?: number | undefined;
}

interface OpeningHours {
  week_day: number;
  opening_hour: string;
  closing_hour: string;
}

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export default function ItemInfoDialog({
  item,
  ButtonProps,
  showSpinner,
  openInfoDialog,
  handleClose,
  ACTIVITIES_COUNTER,
}: IProps) {
  let imgURL = item?.imgURL || 'https://bridgify-frontend-s3.s3.eu-central-1.amazonaws.com/image-not-available.png';
  let location = item.location;
  let duration = item.duration;
  let name = item.name;
  let about = item.about;
  let rank = item.rank;
  let tags = item.tags;
  let _id = item._id;

  const [open, setOpen] = React.useState(inCurrentHash(HASH.Info, _id));
  const [openingHours, setOpeningHours] = useState<OpeningHours[]>([]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm')) || false;
  let buttonColor; 
  let text;
  let onClick;
  let icon;
  let disabled = false
  let textColor;

  useEffect(() => {
    const fetchAttraction = async () => {
      try {
        const response = await axios.get(`${API_ENDPOINT}/attractions/products/${item.placeUUID}`, {
          headers: {
            Authorization: `Bearer ${StorageService.local.get.item('OauthToken')}`
          }
        }); 
        if(response) {
          setOpeningHours(response.data?.attraction?.business_hours);
        }
      } catch (err: any) {
        console.error(err.response);
      }
    }
    if(item.placeUUID) {
      fetchAttraction();
    }
  }, [item.placeUUID]);

  useEffect(() => {
    const onHashChange = () => setOpen(inCurrentHash(HASH.Info, _id));
    window.addEventListener('hashchange', onHashChange);
    return () => window.removeEventListener('hashchange', onHashChange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if(ButtonProps){
     buttonColor = ButtonProps.buttonColor
     text = ButtonProps.text
     onClick = ButtonProps.onClick
     icon = ButtonProps.icon
     textColor = ButtonProps.textColor
  }

  const VALID_ABOUT: boolean = about !== null;

  return (
    <Dialog
      fullScreen={fullScreen}
      open={openInfoDialog}
      fullWidth
      onBackdropClick={handleClose}
      maxWidth={VALID_ABOUT ? 'md' : 'sm'}
      TransitionComponent={Transition}
    >
      <StyledDialogContent>
        <ContentGrid validAbout={VALID_ABOUT}>
          <Titles>
            <CloseIconAndTitle>
              <StyledCloseIcon onClick={handleClose} />
              <Title>{name}</Title>
            </CloseIconAndTitle>
            <HR />
            {tags && <TagsContainer>
              {tags.map((tag) => {
                return <Tag key={tag}>{tripInterests!.Full[tag]?.title ? tripInterests!.Full[tag]?.title : "Restaurant"}</Tag>
              }
            )}
            </TagsContainer>}
            <RankContainer>
              <Rank {...rank} />
            </RankContainer> 
            <TimesContainer>
              {duration && <Duration>
                <img 
                  src={Icons.hourglass} 
                  width="20" 
                  style={{marginRight: 5}} 
                  alt="" 
                />
                People spend here about {convertDuration(duration, false)}
              </Duration>}
              {(item.placeUUID && openingHours.length > 0) && <OpeningHours openingHours={openingHours} />}
            </TimesContainer>
            {about && (
              <AboutContainer>
                <Title>About</Title>
                <About>{about}</About>
              </AboutContainer>
            )}
          </Titles>
          <MapContainer validAbout={VALID_ABOUT}>
            <Map location={location} index={ACTIVITIES_COUNTER} />
          </MapContainer>
          <CoverImage imgURL={imgURL} validAbout={VALID_ABOUT} />
        </ContentGrid>
        <ButtonContainer>
          <Button
            text={text}
            background={buttonColor}
            onClick={onClick}
            spinnerState={showSpinner}
            loadingSpinner={true}
            Icon={icon}
            disabled={disabled}
            textColor={textColor}
          />
        </ButtonContainer>
      </StyledDialogContent>
    </Dialog>
  );
}

const TimesContainer = styled("div")`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 10px 0;
`;

const Duration = styled("p")`
  display: flex;
  align-items: center;
  margin: 10px 0;
`;

const ContentGrid = styled('div')<{ validAbout: boolean }>`
  padding: 1rem;
  grid-auto-flow: row;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: ${(p) => (p.validAbout ? '50% 50%' : '1fr')};
  grid-template-rows: ${(p) => (p.validAbout ? '300px 300px' : 'auto 300px 300px')};
  @media (max-width: 700px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto 300px 300px;
  }
`;

const CloseIconAndTitle = styled.div`
  display: flex;
  align-items: center;
`;

const HR = styled.hr`
  margin: 24px 0;
  border-color: #0e1738;
  opacity: 0.1;
  width: auto;
`;

const Titles = styled.div`
  grid-column: 1/1;
  grid-row: 1/2;
  @media (max-width: 700px) {
    grid-column: 1/1;
    grid-row: 1/1;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  && {
    min-width: 320px;
  }
`;

const StyledCloseIcon = styled(CloseIcon)`
  && {
    color: black;
    /* position:absolute; */
    /* top:1rem;
  left:1rem; */
    border: 1px solid black;
    background-color: white;
    padding: 2px;
    border-radius: 1rem;
    margin-right: 20px;
    cursor: pointer;
  }
`;

const CoverImage = styled('div')<{ imgURL: string; validAbout: boolean }>`
  background-image: url(${(p) => p.imgURL}),
    url(https://bridgify-frontend-s3.s3.eu-central-1.amazonaws.com/image-not-available.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center, center center;
  height: 100%;
  min-height: 200px;
  width: auto;
  object-fit: cover;
  border-radius: 5px;
  grid-column: ${(p) => (p.validAbout ? '2/2' : '1/1')};
  grid-row: 2/2;
  @media (max-width: 700px) {
    grid-column: 1/1;
  }
`;

const MapContainer = styled('div')<{ validAbout: boolean }>`
  position: relative;
  height: 100%;
  min-height: 200px;

  grid-column: ${(p) => (p.validAbout ? '2/2' : '1/1')};
  grid-row: ${(p) => (p.validAbout ? '1/1' : '3/3')};
  @media (max-width: 700px) {
    grid-column: 1/1;
    grid-row: 3/3;
  }
`;

const About = styled.div`
  font-size: 15px;
  line-height: 26px;
  color: #78797d;
  overflow-y: scroll;
  max-height: 300px;
`;
const AboutContainer = styled.div`
  margin-bottom: 2vh;
  height: 100%;
  max-height: 300px;
`;

const TagsContainer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
`;
const ButtonContainer = styled.div`
  margin: 1rem 0;
  text-align: center;
  display: flex;
  justify-content: center;
`;

const RankContainer = styled.div`
  margin: 0.5rem 0;
`;
const Tag = styled.div`
  border: 1px solid #0e1738;
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 12px;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  color: #0e1738;
  padding: 8px 12px;
  margin: 3px;
  width: fit-content;
`;
const Title = styled.span`
  font-family: Alverata;
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
`;
