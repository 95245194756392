import { createRef, useEffect, useRef, useState } from 'react';
import Style from './LoaderWithImage.module.scss';
import { useTripDetails } from '../../../providers/TripDetailsProvider';

const LoaderWithImage = () => {
  const {
    tripDetails: {
      ClientDesign: {
        Images: { Image1, Image2, Image3, Image4, Image5, Image6 },
        Logo,
      },
    },
  } = useTripDetails();

  const initialScreen = {
    loadingText: Image1.text || 'While we are building you the perfect trip...',
    loadingTimeInfo: 'it might take up to a minute',
    loadingImageUrl: Image1.imgURL,
  };
  const applicationStages = [
    {
      id: 1,
      text: Image2.text || 'Our smart algorithms are scanning the web for you',
      imageUrl: Image2.imgURL,
    },
    {
      id: 2,
      text: Image3.text || 'Calculating the best trip itinerary for your needs & interests',
      imageUrl: Image3.imgURL,
    },
    {
      id: 3,
      text: Image4.text || 'We won`t save your details... only the final itinerary is saved',
      imageUrl: Image4.imgURL,
    },
    {
      id: 4,
      text: Image5.text || 'Next up is your itinerary',
      imageUrl: Image5.imgURL,
    },
    {
      id: 5,
      text:
        Image6.text ||
        'It`s highly customizable! You can: Explore & Book unique activities to your trip',
      imageUrl: Image6.imgURL,
    },
  ];

  const stages = { initialScreen: initialScreen, stages: applicationStages };
  // const [stages, setStages] = useState<any>({ initialScreen: initialScreen, stages: applicationStages });
  const [elRefs, setElRefs] = useState<any>([]);
  const initialScreenRef: any = useRef(null);
  let timeOut: any = useRef(null);

  useEffect(() => {
    // setStages({ initialScreen: initialScreen, stages: applicationStages });
    setElRefs((elRefs: any) => {
      return Array(5)
        .fill(1)
        .map((value, index) => elRefs[index] || createRef());
    });
  }, []);

  useEffect(() => {
    let slideIndex = 0;

    const slides = [...elRefs];
    if (elRefs.length) {
      for (let i = 0; i < slides.length; i++) {
        slides[i].current.style.opacity = '0';
      }
    }
    setTimeout(() => {
      if (initialScreenRef.current) {
        initialScreenRef.current.classList.add(Style.fadeOut);
      }
      if (elRefs.length) {
        showSlides();
      }
    }, 4000);

    function showSlides() {
      const slides: any = [...elRefs] || [];
      // if (!slides || !slides.current || !slides.current.style) return;
      slideIndex++;
      if (slideIndex > slides.length) slideIndex = 1;
      if (slides.length > 0 && slides[slideIndex - 1] && slides[slideIndex - 1].current) {
        slides[slideIndex - 1].current.style.opacity = '1';
        if (slideIndex > 1) {
          slides[slideIndex - 2].current.style.opacity = '0';
        } else {
          slides[slides.length - 1].current.style.opacity = '0';
        }
        timeOut.current = setTimeout(showSlides, 4000);
      }
    }

    return function () {
      clearTimeout(timeOut.current);
    };
  }, [elRefs]);

  const InitialScreen: JSX.Element = (
    <div className={Style.stage} ref={initialScreenRef}>
      <p className={Style.loadingText}>{stages.initialScreen.loadingText}</p>
      <div className={Style.stageImageContainer}>
        <div
          style={{
            height: 'inherit',
            backgroundImage: `url(${stages.initialScreen.loadingImageUrl})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            borderRadius: '10px',
          }}
        ></div>
      </div>
      {Logo && (
        <div className={Style.logo}>
          <img src={Logo} alt="" className={Style.loaderImage} />
        </div>
      )}
      <p className={Style.timingInfo}>{stages.initialScreen.loadingTimeInfo}</p>
    </div>
  );

  const StagesScreens: JSX.Element = (
    <>
      {stages.stages?.map((stage: any, index: number) => (
        <div className={Style.stage} style={{ opacity: 0 }} key={stage.id} ref={elRefs[index]}>
          <p className={Style.loadingText}>{stage.text}</p>
          <div className={Style.stageImageContainer}>
            <div
              style={{
                height: 'inherit',
                backgroundImage: `url(${stage.imageUrl})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                borderRadius: '10px',
              }}
            ></div>
          </div>
          {Logo && (
            <div className={Style.logo}>
              <img src={Logo} alt="" className={Style.loaderImage} />
            </div>
          )}
        </div>
      ))}
    </>
  );

  return (
    <div className={Style.loaderContainer}>
      <div className={Style.stageContainer}>{InitialScreen}</div>
      <div className={Style.stageContainer}>{StagesScreens}</div>
    </div>
  );
};

export default LoaderWithImage