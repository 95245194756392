export const pageIndex = (payload) => {
    return {
        type: "PAGE_INDEX",
        payload
    }
}

export const setSelectedHotel = (payload) => {
    return {
        type: "HOTEL_SELECTED",
        payload
    }
}

export const tripPace = (payload) => {
    return {
        type: "TRIP_PACE",
        payload
    }
}

export const saveDateRange = (payload) => {
    return {
        type: "DATE_RANGE",
        payload
    }
}
    
export const travelers = (payload) => {
    return {
        type: "TRAVELERS",
        payload
    }
}
export const setTerms = (payload) => {
    return {
        type: "TERMS",
        payload
    }
}

export const hasTripPageIndex = (payload) => {
    return {
        type: "HAS_TRIP_PAGE_INDEX",
        payload
    }
}

export const setTravelerItineraries = (payload) => {
    return {
        type: "TRAVELER_INTINERARIES",
        payload
    }
}