import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Modal } from "@mui/material";
import ArrowSVG from "../../../../assets/images/icons/arrow.svg";
import useResponsive from "../../../hooks/useResponsive";

const days = [
    "Sun",
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat"
];

interface IProps {
    fromDate: any;
    max?: number;
    color: string;
    range: any[];
    onRange: (dates) => any;
    onChange: (dates) => any;
    openDatePicker: boolean;
    handleCloseDatePicker: () => void;
}

const DatePicker = ({fromDate, max, color, range, onRange, onChange, openDatePicker, handleCloseDatePicker}: IProps) => {
    const [date, setDate] = useState(new Date());
    const [month, setMonth] = useState(date.getMonth());
    const [year, setYear] = useState(date.getFullYear());
    const [daysInMonth, setDaysInMonth] = useState(new Date(year, month + 1, 0).getDate());
    const [dateRangeHover, setDateRangeHover] = useState<any>([null, null]);
    const [firstDay, setFirstDay] = useState("");

    const currentDateRangeRef = useRef<any>([null, null]).current;
    const maxDateRef = useRef<any>();
    const minDateRef = useRef<any>();

    const handlePrevMonth = () => {
        setDate(new Date(date.setMonth(month - 1)));
        setMonth(month - 1);
        setDaysInMonth(new Date(year, month, 0).getDate());

        if(month === 0) {
            setMonth(11);
            setYear(year - 1);
        }
    }

    const handleNextMonth = () => {
        setDate(new Date(date.setMonth(month + 1)));
        setMonth(month + 1);
        setDaysInMonth(new Date(year, month + 2, 0).getDate());

        if(month === 11) {
            setMonth(0);
            setYear(year + 1);
        }
    }

    const handleSelectRange = (date) => {
        if(!currentDateRangeRef[0]) {
            currentDateRangeRef[0] = new Date(date);
            if(max) {
                maxDateRef.current = new Date(date + (86400000 * (max - 1)));
                minDateRef.current = new Date(date - (86400000 * (max - 1)));
            }
            onChange(currentDateRangeRef);
            return;
        }

        if(!currentDateRangeRef[1]) {
            if(new Date(date) > range[0]) {
                currentDateRangeRef[1] = new Date(date);
                currentDateRangeRef[0] = range[0];
            } else {
                currentDateRangeRef[1] = range[0];
                currentDateRangeRef[0] = new Date(date);
            }
            if(max) {
                maxDateRef.current = undefined;
                minDateRef.current = undefined;
            }
            onRange(currentDateRangeRef);
            setDateRangeHover([null, null]);
            return;
        }

        if(currentDateRangeRef[0] && currentDateRangeRef[1]) {
            if(max) {
                maxDateRef.current = new Date(date + (86400000 * (max - 1)));
                minDateRef.current = new Date(date - (86400000 * (max - 1)));
            }
            currentDateRangeRef[0] = new Date(date);
            currentDateRangeRef[1] = null;
            onChange(currentDateRangeRef);
        }
    }

    const handleRangeMouseOver = (date) => {
        if(range[0] && !range[1]) {
            setDateRangeHover(prev => {
                const newRange = [...prev];
                newRange[0] = range[0];
                newRange[1] = new Date(date);
                if(newRange[1] > newRange[0]) {
                    newRange[0] = newRange[1];
                    newRange[1] = range[0];
                }
                return newRange;
            });
        }
    }

    useEffect(() => {
        setFirstDay(new Date(year, month, 1).toLocaleDateString("en-US", {weekday: "short"}));
    }, [year, month]);

    return(
        <Modal open={openDatePicker} onClose={handleCloseDatePicker}>
            <Container>
                <TopContainer>
                    <ArrowBtn src={ArrowSVG} alt="" onClick={handlePrevMonth} />
                    <DateInfo>{date.toLocaleString("en-US", {month: "long"})} {year}</DateInfo>
                    <ArrowBtn src={ArrowSVG} alt="" onClick={handleNextMonth} style={{transform: "scaleX(-1)"}} />
                </TopContainer>
                <DayNames>
                    {days.map((day) => {
                        return <DayName key={day}>{day}</DayName>
                    })}
                </DayNames>
                <DaysContainer>
                    {new Array(daysInMonth + (days.indexOf(firstDay))).fill(null).map((val, index) => {
                        const addedDays = days.indexOf(firstDay);
                        const currentDay = new Date(year, month).setDate((index + 2) - addedDays);
                        const betweenRange = (range[0] && range[1]) && !((currentDay < range[0]) || (currentDay > range[1]));
                        const betweenRangeHover = !((currentDay < dateRangeHover[0]) || (currentDay > dateRangeHover[1]));
                        const maxDate = ((maxDateRef.current && minDateRef.current) && ((currentDay > maxDateRef.current) || (currentDay < minDateRef.current)));
                        return <Day 
                            key={index + Math.random()}
                            betweenRange={betweenRange}
                            betweenRangeHover={betweenRangeHover || range[0]?.toDateString() === new Date(currentDay).toDateString()}
                            notAvailable={((fromDate && fromDate > currentDay) || maxDate)}
                            notExist={index < addedDays}
                            color={color}
                            onClick={() => handleSelectRange(currentDay)}
                            // onTouchStart={responsive ? () => handleSelectRange(currentDay) : () => {}} 
                            onMouseMove={() => handleRangeMouseOver(currentDay)}
                        >
                            {(index + 1) - addedDays}
                        </Day>
                    })}
                </DaysContainer>
            </Container>
        </Modal>
    )
}

const Container = styled.div`
    position: absolute;
    padding: 20px;
    left: 50%;
    top: 50%;
    width: fit-content;
    height: fit-content;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    outline: none;

    * {
        margin: 0;
    }
`;

const TopContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const ArrowBtn = styled.img`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    border-radius: 10px;
    background-color: transparent;
    border: 1px solid #c5c5c5;
    font-size: 20px;
    padding: 5px;
    cursor: pointer;
`;

const DateInfo = styled.h3`
    
`;

const DayNames = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 10px 0;
  color: #808080;
`;

const DayName = styled.p`
    font-size: 14px;
`;

const DaysContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    align-items: center;
    gap: 10px;
`;

const Day = styled.button<{
    betweenRange: boolean;
    betweenRangeHover: boolean;
    notAvailable: boolean;
    notExist: boolean;
}>`
    width: 30px;
    height: 35px;
    border-radius: 8px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-self: center;
    visibility: ${(p) => p.notExist ? "hidden": "visible"};
    opacity: ${(p) => p.notAvailable ? .5 : 1};
    pointer-events: ${(p) => p.notAvailable ? "none" : "all"};
    outline: none;
    border: none;
    box-shadow: ${(p) => p.betweenRangeHover || p.betweenRange ? `0px 0px 0px 2px ${p.color}` : "0px 0px 0px 2px #fff"};
    border-radius: 8px;
    color: ${(p) => p.betweenRange ? "white" : "black"};
    background-color: ${(p) => p.betweenRange ? p.color : "white"};
    cursor: pointer;

    & > * {
        display: block;
        pointer-events: none;
    }

    :hover {
        outline: 1px solid ${(p => p.color)};
    }

    &:focus {
        outline: 0 !important;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none; 
    }
`;

export default DatePicker;