import { AppBar, Button, Dialog, DialogContent, IconButton, Slide, Toolbar, useMediaQuery, useTheme } from "@mui/material";
import Skeleton from '@mui/material/Skeleton';
import { TransitionProps } from "@mui/material/transitions";
import { forwardRef, useEffect, useState } from "react";
import { convertMusementActivity } from "../../../../core/services/converts";
import { getActivity } from "../../../../core/services/musement";
import { useFeed } from "../../Feed/useFeed";
import styled from "styled-components/macro";
import CloseIcon from "@mui/icons-material/Close";
import BackIcon from "@mui/icons-material/ArrowBackIos";
import BookingInfoPage from "./BookingInfoPage";
import BookingTicketsPage from "../BookingTickets/BookingTicketsPage";

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

interface IStep {
  Component: any,
  title: string,
  appbar?: {
    bookNowButton?: boolean,
    backButton?: boolean
  }
}
enum BookingSteps { Info, Tickets }

const StepsDic: { [key in BookingSteps]: IStep } = {
  [BookingSteps.Info]: {
    Component: BookingInfoPage,
    title: 'Activity Info',
    appbar: { bookNowButton: true }
  },
  [BookingSteps.Tickets]: {
    Component: BookingTicketsPage,
    title: 'Book Tickets',
    appbar: { backButton: true }
  }
}
export default function BookingFlowDialog() {
  const [step, setStep] = useState<BookingSteps>(BookingSteps.Info)
  const [loading, setLoading] = useState<boolean>(true)

  const { state: { selectedItemBook, showBookingFlowDialog: open }, dispatch } = useFeed()
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    async function fetchMusementActivity() {
      //if it has an external_id so it's a musement activity 
      try {
        if (selectedItemBook?.uuid) {
          const activityData: IActivity = await getActivity(selectedItemBook.uuid)
          const convertedActivity: IActivity_Converted = convertMusementActivity(activityData)
          dispatch({ type: 'SELECTED_ITEM_TICKETS', payload: convertedActivity })
        }
      } catch (error) {
        // console.log('unable to load musement', error)
        // alert(error)
      } finally {
        setLoading(false)
        // setShowSpinner(false)
      }
    }
    fetchMusementActivity()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleClose() {
    window.history.back();
    dispatch({ type: 'SELECTED_ITEM_TICKETS', payload: undefined })
    dispatch({ type: 'TOGGLE_BOOKING_FLOW_DIALOG' })
    // dispatch({ type: 'SELECTED_ITEM_BOOK', payload: undefined })
  }
  function handleBack() {
    setStep(BookingSteps.Info)
  }

  // //fetch and convert the activity data from musement 

  function nextStep(): void {
    setStep(BookingSteps.Tickets)
  }

  const { Component, title, appbar } = StepsDic[step]
  return (
    <StyledDialog fullScreen={fullScreen}
      maxWidth="xs"
      open={open}
      TransitionComponent={Transition}>
      <Container>
        <StyledAppBar>
          <Toolbar>
            <IconButton edge="start" >
              {appbar?.backButton ?
                <StyledBackIcon onClick={handleBack} /> :
                <StyledCloseIcon onClick={handleClose} />}
            </IconButton>

            <AppBarContent >
              {title}
              {appbar?.bookNowButton &&
                <StyledBarButton variant="contained" onClick={nextStep} >
                  Book Now!
                </StyledBarButton>}
            </AppBarContent>
          </Toolbar>
        </StyledAppBar>

        <StyledDialogContent>
          {loading ? <SkeletonContainer>
            <StyledSkeleton variant="rectangular" width={'100%'} height={200} animation="wave" />
            <StyledSkeleton variant="rectangular" width={'100%'} height={40} animation="wave" />
            <StyledSkeleton variant="rectangular" width={'100%'} height={30} animation="wave" />
            <StyledSkeleton variant="rectangular" width={'100%'} height={30} animation="wave" />
            <StyledSkeleton variant="rectangular" width={'100%'} height={30} animation="wave" />
            <StyledSkeleton variant="rectangular" width={'100%'} height={30} animation="wave" />
            <StyledSkeleton variant="rectangular" width={'100%'} height={30} animation="wave" />
            <StyledSkeleton variant="rectangular" width={'100%'} height={30} animation="wave" />
          </SkeletonContainer> : <Component nextStep={nextStep} />}
        </StyledDialogContent>

      </Container>
    </StyledDialog>
  )
}

const StyledSkeleton = styled(Skeleton)`
&&{
  margin-bottom:1rem;
}
`;


const StyledDialog = styled(Dialog)`
&&{
  overflow:overlay;
}
`;


const Container = styled.div`
min-width: 320px;
min-height: 500px;
box-sizing: border-box;
`

const StyledDialogContent = styled(DialogContent)`
&&{
  padding-bottom: 5vh;
}
`
// const StyledSkeleton = styled(Skeleton)`
// &&{
//   width:60%;
//   height:100px;
//   margin-bottom: 1rem;
// }
// `;
const StyledBackIcon = styled(BackIcon)`
&&{
  color:white;
}
`;
const StyledCloseIcon = styled(CloseIcon)`
&&{
  color:white;
}
`;
const SkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledBarButton = styled(Button)`
&&{
  color: #3f51b5;
  background-color: white;
  font-weight: 600;
}
`

const StyledAppBar = styled(AppBar)`
&&{
  background-color: ${p => p.theme.primary.bgColor};
  color: ${p => p.theme.primary.textColor};
  font-size: 1.2rem;
  text-align: center;
  position:sticky;
}
`

const AppBarContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  font-weight: 600;
`
// const ErrorMessage = styled.div`
//   color:#FE4042;
// `
// const Title = styled.div`
//   font-weight: 700;
//   font-size: 1.3rem;
//   margin-bottom:0.3rem;
//   text-transform: capitalize;
// `