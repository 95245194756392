import { useSelector } from "react-redux";
import styled from "styled-components";
import '@material/react-button/dist/button.css';
import useResponsive from "../../../hooks/useResponsive";

const GetStarted = () => {
  const {design} = useSelector((state: any) => state.clientDesign);
  
  const responsive = useResponsive(580);

  return (
    <Container>
      <Img src='https://bridgify-frontend-s3.s3.eu-central-1.amazonaws.com/Trip-to-the-Beach-Vector.png' />
      <Title style={{color: design?.PrimaryColor?.bgColor}}>Welcome!</Title>
      <Subtitle>We can help you plan your dream trip</Subtitle>
      <Paragraph>
        We want to save you a lot of time. {!responsive && <br />}
        Within a couple of clicks, you'll receive a complete {!responsive && <br />}
        personalized trip itinerary.
      </Paragraph>
    </Container>
  )
}

export default GetStarted;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 90%;
  height: 100%;
`;

const Img = styled.img`
  @media screen and (max-width: 580px) {
    margin-top: 0;
    width: 80vw;
  }
`;

const Title = styled.h1`
  text-align: center;
  font-size: 38px;
  margin-bottom: 0;

  @media screen and (max-width: 580px) {
    font-size: 32px;
  }
`;

const Subtitle = styled.h3`
  text-align: center;
  margin-top: 0;
  font-size: 24px;

  @media screen and (max-width: 580px) {
    font-size: 22px;
    margin: 10px;
  }
`;

const Paragraph = styled.p`
  text-align: center;
  width: 50%;
  margin: 0px;
  line-height: 26px;
  @media (max-width: 900px){
    width: 100%;
  }
`;