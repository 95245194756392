const initialState = {
    isOpen: false,
    verificationPageindex: 0,
    email: '',
    itineraryID: '',
    travelerID: '',
  }
  
  function reducer(state = initialState, action) {
    const { payload, type } = action;
    switch (type) {
      case 'OPEN_MODAL': 
        return { ...state, isOpen: payload}
      case 'PAGE_VERIFICATION_INDEX': 
        return { ...state, verificationPageindex: payload}
      case 'EMAIL': 
        return { ...state, email: payload}
      case 'ITINERARY_ID': 
        return { ...state, itineraryID: payload}
      case 'TRAVELER_ID': 
        return { ...state, travelerID: payload}
      default:
        return { ...state };
    }
  }
  
  export default reducer;