import styled from "styled-components";
import { useSelector } from "react-redux";

import checkSVG from "../../../../assets/images/icons/check.svg";

interface IProps {
    color: string
}

const Stepper = ({color}: IProps) => {
    const {pageIndex} = useSelector((state: any) => state.onboarding);

    return(
        <Container>
            <Step currentStyle={false} color={color}>
                {pageIndex === 0 ? <Circle /> : (<img style={{
                    width: "80%",
                }} src={checkSVG} alt="" />)}
            </Step>
            <Line color={color} />
            <Step currentStyle={pageIndex < 1} color={color}>
                {pageIndex === 1 ? <Circle /> : (<img style={{
                    width: "80%",
                }} src={checkSVG} alt="" />)}
            </Step>
            <Line color={color} />
            <Step currentStyle={pageIndex < 2} color={color}>
                {pageIndex === 2 ? <Circle /> : (<img style={{
                    width: "80%",
                }} src={checkSVG} alt="" />)}
            </Step>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    margin-bottom: 20px;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
    gap: 2px;
    z-index: 1000;
`;

const Step = styled.div<{currentStyle: boolean, color: string}>`
    background-color: ${(p) => p.currentStyle ? "transparent" : p.color};
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${(p) => p.color};
`;

const Line = styled.div`
    background: ${(p) => p.color};
    opacity: .5;
    width: 20px;
    height: 3px;
`;

const Circle = styled.div`
    width: 5px;
    height: 5px;
    background-color: white;
    border-radius: 50%;
`;

export default Stepper;