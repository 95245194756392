import { Button } from "@mui/material";
import styled from "styled-components/macro";
import PartTwo from "./Parts/PartTwo";
import PartOne from "./Parts/PartOne";
import PartThree from "./Parts/PartThree";
import { useFeed } from "../../Feed/useFeed";



export default function BookingInfoPage({ nextStep }) {
  const { state: { selectedItemTickets } } = useFeed()

  // const [error, setError] = useState<string | null>(null)
  // const [loading, setLoading] = useState(true)
  // const [open, setOpen] = useState(inCurrentHash(HASH.BookingInfo, turnSpacesToUnderline(name)));

  // useEffect(() => {
  //   const onHashChange = () => setOpen(inCurrentHash(HASH.BookingInfo, turnSpacesToUnderline(name)));
  //   window.addEventListener("hashchange", onHashChange);
  //   return () => window.removeEventListener("hashchange", onHashChange);
  // }, []);

  // function handleClose() {
  //   window.history.back();
  //   dispatch({ type: 'SELECTED_ITEM_TICKETS', payload: undefined })
  //   dispatch({ type: 'SELECTED_ITEM_BOOK', payload: undefined })
  // }

  // //fetch and convert the activity data from musement 

  // function handleBookNow(): void {
  //   dispatch({ type: 'SELECTED_ITEM_TICKETS', payload: selectedItemTickets })
  // }

  // function handleCloseBookingDialog(): void {
  //   setShowBookingDialog(false)
  // }

  // function handleOpenBookingDialog(): void {
  //   setShowBookingDialog(true)
  // }

  // const Container = ({ open, children }) => {
  //   return !isDesktop ?
  //     <Dialog fullScreen={fullScreen} open={open} TransitionComponent={Transition}>
  //       {children}
  //     </Dialog> : open && <AddOnContainer>
  //       {children}
  //     </AddOnContainer>
  // }

  // return selectedItemTickets ?
  //   step === BookingSteps.Info ?
  //     <Dialog fullScreen={fullScreen} open={true} TransitionComponent={Transition}>
  return selectedItemTickets ? (
    <Container>
      {/* <AppBar style={{ position: "relative" }}> */}
      <CoverImage image={selectedItemTickets?.cover_image_url} />

      <Title>{selectedItemTickets.title}</Title>

      <PartOne />
      <PartTwo />
      <PartThree />

      <StyledButton
        variant="contained"

        color="primary"
        onClick={nextStep}>
        {/* onClick={handleBookNow}> */}
        Book Now!
      </StyledButton>

      {/* </Dialog> */}
    </Container>
  ) : null
  // <ErrorMessage>OOPS! We had trouble fetching pickup points, please try again later.</ErrorMessage>;
}
const Container = styled.div`
`
const CoverImage = styled("img") <{ image: string }>`
  background-image: url(${props => props.image}),
    url(https://bridgify-frontend-s3.s3.eu-central-1.amazonaws.com/image-not-available.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center, center center;
  height: 200px;
  object-fit: cover;
  width:100%;
`;
const Title = styled.div` 
font-weight: 700;
  font-size: 1.3rem;
  margin-bottom:0.3rem;
  text-transform: capitalize;
`;
const StyledButton = styled(Button)`
&&{
  width: 100%;
  height: 3rem;
  background-color: ${p => p.theme.primary.bgColor};
  color: ${p => p.theme.primary.textColor};
}
`;

// const StyledSkeleton = styled(Skeleton)`
// &&{
//   width:60%;
//   height:100px;
//   margin-bottom: 1rem;
// }
// `;
// const StyledCloseIcon = styled(CloseIcon)`
// &&{
//   color:white;
// }
// `;
// const SkeletonContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// `

// const AddOnContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: space-between;
//   width: 100%;
//   font-weight: 600;
// `
// const DialogTitle = styled.div`
//   background-color: ${p => p.theme.primary.bgColor};
//   color: ${p => p.theme.primary.textColor};
//   width: 100%;
//   font-size: 1.2rem;
//   text-align: center;
//   box-sizing: border-box;
// `
/* const StyledBarButton = styled(Button)`
&&{
  color: #3f51b5;
  background-color: white;
  font-weight: 600;
}
`;
const StyledDialogContent = styled(DialogContent)`
&&{
  padding-bottom: 5vh
}
`;
const AppBarContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  font-weight: 600;
`;
const ErrorMessage = styled.div`
  color:#FE4042;
`  */

