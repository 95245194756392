import { MenuItem, FormControl, Select } from "@mui/material"
import { SetStateAction } from "react"
import { Dispatch, useState } from "react"
import styled from "styled-components"
import { useBookingTicketsContext } from "./useBookingTickets"

interface IProps {
  languages: ILanguage[];
  selectedLangauge?: ILanguage;
  setSelectedLangauge: Dispatch<SetStateAction<ILanguage | undefined>>
  // handleSelectLanguage: (language: ILanguage) => void;
}

// export default function LanguagesSelection({ languages, handleSelectLanguage }: IProps) {

export default function LanguagesSelection({ languages, setSelectedLangauge, selectedLangauge }: IProps) {
  const { state: { selectedLanguageIndex } } = useBookingTicketsContext()

  const SINGLE_LANGUAGE = languages.length === 1;
  return languages ? (
    <LanguagesContainer >
      {!SINGLE_LANGUAGE ? <>
        <LanguagesLabel>Language:</LanguagesLabel>
        <StyledFormControl variant="outlined" >
          <StyledSelect
            value={selectedLangauge?.name || languages[0].name}
            placeholder="Select Language">
            {languages?.map((language: ILanguage, index) => {
              const { name } = language
              return (
                <MenuItem
                  value={name}
                  key={`lng ${name}` + index}
                  onClick={() => setSelectedLangauge(language)}>
                  {name}
                </MenuItem>
              )
            })}
          </StyledSelect>
        </StyledFormControl>
      </> : <LanguagesLabel>Language: {languages[0].name}</LanguagesLabel>
      }
    </LanguagesContainer >
  ) : null
}

const StyledSelect = styled(Select)`
&&{
  &.MuiInputBase-input{
    padding: 0.7rem;
  }
}
`;

const LanguagesLabel = styled.div`
  margin-right: 1rem;
`;

const LanguagesContainer = styled.div`
  /* display: flex;
  flex-direction: column,;
  align-items: center;
  justify-content: space-between; */
`;

const StyledFormControl = styled(FormControl)`
&&{
  width: 100%;
  margin-top: 0.5rem;
}
`