import {
  Dialog, DialogActions, Button, DialogContent
} from "@mui/material";
import styled from "styled-components/macro";
import CloseIcon from "@mui/icons-material/Close";

interface IProps {
  handleConfirm?: (event) => Promise<void>;
  handleBack: (event) => void;
  title?: string;
  open: boolean;
  primaryButtom?: boolean;
  children: any;
  subtitle?: string;
}

export default function Modal({
  handleBack, handleConfirm, open, primaryButtom, title, children, subtitle
}: IProps) {

  function onClick(event): void {
    event.stopPropagation();
  }
  return (
    <Dialog open={open} onClose={handleBack} onClick={onClick}>
      <TitlesContainer >
        {title && <Title >{title}</Title>}
        {subtitle && <Subtitle >{subtitle}</Subtitle>}
      </TitlesContainer>
      {!primaryButtom && <StyledCloseIcon onClick={handleBack} />}
      <StyledContent  >
        {children}
      </StyledContent>
      <DialogActions>
        {primaryButtom && <>
          <ContainedButton onClick={handleConfirm} color="primary" variant="contained">
            Confirm
          </ContainedButton>
          <OutlinedButton onClick={handleBack} color="primary" variant="outlined">
            Back
          </OutlinedButton>
        </>}
      </DialogActions>
    </Dialog>
  )
}
const TitlesContainer = styled.div`
  padding: 10px 24px;
`;

const Subtitle = styled.div`
  font-size: 0.9rem;
  color:gray;
`;
const Title = styled.div`
  font-weight: 600;
`;

const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  top: 1rem;
  right: 1rem;
`;

const ContainedButton = styled(Button)`
&&{
  background-color: #0e1738;
}
`;

const OutlinedButton = styled(Button)`
&&{
  color: #0e1738;
  border: 1px solid #0e1738;
}
`;

const StyledContent = styled(DialogContent)`
&&{
  min-width: 250px;
  padding-top: 0;
  padding-bottom: 0;
}
  /* padding-top: 0;
  & .MuiDialogContent-root{
    padding-top: 0;
  } */
`;

