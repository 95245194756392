const initialState = {
    design: []
  }
  
  function reducer(state = initialState, action) {
    const { payload, type } = action;
    switch (type) {
      case 'SAVE_DESIGN': 
        return { ...state, design: payload}
      default:
        return { ...state };
    }
  }
  
  export default reducer;