import styled from 'styled-components';
import { useTripDetails } from '../../providers/TripDetailsProvider';
import TimelineBox from '../pages/Feed/TimelineBox';

export default function AccomandationItem() {

  const { tripDetails: { tripAccommodation:
    { accommodation_name, accommodation_address }
  } } = useTripDetails()

  return (
    <TimelineBox boxType='HOTEL' cardBorderColor='#9E9E9E'>
      <Container >
        <Name> {accommodation_name} </Name>
        {/* <Name> {accommodation_address} </Name> */}
        {accommodation_address && <Address>{accommodation_address}</Address>}
      </Container>
    </TimelineBox>
  );
}
const Container = styled.div`
  /* background-color: white;
  border-radius: 5px;
  width: 90%;
  padding: 0.5rem 1rem 0.5rem 2vw;
  box-sizing: border-box;
  color: #0e1738;
  text-transform: capitalize;
  font-weight: 300;
  font-size: 0.9rem;
  line-height: 1rem;
  letter-spacing: 1px;
  margin-bottom: 1rem; */
  margin-left: 4rem;
  border-radius: 10px;

`;

// const Line = styled.div`
//   border: 1px solid rgba(0, 0, 0, 0.09);
//   position: absolute;
//   left: -29px;
//   top: 38px;
// `;

const Address = styled.div`
  font-size: 0.8rem;
  text-transform: capitalize;
  color: #838383;
  font-weight: 600;
`;

const Name = styled.div`
  font-weight: 600;
  line-height: 1rem;
  text-transform: capitalize;
  color: #5B5B5B;
  margin-bottom:0.5rem;
`;