import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { TextField } from "@rmwc/textfield";
import { Button } from "@rmwc/button";
import { useHistory } from "react-router-dom";
import {useDispatch} from 'react-redux'
import { pageVerificationEmail, setEmailVerification, setTraveler, setItinerary } from '../../../../Actions/emailVerification'
import { validateEmail } from '../../../../core/utils/validations';
import styled from "styled-components";
import { useTripDetails } from '../../../../providers/TripDetailsProvider';
import '@rmwc/textfield/styles';
import { sendVerificationEmail } from '../../../../core/services/emailVerification';
import StorageService from '../../../../core/services/storage';
import toast from 'react-hot-toast';
import PartySVG from "../../../../assets/images/icons/partySVG.svg";

const SetEmailVerification = ({closeBottomModal}) => {
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [travelerId, setTravelerId] = useState<any>();

  const history = useHistory();
  const dispatch = useDispatch();

  const {design} = useSelector((state: any) => state.clientDesign);

  const {setTripDetailsWrapper, convertToOldFeedData} = useTripDetails();

  useEffect(() => {
    setTravelerId(StorageService.local.get.item("traveler"));
  }, []);

  function handleEmailChange(value: string): void {
    setEmail(value);
  }

  async function handleSendEmail() {
    try {
      if (!validateEmail(email)) {
        throw new Error('not valid email');
      }
      const itinerary = JSON.parse(localStorage.getItem('trip')!);
      dispatch(setEmailVerification(email))
      dispatch(setTraveler(travelerId))
      dispatch(setItinerary(itinerary.uuid))
      let data = await sendVerificationEmail({
        traveler_email: email,
        traveler_id:travelerId,
        itinerary_id: itinerary.uuid
      });
      setTravelerId(data.traveler_id)
      StorageService.local.save.item("traveler", data.traveler_id)
      console.log(data.traveler_id)
      dispatch(pageVerificationEmail(1))
    } catch (error) {
      toast.error('Please enter a valid e-mail');
      setError('Please enter a valid e-mail');
    }
  }

  return <Container>
    <VectorIMG src={"https://bridgify-frontend-s3.s3.eu-central-1.amazonaws.com/Trip-Trailer-Vector.png"} alt="" />
    <Title>Yay! You’re natural at this </Title>
    <Paragraph>
    Now that you’ve successfully created your trip, enter your email address so we can send it to you.
    </Paragraph>
    <TextField 
      className="material-input" 
      type="email"
      placeholder="Email"
      onChange={(e: any) => handleEmailChange(e.target.value)}
      outlined 
      style={{
        width: "80%", 
        height: 50,
        marginTop: 25,
        marginBottom: 15
      }}
      value={email} 
    />
    <Button 
      className="mdc-button mdc-button--raised material-button" 
      style={{backgroundColor: design?.PrimaryColor?.bgColor}}
      onClick={handleSendEmail}
    >
      Send
    </Button>
    <MayBeLater onClick={closeBottomModal}>Maybe later</MayBeLater>
  </Container>
}

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const VectorIMG = styled.img`

`;

const Title = styled.h3`
  text-align: center;
  margin-bottom: 0;
`;

const Paragraph = styled.p`
  width: 80%;
  text-align: center;
`;
const MayBeLater = styled.p`
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
`;

export default SetEmailVerification;