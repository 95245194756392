import { Button, ButtonGroup } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import React, { useState } from 'react';
// import ReactTooltip from 'react-tooltip';
import styled from 'styled-components/macro';
import { useBookingTicketsContext } from './useBookingTickets';

export default function Products() {
  const {
    state: { products },
    totalPaymentValue,
    dispatch,
  } = useBookingTicketsContext();
  const {
    currency,
    totalSum: { retail_price, retail_price_without_service_fee, service_fee },
  } = totalPaymentValue();

  // returns true if the user has purchased enough tickets and followed the requisites
  const ABLE_TO_CONTINUE = (): boolean => {
    return products.every((product) => {
      const { min_buy, amount = 0 } = product;
      const NOT_REACHED_THE_MIN: boolean = amount > 0 && amount < min_buy;
      return !NOT_REACHED_THE_MIN;
    });
  };

  function handleProductAmount(
    amount: number,
    operation: 'INCREMENT' | 'DECREMENT',
    index: number,
  ) {
    const updatedAmount = operation === 'INCREMENT' ? amount + 1 : amount - 1;
    const clonedProduct = [...products][index];
    clonedProduct.amount = updatedAmount;
    const clonedArray = [...products];
    clonedArray[index] = clonedProduct;
    dispatch({ type: 'PRODUCTS', payload: clonedArray });
    // setProducts(clonedArray);
    setAbleToContinue(ABLE_TO_CONTINUE());
  }

  const [ableToContinue, setAbleToContinue] = useState<boolean>(ABLE_TO_CONTINUE());

  return (
    <>
      {products?.map((product, index) => {
        const {
          name,
          min_buy,
          max_buy,
          amount = 0,
          retail_price: { formatted_value },
        } = product;
        // const NOT_REACHE D_THE_MIN: boolean = amount > 0
        const NOT_REACHED_THE_MIN: boolean = amount > 0 && amount < min_buy;
        return (
          <ProductContainer key={name}>
            <DescriptionContainer>
              <ProductName>{name}</ProductName>
              <Price>{formatted_value}</Price>
            </DescriptionContainer>
            <AmountsAndLabelContainer>
              <StyledButtonGroup variant="contained">
                <Button
                  color="primary"
                  variant="contained"
                  disabled={amount <= 0}
                  onClick={() => handleProductAmount(amount, 'DECREMENT', index)}
                >
                  {' '}
                  -{' '}
                </Button>
                <Button color="primary" variant="contained">
                  {amount}
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={amount >= max_buy}
                  onClick={() => handleProductAmount(amount, 'INCREMENT', index)}
                >
                  {' '}
                  +{' '}
                </Button>
              </StyledButtonGroup>
              {NOT_REACHED_THE_MIN && <MinBuyLabel>Minimun of {min_buy} tickets</MinBuyLabel>}
            </AmountsAndLabelContainer>
          </ProductContainer>
        );
      })}
      {service_fee > 0 && (
        <FeesContainer>
          <PriceWithoutService>
            {currency}
            {retail_price_without_service_fee}
          </PriceWithoutService>
          <ServiceFee>
            <BookingFee>
              {/* <a data-tip="React-tooltip">
                <StyledInfoOutlinedIcon />
              </a> */}
              {/* <ReactTooltip place="top" type="light" effect="solid" border>
                We know. Nobody likes extra fees. But not only do they cover the cost of managing
                your booking, they let us give you 24/7 customer service and a free app to access
                your tickets and discover more city sights.
              </ReactTooltip> */}
              Booking fee
            </BookingFee>
            <div>
              {currency}
              {service_fee}
            </div>
          </ServiceFee>
        </FeesContainer>
      )}
      <TotalPayment>
        <div> Total Price </div>
        <div>
          {' '}
          {currency}
          {retail_price}{' '}
        </div>
      </TotalPayment>

      <PaymentButton
        disabled={retail_price === 0 || !ableToContinue}
        variant="contained"
        color="primary"
        onClick={() => dispatch({ type: 'SHOW_PAYMENT_MODAL', payload: true })}
      >
        PROCEED TO PAYMENT
      </PaymentButton>
    </>
  );
}

const AmountsAndLabelContainer = styled.div`
  text-align: right;
`;

const MinBuyLabel = styled.div`
  font-size: 0.7rem;
  color: #f59a00;
  font-weight: 400;
`;

// const StyledReactTooltip = styled(ReactTooltip)`
//   && {
//     width: 50%;
//     text-align: justify;
//   }
// `;

const StyledInfoOutlinedIcon = styled(InfoOutlinedIcon)`
  && {
    height: 20px;
    width: 20px;
  }
`;

const FeesContainer = styled.div`
  text-align: right;
  margin: 2rem 1rem 0.5rem 0;
`;
const BookingFee = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
`;

const PriceWithoutService = styled.div`
  font-weight: 600;
`;

const ServiceFee = styled.div`
  font-size: 0.9rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ProductContainer = styled.div`
  padding: 5px 10px;
  border: 1px solid black;
  border-radius: 10px;
  margin-bottom: 1vh;
  width: inherit;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid black;
`;

const TotalPayment = styled.div`
  padding: 5px 10px;
  border: 5px solid green;
  border-radius: 10px;
  margin-bottom: 3rem;
  font-weight: 600;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const DescriptionContainer = styled.div`
  text-align: left;
`;

const ProductName = styled.div`
  font-size: 1rem;
`;

const Price = styled.div`
  font-size: 1rem;
  font-weight: 700;
`;

const PaymentButton = styled(Button)`
  && {
    width: 100%;
    height: 5vh;
    background-color: green;
    border-radius: 30px;
  }
`;

const StyledButtonGroup = styled(ButtonGroup)`
  && {
    height: min-content;
    width: min-content;
    margin-left: 20px;
  }
`;
