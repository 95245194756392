import { useState, useEffect, useCallback, useReducer } from "react"
import { Button, FormControl, LinearProgress, MenuItem, Select } from "@mui/material"
import { getPickupsByActivityID } from "../../../../core/services/musement"
import styled from "styled-components"
import { useBookingTicketsContext } from "./useBookingTickets"
import MapContainer from "../../../shared/Map/MapContainer"
import { useFeed } from "../../Feed/useFeed"

type TActions = 'LOADING' | 'ERROR' | 'DATA'
interface IAction {
  type: TActions;
  payload: any;
}
const initialState = {
  loading: true,
  error: false
}
type IState = typeof initialState & {
  data?: IPickupPoint[];
}
export default function PickUpPoints() {
  const { state: { selectedItemTickets, selectedItemBook } } = useFeed()
  const { handleNext: NextStep, dispatch: providerDispatch, } = useBookingTicketsContext()
  // const DATA_IS_LOADED = pickupPointsList.length !== 0

  const [selectedPickup, setSelectedPickup] = useState<IPickupPoint>()
  function reducer(state: IState, action: IAction): IState {
    const { payload, type } = action
    switch (type) {
      case 'DATA':
        return { ...state, data: payload };
      case 'ERROR':
        return { ...state, error: payload };
      case 'LOADING':
        return { ...state, loading: payload };
      default:
        return { ...state };
    }
  }
  const [{ error, loading, data, }, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    async function initPickups() {
      try {
        // if (data) return;
        if (!selectedItemTickets) throw new Error("No Item was selected");
        const pickups: IPickupPoint[] = await getPickupsByActivityID(selectedItemTickets.uuid)
        dispatch({ type: "DATA", payload: pickups });
      } catch (e) {
        dispatch({ type: 'ERROR', payload: true })
      } finally {
        dispatch({ type: 'LOADING', payload: false })
      }
    };
    initPickups();
  }, [])
  // useEffect(() => {
  //   let mounted = true;
  //   if (!data && selectedItemTickets) {
  //     // const pickups: IPickupPoint[] = await getPickupsByActivityID()
  //     getPickupsByActivityID(selectedItemTickets.uuid)
  //       .then(pickups => {
  //         if (mounted) {
  //           dispatch({ type: "DATA", payload: pickups });
  //         }
  //       })
  //       .catch(error => dispatch({ type: 'ERROR', payload: true }))
  //       .finally(() => dispatch({ type: 'LOADING', payload: false }))
  //   }
  //   return () => { mounted = false };
  // }, []);


  // function handleSelectPickup(index: number) {
  //   // if (!data) return;
  // }

  // const onSelectChange = useCallback((e) => {
  //   // if (!data) return;
  //   const index = +(e?.target?.value || 0)
  //   console.log(index);
  //   console.log(data);

  //   // const pickup: IPickupPoint = data[index]
  //   // providerDispatch({ type: "SELECTED_PICKUP", payload: pickup })
  // }, [providerDispatch]);

  const Map = useCallback(() => {
    return (selectedPickup && selectedPickup.longitude && selectedPickup.latitude ?
      <MapContainerDiv>
        <MapContainer location={{ lng: selectedPickup.longitude, lat: selectedPickup.latitude }} />
      </MapContainerDiv>
      : null)
  }, [])


  // const Selection = useCallback(() => {
  //   return (
  //     <StyledFormControl variant="outlined">
  //       <Select value={selectedPickup?.uuid}        >
  //         {data?.map((pickup: IPickupPoint) => {
  //           const { name, uuid } = pickup
  //           return (
  //             <StyledMenuItem
  //               value={uuid}
  //               key={uuid}
  //               onClick={() => dispatch({ type: 'SELECTED_PICKUP', payload: pickup })}>
  //               {name}
  //             </StyledMenuItem>)
  //         }
  //         )}
  //       </Select>
  //     </StyledFormControl>
  //   )
  // }, [])

  function handleNext() {
    providerDispatch({ type: "SELECTED_PICKUP", payload: selectedPickup })
    NextStep()
  }

  return !error ? (
    <>
      {!loading ? <ContentContainer>

        <StyledFormControl variant="outlined">
          <Select value={selectedPickup?.uuid}        >
            {data?.map((pickup: IPickupPoint) => {
              const { name, uuid } = pickup
              return (
                <StyledMenuItem
                  value={uuid}
                  key={uuid}
                  onClick={() => setSelectedPickup(pickup)}>
                  {/* onClick={() => dispatch({ type: 'SELECTED_PICKUP', payload: pickup })}> */}
                  {name}
                </StyledMenuItem>)
            }
            )}
          </Select>
        </StyledFormControl>

        <Map />

        <StyledButton
          onClick={handleNext}
          variant="contained"
          color="primary"
          disabled={selectedPickup === undefined}>
          Continue
        </StyledButton>
      </ContentContainer>
        : <LinearProgress />
      }
    </>
  ) : <ErrorMessage>
    OOPS! We had trouble fetching pickup points, please try again later.
  </ErrorMessage>
}

const ErrorMessage = styled.div`
  color:red;
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const MapContainerDiv = styled.div`
  position: relative;
  padding-bottom: 260px;
  margin-top:3vh;
`

const StyledButton = styled(Button)`
  &&{
    margin-top:3vh;
  }
`
const StyledFormControl = styled(FormControl)`
  &&{
    width: 100%;
    font-size:0.8rem;
  }
`
const StyledMenuItem = styled(MenuItem)`
&&{
  white-space: break-spaces;
  border-bottom: 1px solid #78787836;
}
`

