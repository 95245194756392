import { Route, Switch } from "react-router-dom";
import React, {useEffect} from 'react';
import Feed from "./components/pages/Feed";
import OnBoarding from "./components/pages/Onboarding/Onboarding";
import WelcomePage from "./components/pages/WelcomePage";
import Terms from "./components/pages/Terms/Terms";
import { Routes } from './core/utils/endpoints';
import Page404 from "./components/pages/Page404";
import {useDispatch} from 'react-redux';
import {saveDesign} from './Actions/clientDesignActions';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { TripDetailsProvider } from './providers/TripDetailsProvider';
import StorageService from './core/services/storage';
import { LayoutProvider } from './providers/LayoutProvider';
import initReactFastclick from 'react-fastclick';

 //@ts-ignore
const stripePromise = loadStripe(process.env.REACT_APP_MUSEMENT_STRIPE_TOKEN); 

export default function App(): JSX.Element {
  const dispatch = useDispatch()
  const design = StorageService.local.get.clientDesign();

  useEffect(() => {
    dispatch(saveDesign(design))
  }, [design]);

  useEffect(() => {
    initReactFastclick();
  }, []);

  return (
    <Elements stripe={stripePromise}>
      <LayoutProvider>
        <TripDetailsProvider>
          <Switch>
            <Route path={Routes.feed} exact component={Feed} />
            <Route path={Routes.onBoarding} exact component={OnBoarding} />
            <Route path={Routes.default} exact component={WelcomePage} />
            <Route path={Routes.terms} exact component={Terms} />
            <Route component={Page404} />
          </Switch>
        </TripDetailsProvider>
      </LayoutProvider>
    </Elements>
  );
}