import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import GTranslateIcon from '@mui/icons-material/GTranslate';
import TodayIcon from '@mui/icons-material/Today';
import styled from "styled-components/macro";
import UpdateIcon from '@mui/icons-material/Update';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { SeperationLine } from '../../../../shared/StyledComponents/Musement';
import { useFeed } from '../../../Feed/useFeed';

enum MUSEMENT_COMP1 {
  FreeCancellation,
  Languages,
  Availability,
  ConfirmationType
}

interface IDetail {
  title: string;
  content: JSX.Element | null;
  icon: any;
}

export default function PartOne() {
  const { state: { selectedItemTickets:
    //@ts-ignore
    { languages, availability, free_cancellation, requiresConfirmation }
  } } = useFeed();

  const RequiresPending = (
    <RequiresPendingContainer >
      Booking requires operator's confirmation
    </RequiresPendingContainer>
  )

  const Component1Dic: { [key in MUSEMENT_COMP1]: IDetail } = {
    [MUSEMENT_COMP1.FreeCancellation]: {
      title: 'Free Cancellation', content: free_cancellation ? (
        <FreeCancellation>Free Cancellation</FreeCancellation>) : null,
      icon: StyledFreeCancellationIcon
    },
    [MUSEMENT_COMP1.Languages]: {
      title: 'When',
      content: languages?.length > 0 ? (<div><b>Languages:</b> {languages.join(', ')}</div >) : null,
      icon: StyledLanguagesIcon
    },
    [MUSEMENT_COMP1.Availability]: {
      title: 'Where',
      content: availability ? (<div><b>Availability:</b> {availability}</div>) : null,
      icon: StyledAvailabilityIcon
    },
    [MUSEMENT_COMP1.ConfirmationType]: {
      title: 'Confirmation Time',
      content: !requiresConfirmation ?
        <InstantBookingLabel >Instant Booking!</InstantBookingLabel>
        : RequiresPending
      ,
      icon: !requiresConfirmation ? StyledEventAvailableIcon : StyledUpdateIcon
    }

  }
  return (
    <>
      {Array.from(Array(Object.keys(MUSEMENT_COMP1).length / 2).keys())
        .map((key) => {
          const { icon: Icon, content } = Component1Dic[key]
          return content ? (
            <div key={'partone' + key} >
              <SeperationLine />
              <StyledActivityProperty  >
                {Icon && <Icon />}
                {content}
              </StyledActivityProperty>
            </div>
          ) : null
        })
      }
    </>
  )
}

const StyledActivityProperty = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius:5px;
`
const StyledLanguagesIcon = styled(GTranslateIcon)`
 margin-right: 1vh;
 color: rgb(9, 9, 77); 
`
const StyledFreeCancellationIcon = styled(CheckCircleRoundedIcon)`
 margin-right: 1vh;
 color:green;
`
const StyledAvailabilityIcon = styled(TodayIcon)`
 margin-right: 1vh;
`
const StyledUpdateIcon = styled(UpdateIcon)`
 margin-right: 1vh;
 color:orange;
`
const StyledEventAvailableIcon = styled(EventAvailableIcon)`
 margin-right: 1vh;
`

const FreeCancellation = styled.div`
  font-weight: 700;
  font-size: 1rem;
  color: forestgreen;
`;
const InstantBookingLabel = styled.div`
  font-weight: 500;
  font-size: 0.9rem;
  color: #0f740f;
`;

const RequiresPendingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;