import { combineReducers } from 'redux';
import onBoardingReducer from './onBoardingReducer'
import clientDesignReducer from './clientDesignReducer'
import verificarionEmail from './verificationEmailReducer'

const allReducers = combineReducers({
  onboarding: onBoardingReducer,
  clientDesign: clientDesignReducer,
  verificarionEmail: verificarionEmail
});

export default allReducers;