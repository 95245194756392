import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import styled, { keyframes } from 'styled-components';
import { useBookingTicketsContext } from '../BookingTickets/useBookingTickets';
import './StripeCard.css';
import { Field, ThankYouMessage, SubmitButton, ExtraCustomerField } from './subcomponents';
import usePaymentDialog from './usePaymentDialog';
import LinearProgress from '@mui/material/LinearProgress';
import {
  AppBar,
  Dialog,
  IconButton,
  Slide,
  DialogContent,
  TextField,
  Toolbar,
  Typography,
  CircularProgress,
  Box,
  useTheme,
  useMediaQuery,
} from '@mui/material';

import { TransitionProps } from '@mui/material/transitions';
import BackIcon from '@mui/icons-material/ArrowBackIos';
import SecureIcon from '@mui/icons-material/Lock';
import creditCardsImg from '../../../../assets/images/creditcards.png';
import { ErrorAlert } from '../../../shared/Alerts';
import SecureIMG from '../../../../assets/images/securepayment.png';
import { useTripDetails } from '../../../../providers/TripDetailsProvider';

export default function PaymentDialog() {
  const {
    totalPaymentValue,
    state: { showPaymentModal },
    dispatch,
  } = useBookingTicketsContext();

  const {
    tripDetails: {
      ClientDesign: { MERCHANT_FLOW },
    },
  } = useTripDetails();

  const {
    handleSubmit,
    stripe,
    billingDetails,
    error,
    setError,
    setCardComplete,
    processing,
    setBillingDetailsDynamic,
    setExtraBillingDetailsDynamic,
    completedProcess,
    progressValue,
    loading,
    extraFields,
    merchantPaymentPasswordState,
  } = usePaymentDialog();

  const [merchantPaymentPassword, setMerchantPaymentPassword] = merchantPaymentPasswordState;
  const {
    currency,
    totalSum: { retail_price },
  } = totalPaymentValue();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  function handleClosePaymentDialog(): void {
    dispatch({ type: 'SHOW_PAYMENT_MODAL', payload: false });
  }

  const LoadingPaymentMessage = () => (
    <LoaderContainer>
      {error ? (
        <ErrorAlert>{error.message}</ErrorAlert>
      ) : (
        <>
          <SecurePaymentImg src={SecureIMG} />
          <LoaderText>Loading Secure Payment</LoaderText>
          <CircularProgress />
        </>
      )}
    </LoaderContainer>
  );

  //Use for DolceVita - Just a hotfix
  const MerchantPaymentWithPassword = (
    <LoaderContainer>
      {error ? (
        <ErrorAlert>{error.message}</ErrorAlert>
      ) : (
        <PasswordContainer>
          {/* <PasswordLabel>Payment Getway</PasswordLabel> */}
          <TextField
            style={{ marginBottom: '1rem' }}
            placeholder="Password"
            onChange={(e) => setMerchantPaymentPassword(e.target.value)}
          >
            {merchantPaymentPassword}
          </TextField>
        </PasswordContainer>
      )}
    </LoaderContainer>
  );

  return (
    <Dialog fullScreen={fullScreen} open={showPaymentModal} TransitionComponent={Transition}>
      {/* <DialogTitle>        Book Now      </DialogTitle> */}
      <AppBar style={{ position: 'relative' }}>
        <Toolbar>
          <StyledIconButton edge="start" color="inherit" aria-label="close">
            {/* {!(processing || completedProcess) && */}
            {!completedProcess && <BackIcon onClick={handleClosePaymentDialog} />}
          </StyledIconButton>
          <Typography variant="h6">Payment</Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        {completedProcess ? (
          <ThankYouMessage />
        ) : loading ? (
          <LoadingPaymentMessage />
        ) : (
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Field
                required
                label="First Name"
                id="firstname"
                type="text"
                placeholder=""
                autoComplete="firstname"
                value={billingDetails.firstname}
                onChange={(e) => setBillingDetailsDynamic(e, 'firstname')}
              />
              <Field
                required
                label="Last Name"
                id="lastname"
                type="text"
                placeholder=""
                autoComplete="lastname"
                value={billingDetails.lastname}
                onChange={(e) => setBillingDetailsDynamic(e, 'lastname')}
              />
              <Field
                required
                label="Email"
                id="email"
                type="email"
                placeholder=""
                autoComplete="email"
                value={billingDetails.email}
                onChange={(e) => setBillingDetailsDynamic(e, 'email')}
              />
            </FormGroup>
            <FormGroup>
              {extraFields
                ? Object.keys(extraFields)?.map((key: string) => {
                    const { title, type } = extraFields[key];
                    return (
                      <ExtraCustomerField
                        required
                        label={title}
                        id={key}
                        type={type}
                        placeholder=""
                        autoComplete={key}
                        value={
                          billingDetails?.extra_customer_data
                            ? billingDetails?.extra_customer_data[key]
                            : ''
                        }
                        onChange={(e) => setExtraBillingDetailsDynamic(e, key)}
                      />
                    );
                  })
                : null}
            </FormGroup>
            <ImageContainer>
              <img height="20px" src={creditCardsImg} alt="payment" />
            </ImageContainer>
            {MERCHANT_FLOW ? (
              MerchantPaymentWithPassword
            ) : (
              <FormGroup>
                <div className="FormRow">
                  <CardElement
                    options={stylesObject}
                    onChange={(e) => {
                      setError(e.error);
                      setCardComplete(e.complete);
                    }}
                  />
                </div>
              </FormGroup>
            )}
            <SecurePaymentContainer>
              <StyledSecureIcon />
              <SecurePaymentLabel>
                <i>
                  All data is securely encrypted and available only to the credit card processor
                </i>
              </SecurePaymentLabel>
            </SecurePaymentContainer>
            {error && <ErrorAlert>{error?.message}</ErrorAlert>}
            {processing && <LinearProgressWithLabel value={progressValue} />}
            <SubmitButton processing={processing} error={error} disabled={!stripe || error}>
              Pay ({currency}
              {retail_price})
            </SubmitButton>
          </Form>
        )}
      </DialogContent>
    </Dialog>
  );
}

function LinearProgressWithLabel({ value }) {
  return (
    <Box display="flex" width="80%" alignItems="center" marginTop="1rem">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" value={value} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${value}%`}</Typography>
      </Box>
    </Box>
  );
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PasswordContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SecurePaymentImg = styled.img`
  width: 50%;
  height: auto;
  margin-top: 5rem;
`;

const FormGroup = styled.fieldset`
  margin: 0 15px 20px;
  padding: 0;
  border-style: none;
  background-color: white;
  will-change: opacity, transform;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  width: 100%;
`;

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const LoaderText = styled.h2`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 400;
`;
const ImageContainer = styled.div`
  width: 100%;
  place-content: left;
  margin-left: 1rem;
`;

const stylesObject = {
  hidePostalCode: true,
  style: {
    base: {
      iconColor: '#c4f0ff',
      color: 'black',
      // fontWeight: 500,
      fontSize: '1em',
      ':-webkit-autofill': {
        color: '#fce883',
      },
      '::placeholder': {
        color: '#87bbfd',
      },
    },
    invalid: {
      iconColor: '#de1b1b',
      color: '#de1b1b',
    },
  },
};

const fade = keyframes`
  from {
    opacity: 1;
    transform: scale3D(0.6, 0.95, 0.95);
  }
  to {
    opacity: 1;
    transform: scale3D(1, 1, 1);
  }
`;

const Form = styled.form`
  animation: ${fade} 200ms ease-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
`;

const SecurePaymentLabel = styled.span`
  font-size: 0.8rem;
  color: grey;
  padding: 0 1rem;
  margin-bottom: 1rem;
`;

const SecurePaymentContainer = styled.div`
  display: flex;
  align-content: center;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const StyledSecureIcon = styled(SecureIcon)`
  color: grey;
`;
const StyledIconButton = styled(IconButton)`
  min-width: 3rem;
`;
