import Style from './Spinner.module.scss';

interface IProps {
    style?: any;
    children?: any;
}


const Spinner = ({ children }: IProps) => {
    return (
        <div id={Style.wave}
            style={{
                display: 'flex', justifyContent: 'center', alignItems: 'center',
                width: '100vw', height: '100vh', marginTop: '-100px', flexDirection: 'column'
            }}>
            <div>
                <span className={Style.dot} style={{
                    width: '20px',
                    height: '20px'
                }}></span>
                <span className={Style.dot} style={{
                    width: '20px',
                    height: '20px'
                }}></span>
                <span className={Style.dot} style={{
                    width: '20px',
                    height: '20px'
                }}></span>
            </div>
            <div className={Style.childText}>{children}</div>
        </div>
    )
}
export default Spinner;