import { useEffect, useState, useRef } from 'react';
import GoogleMapReact from 'google-map-react';
import Marker from './Marker/Marker';
import { useTripDetails } from '../../../providers/TripDetailsProvider';
import { TRIP_ITEMS } from '../../../core/utils/enums';
import { useFeed } from '../../pages/Feed/useFeed';
import ReactDependentScript from 'react-dependent-script';

interface IMapItem {
  location: ILocation;
  type: TRIP_ITEMS;
  index: number;
}
interface IProp {
  centerMap?: any;
  setCenterMap?: any
  setCurrentCenter?: any
}

// IMapItem[]

export default function Map({centerMap, setCenterMap}: IProp) {
  const [mapItems, setMapItems] = useState<any[]>([]);
  const [isLoad, setIsLoad] = useState<boolean>(false);

  const mapRef = useRef<any>();

  const {
    tripDetails: { tripDays },
  } = useTripDetails();
  const {
    state: { currentDayIndex, selectedItemBook },
  } = useFeed();

  const dayData = tripDays[currentDayIndex];
  
  useEffect(() => {
    const markers = document.getElementsByClassName('Marker_container');
    for (let i = 0; i < markers.length; i++) {
      markers[i].parentElement?.removeChild(markers[i]);
    }

    getMarkers();

    return () => {
      // @TODO: I deleted the markers twice - check if I can delete one
      const markers = document.getElementsByClassName('Marker_container');
      for (let i = 0; i < markers.length; i++) {
        markers[i].parentElement?.removeChild(markers[i]);
      }
      setMapItems([]);
    };
  }, [currentDayIndex, tripDays]);

  const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

  // TOMOROW
  // useEffect(() => {
  //   if(Object.keys(markersAttraction).length) {
  //     console.log("markersAttraction", markersAttraction)
  //     const oldIndex = mapItems.findIndex(item => item._id === selectedReplace._id);
  //     const newItems = [...tripDays[currentDayIndex].items.filter(item => item.type !== TRIP_ITEMS.Commute)];
  //     console.log('IMPORTANT: ', newItems, oldIndex, markersAttraction);
  //     newItems.splice(oldIndex, 1, markersAttraction);
  //     setMapItems(newItems)
  //   }
  //   // // let newItems = mapItems[oldIndex].
  //   // const filteredItems = mapItems.filter(item => item._id !== selectedReplace._id);
  //   // setMapItems(filteredItems);
  // }, [isReplace, markersAttraction]);

 
  useEffect(() => {
    setCenterMap(selectedItemBook?.location || dayData?.items[0]?.location || dayData?.items[1]?.location);
  }, [currentDayIndex]);

  const getMarkers = () => {
    const newItems: IMapItem[] = [];
    let index: number = 1;
    dayData?.items?.forEach((item) => {
      if (item.type !== TRIP_ITEMS.Commute) {
        newItems.push(item);
        index++;
      }
    });
    setMapItems(newItems);
  }

  const defaultCenter = selectedItemBook?.location || dayData?.items[0]?.location || dayData?.items[1]?.location;

  return API_KEY ? (
    <ReactDependentScript scripts={[`https://maps.googleapis.com/maps/api/js?key=${API_KEY}`]}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: API_KEY }}
        defaultZoom={12}
        center={centerMap || defaultCenter}
        options={{
          disableDefaultUI: true,
          mapTypeControl: false,
          streetViewControl: true,
          styles: [
            {
              featureType: 'poi',
              elementType: 'labels',
              stylers: [{ visibility: 'on' }],
            },
          ],
          fullscreenControl: false,
        }}
        onGoogleApiLoaded={({ map }) => {
          mapRef.current = map;
        }}
        onChange = {()=> {
          if(isLoad) {
            setCenterMap({lat: mapRef.current?.center.lat(), lng: mapRef.current?.center.lng()});
          }
          setIsLoad(true);
        }}
        yesIWantToUseGoogleMapApiInternals
      >
        {mapItems.map((item, index) => {
          return <Marker key={item.location.lat + item.location.lng + index + 1} lat={item.location.lat} lng={item.location.lng} item={item} index={index+1} />;
        })}
      </GoogleMapReact>
    </ReactDependentScript>
  ) : null;
}