//Local Storage

// new design interface
import { IDesign, IOnBoarding_New } from '../../components/pages/Onboarding/interfaces';
import { isValidLang } from '../utils/common';
import { Langs } from '../utils/enums';
import { InsertAllTripItemsIDsIntoMap } from '../utils/feed';
import encryptLocalStorage from './encrypt';
import ParamsService from './params';

const getLocalItem = (key: string) => {
  try {
    const parsedValue = localStorage.getItem(key);
    return parsedValue ? JSON.parse(encryptLocalStorage.decode(parsedValue) || '') : null;
  } catch (err) {
    return localStorage.getItem(key) || "";
  }
};

const saveLocalItem = (key: string, value: any) => {
  const parsedValue = JSON.stringify(value);
  const encryptedValue = encryptLocalStorage.encode(parsedValue);
  return localStorage.setItem(key, encryptedValue);
};

const removeLocal = (key: string) => localStorage.removeItem(key);

const clearLocal = (): void => localStorage.clear();

const getToken = (): string | null => StorageService.local.get.item('token') || null;

/** fetching token from url an storing it in local storage */
const getValidatedEmail = (): boolean => StorageService.local.get.item('ValidatedEmail') !== null;

const getClientDesign = (): any | null =>
  StorageService.local.get.item('ClientDesign') || null;


const getClientDesign_New = (): IDesign| null =>
  StorageService.local.get.item('ClientDesign') || null;

const getOnboardingData_New = (): IOnBoarding_New| null =>
  StorageService.local.get.item('OnboardingData') || null;

const getRedirectionSource = (): string | null => StorageService.local.get.item('rd') || null;
const getTEST = (): boolean => StorageService.local.get.item('test') !== null;

const saveToken = (paramToken: string): void => StorageService.local.save.item('token', paramToken);

const saveValidatedEmail = (): void => StorageService.local.save.item('ValidatedEmail', true);

const saveClientDesign = (ClientDesign: any) =>
  StorageService.local.save.item('ClientDesign', ClientDesign);

const saveNewClientDesign = (ClientDesign: IDesign) => {
  StorageService.local.save.item('ClientDesign', ClientDesign);
}

const saveOnboardingData = (OnboardingData: IOnBoarding_New) => {
  StorageService.local.save.item('OnboardingData', OnboardingData);
}

const saveRedirectionSource = (redircetionSource: string) =>
  StorageService.local.save.item('rd', redircetionSource);
const saveTEST = () => StorageService.local.save.item('test', true);

//<<<<<<<<<<<<<<<<<<<<<<=========================Session Storage==============================>>>>>>>>>>>>>>>>

const getSessionItem = (key: string) => JSON.parse(sessionStorage.getItem(key) || 'null');

const saveSessionItem = (key: string, value: any) => {
  if (!key || !value) return;
  return sessionStorage.setItem(key, JSON.stringify(value));
};

const removeSessionItem = (key: string) => sessionStorage.removeItem(key);

const clearSessionItems = () => sessionStorage.clear();

function getTripDetails(): ITripDetails_Converted | null {
  const cachedTrip = StorageService.session.get.item('tripDetails');
  if (cachedTrip && Object.keys(cachedTrip).length) {
    return cachedTrip;
  }
  return null;
}

function getLanguage(): Langs | null {
  return StorageService.session.get.item('lng');
}
function getCurrentDay(): number {
  return StorageService.session.get.item('currentDay') || 0;
}
function getExploreAttractions() {
  return StorageService.session.get.item('exploreAttractions') || null;
}
function getTripPrefrences(): ITripPreferences | null {
  return StorageService.session.get.tripDetails()?.tripPreferences || null;
}
function getFavorites(): Array<number> {
  return StorageService.session.get.tripDetails()?.favorites || [];
}

const getPresent = (): boolean => StorageService.local.get.item('present') !== null;

function getTripInterests(): number[] {
  const cachedtripPrefrences = StorageService.session.get.tripPrefrences();
  //@ts-ignore
  const { interests }: Array<number> = cachedtripPrefrences;
  return interests || null;
}

function getTripItemsIDs(): Array<number> | null {
  return StorageService.session.get.item('TripItemsIDsMap') || null;
}

function getMusementToken(): IMusementToken | null {
  return StorageService.session.get.item('MusementToken') || null;
}

/**
 * This function saves the language
 * if lang in URL parameters--> save it and return it
 * else if lang in cache-->  return it
 * else return default (English)
 * @returns Language
 */
function saveLanguage(): Langs {
  // if language exist in parameters--> save and use it
  let LanguageParam: string = ParamsService.getLanguage();
  if (isValidLang(LanguageParam)) {
    StorageService.session.save.item('lng', LanguageParam);
    //@ts-ignore
    return LanguageParam;
  } else {
    //if lang exist in cache - return it and exit
    const cachedLang = StorageService.session.get.lang();
    if (cachedLang) return cachedLang;
  }

  //else set English as default
  StorageService.session.save.item('lng', Langs.english);
  return Langs.english;
}

function saveTripDetails(data: ITripDetails_Converted): void {
  StorageService.session.save.item('tripDetails', data);
}

function saveCurrentDay(currentDayIndex: number): void {
  if (currentDayIndex === 0) {
    StorageService.session.remove('currentDay');
  } else {
    StorageService.session.save.item('currentDay', currentDayIndex);
  }
}
const savePresent = () => StorageService.local.save.item('present', true);

function saveTripPrefrences(tripPrefrences: ITripPreferences): void {
  StorageService.session.save.item('tripPrefrences', { tripPrefrences });
}

function saveFavorites(favorites: number[]): void {
  const tripDetails: ITripDetails_Converted | null = getTripDetails();
  if (tripDetails) {
    const updatedTripDetails: ITripDetails_Converted = { ...tripDetails, favorites };
    saveTripDetails(updatedTripDetails);
  }
}

function saveExploreAttractions(exploreAttractions: any): void {
  if (!exploreAttractions || Object.keys(exploreAttractions).length < 1) return;
  return StorageService.session.save.item('exploreAttractions', exploreAttractions);
}

function saveTripItemsIDs(): Array<number> | void {
  const cachedTripDetails = StorageService.session.get.tripDetails();
  if (!cachedTripDetails) return;
  const TripItemsIDsMap: Set<number> = InsertAllTripItemsIDsIntoMap(cachedTripDetails);
  const IDsArray: number[] = Array.from(TripItemsIDsMap);
  StorageService.session.save.item('TripItemsIDsMap', IDsArray);
  return IDsArray;
}

//If TripItemsIDsMap in chace --> return it
//else: create it,save in local storage and return it
function getOrSaveTripItemsIDs(): Array<number> | void {
  const cachedTripItemsIDsMap: Array<number> | null = StorageService.session.get.tripItemsIDs();
  if (cachedTripItemsIDsMap) {
    return cachedTripItemsIDsMap;
  }
  return saveTripItemsIDs();
}

function saveMusementToken(musementToken: IMusementToken) {
  StorageService.session.save.item('MusementToken', musementToken);
}

//--------------------- Service API-----------------

const StorageService = {
  local: {
    get: {
      item: getLocalItem,
      token: getToken,
      validatedEmail: getValidatedEmail,
      clientDesign: getClientDesign,
      clientDesignNew:getClientDesign_New,
      redirectionSource: getRedirectionSource,
      TEST: getTEST,
      present: getPresent,
      onboardingData:getOnboardingData_New
    },
    save: {
      item: saveLocalItem,
      token: saveToken,
      TEST: saveTEST,
      validatedEmail: saveValidatedEmail,
      clientDesign: saveClientDesign,
      redirectionSource: saveRedirectionSource,
      present: savePresent,
      onboardingData: saveOnboardingData
    },
    // getOrSaveToken,
    remove: removeLocal,
    clear: clearLocal,
  },
  session: {
    get: {
      item: getSessionItem,
      tripDetails: getTripDetails,
      lang: getLanguage,
      currentDay: getCurrentDay,
      exploreAttractions: getExploreAttractions,
      tripPrefrences: getTripPrefrences,
      interests: getTripInterests,
      tripItemsIDs: getTripItemsIDs,
      musementToken: getMusementToken,
      favorites: getFavorites,
    },
    save: {
      item: saveSessionItem,
      language: saveLanguage,
      tripDetails: saveTripDetails,
      tripPrefrences: saveTripPrefrences,
      currentDay: saveCurrentDay,
      exploreAttractions: saveExploreAttractions,
      tripItemsIDs: saveTripItemsIDs,
      musementToken: saveMusementToken,
      favorites: saveFavorites,
    },
    getOrSaveTripItemsIDs: getOrSaveTripItemsIDs,
    remove: removeSessionItem,
    clear: clearSessionItems,
  },
  cookie: {},
};

export default StorageService;