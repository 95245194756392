export enum Langs {
  hebrew = 'he',
  english = 'en',
}

export enum Directions {
  RightToLeft = 'rtl',
  LeftToRight = 'ltr',
}

// export enum CLIENTS {
//   bridgify = "bridgify",
//   visitdubai = "visitdubai",
//   alamocitystays = "alamocitystays",
//   neatsuites = "neatsuites",
//   loginn = "loginn",
//   rimonim = "rimonim",
//   olive = "olive",
//   sofitel = "sofitel",
//   passportcard = "passportcard",
//   Atlas_MELODY_HOTEL = "Atlas_MELODY_HOTEL",
//   Atlas_YAM_HOTEL = "Atlas_YAM_HOTEL",
//   Atlas_TalbytheBeach_Hotel = "Atlas_TalbytheBeach_Hotel",
//   Atlas_SHALOM_HOTEL = "Atlas_SHALOM_HOTEL",
//   Atlas_SADOT_HOTEL = "Atlas_SADOT_HOTEL",
//   Atlas_MARKETHOUSE_HOTEL = "Atlas_MARKETHOUSE_HOTEL",
//   Atlas_FABRIC_HOTEL = "Atlas_FABRIC_HOTEL",
//   Atlas_CINEMA_HOTEL = "Atlas_CINEMA_HOTEL",
//   Atlas_CenterChic_HOTEL = "Atlas_CenterChic_HOTEL",
//   Atlas_Artist_Hotel = "Atlas_Artist_Hotel",
//   Atlas_65_HOTEL = "Atlas_65_HOTEL"
// }

export enum INTERESTS {
  Art,
  Shopping,
  HiddenGems,
  HistoricSites,
  Nightlife,
  Beach,
  Watersports,
  Architecture,
  Nature,
  Museums,
  SportingEvents,
  Popular,
  WalkingAndBiking,
  UrbanParks,
  Religion,
  ShowsAndPerformances,
  Festivals,
  Cuisine,
  Amusements,
  OutdoorActivities,
  Culture,
  LocalEvents,
  LocalMarkets,
  StreetFood,
  GuidedTours,
  LGBT,
  Music,
  WellnessAndWellbeing,
}

export enum TRIP_TYPES {
  Leisurely,
  Moderate,
  SeeItAll,
}

export enum TRIP_ITEMS {
  Attraction = 1,
  Restaurant = 2,
  Commute = 3,
}

export enum TOAST_TYPES {
  Error = 'error',
  Success = 'success',
  Info = 'info',
  Warning = 'warning',
}

export enum LoaderTypes {
  Dots,
  Images,
  WelcomePage,
}

export enum AttractionStates {
  Navigate,
  AddToTrip,
  BookNow,
  Replace,
  Remove,
  NotAvailable,
  Booked
}

export enum BookedStatuses {
  NotBooked,
  Booked,
  Pending,
  Cancelled,
}

export enum HASH {
  Info = '#info',
  Booking = '#booking',
  // Payment = "#payment",
  BookingInfo = '#BookingInfo',
  Explore = '#explore',
  TiqetsBooking = '#TiqetsBooking',
}

export enum OnBoardingStates {
  WelcomePage,
  TripPlanningPage, //Screen For dubai
  StayDetailsPage,
  DiningRecommendation,
  TripTypesPage,
  InterestsPage,
}

export enum CardBorderColors {
  FreeAttraction = 1,
  PaidAttraction = 2,
  Other = 3,
}