import { Button } from '@mui/material';
import moment from 'moment';
import styled from 'styled-components/macro';
import { useTripDetails } from '../../providers/TripDetailsProvider';
import { useFeed } from '../pages/Feed/useFeed';
import { HideOnDesktop } from '../shared/Layout';
import StorageService from '../../core/services/storage'

interface IProps {
  actionIcons: IActionIcons[];
  handleShowAttractions: () => void;
}
export default function FeedTopBar({ actionIcons, handleShowAttractions }: IProps) {
  const {
    state: { currentDayIndex, showMapView },
  } = useFeed();
  const {
    tripDetails: {
      tripAccommodation: { city, start_date },
      ClientDesign: { PrimaryColor }
    },
  } = useTripDetails();
  const hotel = StorageService.local.get.item('hotel');
  const destination = localStorage.getItem('destination');
  let currentDayLabel = moment(start_date).add(currentDayIndex, 'days').format('MMM DD');

  return (
    <MainContainer>
      <Container>
        <Title>{`${currentDayLabel} • ${hotel?.city?.name || city || destination}`}</Title>
      </Container>

        <ActionsIconsContainer>
          <ActionButton 
            onClick={(e) => {
              handleShowAttractions();
              actionIcons[0].onIconClick(e);
            }} 
            style={actionIcons[0].text === "Map View" && showMapView ? {
              border: `2px solid ${PrimaryColor.bgColor || "red"}`
            } : {
              border: "none"
            }}>
          <Img src={actionIcons[0].icon} alt="action" />
          <ActionTitle>{actionIcons[0].text}</ActionTitle>
          </ActionButton>
            <ActionButton 
              onClick={actionIcons[1].onIconClick} 
              style={actionIcons[1].text === "Map View" && showMapView ? {
                border: `2px solid ${PrimaryColor.bgColor || "red"}`
              } : {
                border: "none"
              }}
            >
            <Img src={actionIcons[1].icon} alt="action" />
            <ActionTitle>{actionIcons[1].text}</ActionTitle>
          </ActionButton>
        </ActionsIconsContainer>
    </MainContainer>
  );
}

const ActionButton = styled(Button)`
  && {
    padding: 10px;
    width: 80px;
    height: 60px;
    background: transparent;
    color: black;
    display: flex;
    flex-direction: column;
    text-transform: unset;
    margin-right: 5px;
    background-color: white !important;
    border-radius: 10px;
    /* margin-right: 0.9rem; */
  }
`;

const ActionTitle = styled.span`
  font-size: 0.7rem;
  line-height: 12px;
`;

const Img = styled.img`
  height: 4vh;
  margin-right: 5px;
`;

const ActionsIconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const Title = styled.div`
  white-space: nowrap;
`;

const MainContainer = styled.div`
  display: flex;
  
  justify-content: center;
  align-items: center;
  height: 60px;
  gap: 15px;
  margin: 20px 0;
`;

const Container = styled.div`
  background-color: #ffffffe6;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  border-radius: 0px 10px 10px 0px;
  padding: 0 10px 0 20px;
  position: sticky;
  margin: 1rem 0;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
`;
