import { BRIDGIFY_DEFAULT_JSON } from '../../providers/TripDetailsProvider';
import { Endpoints } from '../utils/endpoints';
import Calls from './calls';
import StorageService from './storage';

const MUSEMENT_URL = {
  activities: `/activities`,
  login: `/login`,
  carts: `/carts`,
  orders: `/orders`,
  payments: `/payments/no/payment`,
};
//
export async function getLoginMusementToken(): Promise<string> {
  const EXPERATION_PERIOD = 60 * 100 * 14; //14 minutes

  //check if there's a token in the session storage
  const cachedToken: IMusementToken | null = StorageService.session.get.musementToken();
  if (cachedToken) {
    const { timestamp, value } = cachedToken;
    const nowTime = Date.now();
    // check its expiration time compares to the Date.Now
    const timeGap = nowTime - timestamp;
    if (timeGap < EXPERATION_PERIOD) {
      //if not expired => return token;
      return value;
    }
  }

  const clientDesign: IClientDesign =
    StorageService.local.get.clientDesign() || BRIDGIFY_DEFAULT_JSON;
  const merchantFlow: boolean = clientDesign?.MERCHANT_FLOW === true;

  //if expired => get new token; save it in session storage; return it
  const {
    REACT_APP_MUSEMENT_CLIENT_ID_MERCHANT,
    REACT_APP_MUSEMENT_CLIENT_SECRET_MERCHANT,
    REACT_APP_MUSEMENT_CLIENT_ID_AFFILIATE,
    REACT_APP_MUSEMENT_CLIENT_SECRET_AFFILIATE,
  } = process.env;
  const clientID = merchantFlow
    ? REACT_APP_MUSEMENT_CLIENT_ID_MERCHANT
    : REACT_APP_MUSEMENT_CLIENT_ID_AFFILIATE;
  const clientSecret = merchantFlow
    ? REACT_APP_MUSEMENT_CLIENT_SECRET_MERCHANT
    : REACT_APP_MUSEMENT_CLIENT_SECRET_AFFILIATE;

  const URL = `${process.env.REACT_APP_MUSEMENT_URL}${MUSEMENT_URL.login}?client_id=${clientID}&client_secret=${clientSecret}&grant_type=client_credentials`;
  const loginToken: ILoginToken = await Calls.general.get(URL);
  const newToken: IMusementToken = {
    value: loginToken.access_token,
    timestamp: Date.now(),
  };
  StorageService.session.save.musementToken(newToken);
  return newToken.value;
}

// GET {{api_base_url}}/activities/{uuid}/dates?date_from=YYYY-MM-DD&date_to=YYYY-MM-DD
export async function getActivity(uuid: string) {
  const URL = `${MUSEMENT_URL.activities}/${uuid}`;
  const activity: IActivity = await Calls.musement.get(URL);
  return activity;
}

// GET {{api_base_url}}/activities/{uuid}/dates?date_from=YYYY-MM-DD&date_to=YYYY-MM-DD
export async function getDatesByActivityID(
  uuid: string,
  start_date?: string | null,
  finish_date?: string | null,
  pickupPoint?: string,
) {
  if (!start_date || !finish_date) throw new Error('dates range are missing');

  const datesRangeString = `dates?date_from=${start_date}&date_to=${finish_date}`;
  const pickupString = `&pickup=${pickupPoint}`;

  // const URL = `${MUSEMENT_URL.activities}/${uuid}/${pickupPoint ? pickupString : ''}`;
  const URL = `${MUSEMENT_URL.activities}/${uuid}/${datesRangeString}${
    pickupPoint ? pickupString : ''
  }`;
  const dates: IDate[] = await Calls.musement.get(URL);
  // const dates = require("../../assets/mocks/Dates.json");
  if (dates.length === 0) throw new Error('no date available');
  return dates;
}

// GET   https://{{api_base_url}}/api/v3/activities/{activity_uuid}/pickups
export async function getPickupsByActivityID(uuid: string) {
  const URL = `${MUSEMENT_URL.activities}/${uuid}/pickups`;
  const pickups: IPickupPoint[] = await Calls.musement.get(URL);
  // const pickups: IPickupPoint[] = require("../../assets/mocks/PickupsPoints.json");
  return pickups;
}

// GET {{api_base_url}}/activities/{uuid}/dates/YYYY-DD-MM
export async function getGroupsByDate(uuid: string, date?: string, pickupPoint?: string) {
  if (!uuid) throw new Error();
  let URL: string;
  const pickupString = `?pickup=${pickupPoint}`;
  if (!date) {
    const today = new Date();
    const year = today.getFullYear();
    URL = `${MUSEMENT_URL.activities}/${uuid}/dates/${year}-12-31`;
  } else {
    URL = `${MUSEMENT_URL.activities}/${uuid}/dates/${date}${pickupPoint ? pickupString : ''}`;
  }

  const data = await Calls.musement.get(URL);
  // const groups = require("../../assets/mocks/Day.json")[0].groups;
  return data[0]?.groups || [];
}

export async function CreateCart() {
  const response: ICart = await Calls.musement.post(MUSEMENT_URL.carts, {});
  const { uuid } = response;
  return uuid;
}

export async function addItemToCart(createdCartUUID, productDetails) {
  const URL = `${MUSEMENT_URL.carts}/${createdCartUUID}/items`;

  const response: any = await Calls.musement.post(URL, productDetails);
  return response;
}

export async function getCart(CartUUID) {
  const URL = `${MUSEMENT_URL.carts}/${CartUUID}`;
  const response: ICart = await Calls.musement.get(URL);
  return response;
}

// PUT https://sandbox.Calls.musement.com/api/v3/carts/cartUuid/customer
export async function putCartCustomer(CartUUID: string, customer: IBillingDetails) {
  const { email, firstname, lastname, extra_customer_data } = customer;
  const cartCustomer: ICartCustomer = {
    email,
    firstname,
    lastname,
    musement_newsletter: 'NO',
    events_related_newsletter: 'NO',
    thirdparty_newsletter: 'NO',
  };
  if (extra_customer_data) {
    cartCustomer['extra_customer_data'] = extra_customer_data;
  }
  const URL = `${MUSEMENT_URL.carts}/${CartUUID}/customer`;
  const response: any = await Calls.musement.put(URL, cartCustomer);
  return response;
}

// Now that the cart is created and all the information has been set, you now need to create the order
//   POST https://api.Calls.musement.com/api/v3/orders \
//   -H 'Authorization: Bearer valid' \
//   -H 'Cache-Control: no-cache' \
//   -H 'Content-Type: application/json' \
//   -H 'X-Musement-Version: 3.4.0' \
//   -d '{"cart_uuid" : "[CART_UUID]"}'

export async function makeAnOrder(cart_uuid) {
  const response: IOrder = await Calls.musement.post(MUSEMENT_URL.orders, { cart_uuid });
  return response;
}

// Pay an order with stripe!
//  POST https://api.Calls.musement.com/api/v3/payments/split/payment \
//   -H 'Accept: application/json, application/xml' \
//   -H 'Cache-Control: no-cache' \
//   -H 'Content-Type: application/json' \
//   -d '{
//     "order_uuid": "valid-order-uuid-here",
//     "stripe_token": "stripe_payment_method_id_here"
// }
// '
export async function payOrder(body): Promise<IPaymentResponse> {
  const response = await Calls.musement.post(MUSEMENT_URL.payments, body);
  return response;
}

// The customer is the person who buy the ticket. These data are used to create the invoice and,
//  if the activity does not require a custom participants info
export async function getCustomerSchema(cardUUID) {
  const URL = `${MUSEMENT_URL.carts}/${cardUUID}/customer/schema`;
  const response = await Calls.musement.get(URL);
  return response;
}

// https://api.Calls.musement.com/api/v3/activities/{uuid}/refund-policies
export async function getCancellationPolicy(uuid: string): Promise<ICancellationPolicy[]> {
  const URL = `${MUSEMENT_URL.activities}/${uuid}/refund-policies`;
  const response = await Calls.musement.get(URL);
  return response;
}
// https://api.Calls.musement.com/api/v3/orders/{order_uuid}/items/{order-item_uuid}/refund-policies
export async function getCancellationPolicyForOrder(
  uuid: string,
  orderUuid: string,
): Promise<ICancellationRemainingTime[]> {
  const URL = `${MUSEMENT_URL.orders}/${uuid}/items/${orderUuid}/refund-policies`;
  const response = await Calls.musement.get(URL);

  // const response: ICancellationPolicy[] = [
  //   {
  //     "uuid": "cdf7e764-abc9-44a5-9dd5-2084eabe806d",
  //     "period": "P0DT72H0M",
  //     "type": "PERCENTAGE",
  //     "value": 100
  //   },
  //   {
  //     "uuid": "291ac43a-7036-4c67-b193-f8ed858c4813",
  //     "period": "P0DT24H0M",
  //     "type": "PERCENTAGE",
  //     "value": 50
  //   }
  // ]

  return response;
}

// setting Musement booked status
// endpoint : https://api.dev.bridgify.io/attraction-booked/
interface ISetAttractionStatus {
  trip_id: ITripDetails_Converted['_id'];
  item_id: ITripItem_Attraction['_id'];
  order_id: string;
  activity_datetime?: string;
  status: 'BOOKED' | 'PENDING' | 'CANCELLED';
  pickup?: IPickupDetails;
  merchant_flow: boolean;
  client: string;
}
export async function setAttractionStatus(data: ISetAttractionStatus) {
  await Calls.bridgify.post(Endpoints.setAttractionStatus, data);
  return;
}

//https://sandbox.Calls.musement.com/api/v3/orders/orderUuid
export async function getOrderDetails(uuid: string) {
  const URL = `${MUSEMENT_URL.orders}/${uuid}`;
  const response = await Calls.musement.get(URL);
  return response;
}

/**
 * Cancel reservation for anitem
 * @param uuid order's uuid
 * @param itemUUID item's uuid
 * @returns
 */
//https://sandbox.Calls.musement.com/api/v3/orders/orderUuid
export async function cancelOrderOfItem(orderUuid: string, orderItemUuid: string): Promise<void> {
  const URL = `${MUSEMENT_URL.orders}/${orderUuid}/items/${orderItemUuid}`;
  await Calls.musement.delete(URL);
}

/**
 * example: POST \
  {{api_base_url}}/payments/no/payment \
  -d { "uuid" : "efbc64fa-ab9f-40aa-84db-590beed8350d"  }
 * @param uuid order's uuid
 */
//https://sandbox.Calls.musement.com/api/v3/orders/orderUuid
export async function CompleteNoPayment(uuid: string): Promise<void> {
  // const URL = `${MUSEMENT_URL.payments}`
  await Calls.musement.post(MUSEMENT_URL.payments, { uuid });
}
