import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Routes } from '../../core/utils/endpoints';
import { useTripDetails } from '../../providers/TripDetailsProvider';
import toast from 'react-hot-toast';
import Loading from './Onboarding/OnboardingComponents/Loading';

export default function WelcomePage() {
  const {
    tripDetails: {
      _id,
      ClientDesign: { Logo: LogoSrc },
    },
    navigatePageWithWelcomeScreen,
  } = useTripDetails();

  const {design} = useSelector((state: any) => state.clientDesign);

  useEffect(() => {
    if (_id !== 0) {
      navigatePageWithWelcomeScreen(Routes.feed);
    }
  }, []);
  
  return <Loading logo={design?.Logo} color={design?.PrimaryColor?.bgColor} text="Welcome to your personalized trip itinerary!" />
}