import { useRef, useState } from 'react';
import { IItemButtonsDic } from './Item';
import {
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Fab,
} from '@mui/material';
import DeletePNG from "../../../assets/images/icons/delete.png";
import RecyclePNG from "../../../assets/images/icons/recycle.png";
import CancelPNG from "../../../assets/images/icons/cancel.png";
import Duration from '../../shared/Duration';
import Rank from '../../shared/Rank';
import { BookedStatuses, CardBorderColors } from '../../../core/utils/enums';
import ContomizedButton from '../../shared/Button';
import styled from 'styled-components/macro';
import Modal from '../../shared/Modal';
import CancellationModal from './CancellationModal';
import { useTripDetails } from '../../../providers/TripDetailsProvider';
import tripInterests from "../../../assets/static/tripInterests/tripInterests";
import { useFeed, FeedProvider } from '../Feed/useFeed';
import ThreeDotsSVG from "../../../assets/images/icons/three_dots.svg"; 
import interests from '../../../assets/static/tripInterests/en_interests.json'

interface IProps {
  // item: ITripItem_Resturant | ITripItem_Attraction;
  item: any;
  componentLocation?: 'Feed' | 'Explore';
  onDelete(id: number | null): void;
  ButtonProps: IItemButtonsDic;
  handleOpenItemDialog: () => void;
  showSpinner: boolean;
  last?: boolean;
  TIMELINE?: boolean;
  ACTIVITIES_COUNTER?: number;
  handleOpenExplore?: ()=> void;
}

export default function ItemCard({
  item,
  componentLocation = 'Feed',
  ACTIVITIES_COUNTER,
  onDelete,
  ButtonProps,
  showSpinner,
  last,
  handleOpenItemDialog,
  TIMELINE = false,
  handleOpenExplore
}: IProps) {
  const { imgURL, name, _id: item_id, rank, duration, tags } = item;
  const imageRef = useRef<any>();
  //@ts-ignore
  const bookingStatus: BookedStatuses = item?.bookingStatus || BookedStatuses.NotBooked;
  //@ts-ignore
  // const activityDatetime: string | null = (item?.activityDatetime) ? item.activityDatetime : null
  const {
    tripDetails: { _id: trip_id },
  } = useTripDetails();

  const {
    state: { currentDayIndex, setItinerary, isOpenExplore },
    dispatch,
  } = useFeed();

  const [showDeletionModal, setShowDeletionModal] = useState<boolean>(false);
  const [showCancelBookingModal, setShowCancelBookingModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [showDeleteIcon, setShowDeleteIcon] = useState<boolean>(false);

  const {
    tripDetails: {
      ClientDesign: { PrimaryColor, SecondaryColor },
    },
  } = useTripDetails();
  const { onAttractionDelete } = FeedProvider();

  // console.log('ButtonProps', ButtonProps)

   

  const cardBorderColor =
    item.cardBorderColor === CardBorderColors.PaidAttraction
      ? PrimaryColor.bgColor
      : item.cardBorderColor === CardBorderColors.FreeAttraction
      ? SecondaryColor.bgColor
      : '#9E9E9E';

  async function confirmDelete(event) {
    event.stopPropagation();
    setSuccess(false);
    setLoading(true);

    setShowDeletionModal(false);
    await onDelete(item_id);

    setSuccess(true);
    setLoading(false);
  }

  function handleDelete(event) {
    event.stopPropagation();
    setShowDeletionModal(true);
  }

  function handleClose(event) {
    event.stopPropagation();
    setShowDeletionModal(false);
  }

  function openExplore(event) {
    event.stopPropagation();
    dispatch({ type: 'SELECTED_ITEM_REPLACE', payload: item});
    dispatch({ type: 'OPEN_EXPLORE', payload: true});
  }

  function deleteAttr(event) {
    event.stopPropagation();
    event.stopPropagation();
    let mapEvents = JSON.parse(localStorage.getItem('trip')!).sections[currentDayIndex].events;
    mapEvents.forEach((event, i) => {
      if (event.title === item.name) {
        onAttractionDelete(i);
      }
    });
  }

  function toggleDeleteIcon(event) {
    event.stopPropagation();
    setShowDeleteIcon(!showDeleteIcon);
  }
  function handleOpenCancelBookingModal(event) {
    event.stopPropagation();
    setShowCancelBookingModal(true);
  }
  function handleCloseCancelBookingModal(event) {
    event.stopPropagation();
    setShowCancelBookingModal(false);
  }




  let sourceIcon;
  if(item.source){
   switch(item.source) {
      case 'Tiqets':
        sourceIcon = 'https://bridgify-frontend-s3.s3.eu-central-1.amazonaws.com/icons/tiquets_icn.png'
        break;
      case 'Musement':
      sourceIcon = 'https://bridgify-frontend-s3.s3.eu-central-1.amazonaws.com/icons/musment_icn.png'
        break;
      case 'Viator':
      sourceIcon = 'https://bridgify-frontend-s3.s3.eu-central-1.amazonaws.com/icons/viator_icn.png'
        break;
      case 'TicketMaster':
      sourceIcon = 'https://bridgify-frontend-s3.s3.eu-central-1.amazonaws.com/icons/ticketmaster_icn.png'
        break;
      case 'SportEvents365':
      sourceIcon = 'https://bridgify-frontend-s3.s3.eu-central-1.amazonaws.com/icons/sportesevents_icn.png'
        break;
      case 'Eventim':
      sourceIcon = 'https://bridgify-frontend-s3.s3.eu-central-1.amazonaws.com/icons/eventim_icn.png'
        break;
      case 'GetYourGuide':
      sourceIcon = 'https://bridgify-frontend-s3.s3.eu-central-1.amazonaws.com/icons/getyourguide_icn.png'
        break;
      case 'Tickitto':
      sourceIcon = 'https://bridgify-frontend-s3.s3.eu-central-1.amazonaws.com/icons/tickitto_icn.webp'
        break;
      case 'ExtremeIL':
      sourceIcon = 'https://bridgify-frontend-s3.s3.eu-central-1.amazonaws.com/icons/extremeIL_icn.png'
        break;
      case 'GoogleMaps':
      sourceIcon = 'https://bridgify-frontend-s3.s3.eu-central-1.amazonaws.com/icons/googleMaps_icn.jpg'
        break;
      case 'Getyourguide':
      sourceIcon = 'https://bridgify-frontend-s3.s3.eu-central-1.amazonaws.com/icons/getyourguide_icn.png'
        break;
      case 'Google Places':
        sourceIcon = 'https://bridgify-frontend-s3.s3.eu-central-1.amazonaws.com/icons/googlePlaces_icn.png'
          break;
      default:
    sourceIcon = 'https://bridgify-frontend-s3.s3.eu-central-1.amazonaws.com/icons/bridgify_icn.png'
    }
  }else{
    sourceIcon = 'https://bridgify-frontend-s3.s3.eu-central-1.amazonaws.com/icons/googlePlaces_icn.png'
  }

  /**
   * This function does two things simonteniously
   * 1. Adds/Removes the item_id from the favorites on Session Storage
   * 2. Adds/Removes the item_id from the favorites on the backend DB
   * @param event - onClick event
   */

  const bookingStatusLabelMap: { [key in BookedStatuses]: { text?: string; color?: string } } = {
    [BookedStatuses.NotBooked]: {},
    [BookedStatuses.Cancelled]: { text: 'CANCELLED', color: '#E45B5B' },
    [BookedStatuses.Pending]: { text: 'PENDING', color: '#EE8E1E' },
    [BookedStatuses.Booked]: { text: 'BOOKED', color: '#3DB007' },
    [BookedStatuses.Booked]: { text: 'BOOKED', color: '#3DB007' },

  };

  const linkToMusementMail = (
    <b>
      <Alink href="mailto: support@musement.com" target="_top">
        support@musement.com
      </Alink>
    </b>
  );

  let { onClick, text, disabled, buttonColor, icon, textColor } = ButtonProps;

  const handleItemAction = () => {
    handleOpenItemDialog();
  }

  return (
    <CardContainer
      key={item_id}
      componentLocation={componentLocation}
    >
      <StyledCard cardBorderColor={cardBorderColor}>
        <CardActionArea onClick={handleItemAction}> 
          {/* {ACTIVITIES_COUNTER && <TimelineIndex>{ACTIVITIES_COUNTER}</TimelineIndex>} */}
        <img 
          ref={imageRef}
          src={ imgURL || interests[tags[0]]?.img || "https://bridgify-frontend-s3.s3.eu-central-1.amazonaws.com/image-not-available.png"}
          style={{ width: "100%", height: '140px', objectFit: "cover" }}
          onError={() => imageRef.current.src = "https://bridgify-frontend-s3.s3.eu-central-1.amazonaws.com/image-not-available.png"} 
          alt="" 
        />
       <TagsContainer TIMELINE={TIMELINE}>
        {componentLocation === 'Feed'
          ? tags
              ?.slice(0, 2)
              .map((tag) => (
                <Tag key={tag}>
                  {tripInterests!.Full[tag]?.title
                    ? tripInterests!.Full[tag]?.title
                    : 'Restaurant'}
                </Tag>
              ))
          : tags?.map((t) => <Tag key={t}>{t}</Tag>)}
          </TagsContainer>
          <Content>
            <div style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}>
              <Title>{name}</Title>
              {componentLocation === 'Feed' && (
                <DeleteIconContainer>
                  {bookingStatus === BookedStatuses.Booked ||
                  bookingStatus === BookedStatuses.Pending ? (
                    <Modal
                      handleBack={handleClose}
                      handleConfirm={confirmDelete}
                      open={showDeletionModal}
                      title={'Are you sure?'}
                    >
                      Pending and booked attractions cannot be removed from your trip.
                      <br />
                      If you wish to cancel your order, first cancel it by sending a message to
                      {linkToMusementMail}
                      <br />
                      <br />
                      Once it's cancelled, you'll be able to remove it from the itinerary.
                    </Modal>
                  ) : (
                    <Modal
                      handleBack={handleClose}
                      handleConfirm={confirmDelete}
                      open={showDeletionModal}
                      title={'Are you sure?'}
                      primaryButtom
                    >
                      You are about to remove <b>{name}</b> from your trip itinerary..
                    </Modal>
                  )}
                  {showDeleteIcon && (
                    <div style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      position: "absolute",
                      marginTop: 122,
                      gap: 4
                    }}>
                      {success ? null : <Icon src={RecyclePNG} onClick={openExplore} width="20" alt="" />}
                      {success ? null : <Icon src={DeletePNG} onClick={deleteAttr} width="20" alt="" />}
                      {loading && <CircularProgress size={37} />}
                    </div>
                  )}
                  {item.name !== 'Free Time' && <ThreeDotsMenu src={showDeleteIcon ? CancelPNG : ThreeDotsSVG} style={showDeleteIcon ? {
                    backgroundColor: PrimaryColor.bgColor,
                  } : {}} alt="item_info" onClick={toggleDeleteIcon} />}
                </DeleteIconContainer>
              )}
            </div>
            <RankAndStatusContainer>
              <Rank {...rank} color={PrimaryColor.bgColor} />
              {duration === '00:00:00' ? null : <Duration duration={duration} color={PrimaryColor.bgColor} />}
              {bookingStatusLabelMap[bookingStatus].text && (
                <>
                  <BookingStatusLabel color={bookingStatusLabelMap[bookingStatus]?.color || ''}>
                    &nbsp; {bookingStatusLabelMap[bookingStatus].text}
                  </BookingStatusLabel>
                  &nbsp; · &nbsp;
                  {(bookingStatus === BookedStatuses.Booked ||
                    bookingStatus === BookedStatuses.Pending) && (
                    <>
                      <CancelBookingButton onClick={handleOpenCancelBookingModal}>
                        {showCancelBookingModal ? (
                          <CancellationModal
                            handleCloseModal={handleCloseCancelBookingModal}
                            showModal={showCancelBookingModal}
                            //@ts-ignore
                            item={item}
                          />
                        ) : null}
                        Cancel Booking
                      </CancelBookingButton>
                    </>
                  )}
                </>
              )}
            </RankAndStatusContainer>
          </Content>
        </CardActionArea>
        <ContomizedButton
          text={text}
          background={buttonColor}
          onClick={onClick}
          spinnerState={showSpinner}
          loadingSpinner={true}
          Icon={icon}
          disabled={disabled}
          textColor={textColor}
          style={{ width: '100%', border: 'none', borderTop: '1px solid grey', borderRadius: 0 }}
        />
      </StyledCard>
    </CardContainer>
  );
}

const TimelineIndex = styled.div`
  background-color: ${(p) => p.theme.primary.bgColor};
  color: ${(p) => p.theme.primary.textColor};
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
  padding: 5px 7px;
  font-weight: 600;
  font-family: Roboto;
  border-radius: 5px;
`;

const CardContainer = styled('div')<{ componentLocation: 'Feed' | 'Explore' }>`
  margin: ${(p) => (p.componentLocation === 'Feed' ? '1rem 0' : '0 1rem')};
  position: relative;
`;

const IconSupplier = styled.img`
  margin-left: 5px;
  height: 1.5rem;
  width: 1.9rem;
`;

const StyledCard = styled(Card)<{ cardBorderColor: string }>`
  && {
    width: 320px;
    background: white;
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(63, 63, 63, 0.08), 0px 3px 5px rgba(63, 63, 63, 0.02),
      0px 1px 1px rgba(63, 63, 63, 0.05);
    border: 0.5px solid ${(p) => p.cardBorderColor};
  }
`;

const CancelBookingButton = styled.div`
  color: #535353;
  text-decoration-line: underline;
  font-size: 0.8rem;
  line-height: 1.2rem;
`;

const RankAndStatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.5rem;
`;

const BookingStatusLabel = styled('span')<{ color: string }>`
  color: ${(props) => props.color};
  font-weight: 600;
  font-size: 0.9rem;
`;

const Content = styled(CardContent)`
  && {
    padding-left: 2.2rem;
  }
`;

const Title = styled.div`
  text-transform: capitalize;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #0e1738;
`;

const StyledFab = styled(Fab)<{ success?: boolean }>`
  && {
    height: 35px;
    width: 35px;
    background-color: ${(p) => (p.success ? '#a5d6a7' : '')};
    aspect-ratio: 1;
  }
`;

const Alink = styled.a`
  text-decoration: none;
  font-weight: 700;
  color: darkblue;
`;

const TagsContainer = styled.div<{ TIMELINE: boolean }>`
  position: absolute;
  height: 24px;
  left: ${(p) =>
    p.TIMELINE ? '4rem' : '1rem'}; // set margin to be located next to the Feed's timeline
  top: 1rem;
`;
const DeleteIconContainer = styled.div`
  top: 1rem;
  right: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
`;

const Tag = styled.div`
  background: rgba(255, 255, 255, 0.95);
  border-radius: 4px;
  padding: 5px;
  margin-bottom: 2px;
  font-size: 12px;
  width: fit-content;
`;

const ThreeDotsMenu = styled.img`
  display: flex;
  padding: 7px;
  width: 20px;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: #dbdbdb;
`;

const Icon = styled.img`
  width: 28px;
  padding: 5px;
  border-radius: 50%;
  background-color: #dbdbdb;
  z-index: 999;
  cursor: pointer;
`;