import styled from 'styled-components/macro';
import { TripItemsDic } from '../../../core/utils/dictionaries';
import { TRIP_ITEMS } from '../../../core/utils/enums';
import tripInterests from '../../../assets/static/tripInterests/tripInterests';
import StorageService from "../../../core/services/storage";

interface IContainerProps {
  children: any;
  last?: boolean;
  boxType: TRIP_ITEMS | 'HOTEL';
  cardBorderColor: string;
}

export default function TimelineBox({ children, last, boxType, cardBorderColor }: IContainerProps) {
  let icon;

  if (children.props.item) {
    let type = children.props.item.type;
    if(type !== 3) {
      const tag = children.props.item.tags[0];
      icon = tripInterests!.Full[tag]?.icon;
    }
  }

  const borderColor = StorageService.local.get.item("ClientDesign").PrimaryColor_bg || null; 
  const bgColor= StorageService.local.get.item("ClientDesign")?.PrimaryColor?.bgColor || null;
  
  return (
    <Container>
      <TimelineContainer type={boxType}>
        {boxType === 'HOTEL' && <HotelIcon src={TripItemsDic[boxType].icon} alt="icon" />}
        {(boxType === TRIP_ITEMS.Attraction || boxType === TRIP_ITEMS.Restaurant) && (
          <AttractionIcon
            cardBorderColor={borderColor ? borderColor : bgColor}
            src={icon ? icon : TripItemsDic[boxType].icon}
            alt="icon"
          />
        )}
        <TimelineLine last={last} />
      </TimelineContainer>
      {children}
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 10px auto 10px auto;
  &:last-child .line {
    display: none;
  }
  @media (max-width: 950px) {
  :last-child {
    margin-bottom: 60px
  }
}
`;
const TimelineContainer = styled('div') <{ type: TRIP_ITEMS | 'HOTEL' }>`
  position: absolute;
  height: 100%;
  z-index: 1;
  top: ${(p) => (p.type === TRIP_ITEMS.Commute || p.type === 'HOTEL' ? '' : '22px')};
  bottom: ${(p) => (p.type === TRIP_ITEMS.Commute || p.type === 'HOTEL' ? '15px' : '')};
  left: 5px;
  /* left:${(p) => (p.type === TRIP_ITEMS.Commute || p.type === 'HOTEL' ? '5px' : '5px')}; */
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HotelIcon = styled.img`
  padding: 7px;
  background-color: white;
  width: 25px;
  height: 25px;
  border: 1px solid rgba(0, 0, 0, 0.09);
  z-index: 2;
  border-radius: 30px;
  position: flex;

`;
const TimelineLine = styled('div') <{ last?: boolean }>`
  position: absolute;
  top: 1.2vh;
  left: 20px;
  z-index: -1;
  border-left: 3px solid rgba(196, 196, 196, 0.5);
  height: 100%;
  display: ${(p) => p.last && 'none'};
`;

const AttractionIcon = styled.img<{ cardBorderColor: string }>`
  padding: 7px;
  background-color: white;
  width: 25px;
  height: 25px;
  border: 3px solid ${(p) => p.cardBorderColor};
  z-index: 2;
  border-radius: 10px;
  margin-top: -23px;
  box-shadow: 0px -3px 4px rgba(0, 0, 0, 0.25);
`;
