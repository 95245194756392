import {   AttractionStates, BookedStatuses, HASH, TRIP_ITEMS } from '../../../../core/utils/enums';
import styled from 'styled-components/macro';
import { Icons } from '../../../../assets/images/images';
import ItemInfoDialog from '../../../pages/Item/ItemInfoDialog'
import React, {useState, useEffect} from 'react';
import { useFeed, FeedProvider } from '../../../pages/Feed/useFeed';
import { useTripDetails } from '../../../../providers/TripDetailsProvider';
import { ButtonProvider } from '../../../pages/useButtonProps'
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/';
import Mixpanel, { MP_Events, MP_Pages, MP_Props } from '../../../../core/services/mixpanel';
import { navigateToOrdersPage, navigationHandler, scrollWindowToTop } from '../../../../core/utils/feed';
import { clearHash, setHash, turnSpacesToUnderline } from '../../../../core/utils/common';

interface IProps {
  lat: number;
  lng: number;
  index: number;
  item: any;
}

export interface IItemButtonsDic {
  text: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  buttonColor: string;
  textColor?: string;
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> | null | string;
  disabled?: boolean;
}


export default function Marker({ index, item }: IProps) {
  const [render, setRender] = useState(false)
  const [itemSelected, setItemSelected] = useState<any>()
  const [strState, setStrState] = useState<number>(0)
  const [event, setEvent] = useState(item)
  const {
    dispatch,
    state: { isOpenExplore, isOpenRemoveExplore, isSelectedAtr, isOpenDescription, isReplace },
  } = useFeed();

    const {
    handleReplace,
    handleSelectToAdd,
    handleRemove,
  } = ButtonProvider();
  
  let pickup: IPickupDetails | null = item?.pickup ? itemSelected.pickup : null;
  const {
    tripDetails: {
      ClientDesign: { PrimaryColor, SecondaryColor, name: clientName },
    },
  } = useTripDetails();

  useEffect(() => {
    setRender(!render)
    setEvent(item)
  }, [isReplace])

  let bookingStatus = false;
  let attractionState;
  function handleClickPin (e) {
    e.stopPropagation();
    setItemSelected(event)
    attractionState = setAttractionState(event);
    setStrState(attractionState)
    dispatch({ type: 'OPEN_ITEM_DESCRIPTION', payload: true });
  }
   
  function handleCloseBookedAttr () {
    dispatch({ type: 'OPEN_ITEM_DESCRIPTION', payload: false });
    setItemSelected('');
  }

  function handleNavigation(event) {
    event.stopPropagation();
    Mixpanel.track(MP_Events.Navigate, {
      Page: MP_Pages.Feed,
      tags: itemSelected.tags.join(', '),
      title: itemSelected.name,
      [MP_Props.client]: clientName,
    });
    let locationToNavigate: ILocation = itemSelected.location;
    // Pickup?--> navigate to pickup location
    if (pickup) {
      const { lat, lng } = pickup;
      locationToNavigate = { lat, lng };
    }
    navigationHandler(locationToNavigate);
  }

  async function handleBookNow(event) {
    event.stopPropagation();
    if (itemSelected.uuid) {
      Mixpanel.track(MP_Events.BookNow, {
        Page: MP_Pages.Feed,
        tags: itemSelected.tags.join(', '),
        title: itemSelected.name,
        [MP_Props.client]: clientName,
      });
      dispatch({ type: 'SELECTED_ITEM_BOOK', payload: event });
      setHash(HASH.BookingInfo, turnSpacesToUnderline(itemSelected.name));
      if (
        itemSelected.source === 'Tiqets' ||
        itemSelected.source === 'SportsEvents365' ||
        itemSelected.source === 'Eventim' ||
        itemSelected.source === 'Tickitto'
      ) {
        dispatch({ type: 'TOGGLE_TIQETS_DIALOG' });
      } else if (itemSelected.source === 'Musement') {
        dispatch({ type: 'TOGGLE_BOOKING_FLOW_DIALOG' });
      } else {
        if (!itemSelected.orderPage) return;

        navigateToOrdersPage(itemSelected.orderPage);
      }
    }
  }

  let isInTrip = true;

  function setAttractionState(itemdata): AttractionStates {
    //If musement activity? map depends on the activity state
    if (itemdata.name === 'Free Time' ) return AttractionStates.NotAvailable;
    if (bookingStatus) return AttractionStates.Booked;
    if (isOpenRemoveExplore && isOpenExplore) return AttractionStates.Remove;
    if (isOpenExplore && !isOpenRemoveExplore && isSelectedAtr) return AttractionStates.Replace;
    if (isOpenExplore && !isOpenRemoveExplore && !isSelectedAtr) return AttractionStates.AddToTrip;
    if (isInTrip && itemdata.name !== 'Free Time' && itemdata.type !== 2 && !isOpenExplore && !isOpenRemoveExplore && itemdata.source !== "Google Place" && itemdata.source !== "GoogleMaps" && !bookingStatus) return AttractionStates.BookNow;
    if (itemdata.source === "GoogleMaps" && !isOpenExplore && !isOpenRemoveExplore) return AttractionStates.Navigate;
    if (itemdata.source === "Google Place" && !isOpenExplore && !isOpenRemoveExplore) return AttractionStates.Navigate;
    if (itemdata.type === 2 && !isOpenRemoveExplore && !isOpenExplore) return AttractionStates.Navigate;
    
    return AttractionStates.Navigate;
  }
  
  let ButtonsDic = {}
  if(itemSelected){
   ButtonsDic = {
      [AttractionStates.AddToTrip]: {
        text: 'Add To Trip',
        buttonColor: PrimaryColor.bgColor,
        textColor: PrimaryColor.textColor,
        onClick: () => handleSelectToAdd(itemSelected),
        icon: null,
        disabled: false
      },
      [AttractionStates.Replace]: {
        text: 'Replace Event',
        buttonColor: PrimaryColor.bgColor,
        textColor: PrimaryColor.textColor,
        onClick: () => handleReplace(itemSelected),
        icon: null,
        disabled: false
      },
      [AttractionStates.Remove]: {
        text: 'Remove from Trip',
        buttonColor: PrimaryColor.bgColor,
        textColor: PrimaryColor.textColor,
        onClick: () => handleRemove(itemSelected),
        icon: null,
        disabled: false
      },
      [AttractionStates.NotAvailable]: {
        text: '',
        buttonColor: PrimaryColor.bgColor,
        textColor: PrimaryColor.textColor,
        onClick: (e: any) => console.log('not available'),
        icon: null,
        disabled: false
      },
      [AttractionStates.BookNow]: {
        text: bookingStatus ? 'Awaiting Approval' : 'Book Now',
        buttonColor: itemSelected.type === TRIP_ITEMS.Attraction ? PrimaryColor.bgColor : SecondaryColor.bgColor,
        textColor: itemSelected.type === TRIP_ITEMS.Attraction ? PrimaryColor.textColor : SecondaryColor.textColor,
        onClick: (e) => handleBookNow(e),
        icon: null,
        disabled: bookingStatus,
      },
      [AttractionStates.Navigate]: {
        text: 'Navigate',
        buttonColor: 'white',
        textColor: 'black',
        onClick: (e) => handleNavigation(e),
        icon: Icons.navigate,
        disabled: false
      },
      [AttractionStates.Booked]: {
        text: 'Booked',
        textColor: 'white',
        buttonColor: 'purple',
        onClick: () => console.log('Attraction booked'),
        icon: null,
        disabled: false
      },
    };
  }

  return (
    <Container className="Marker_container">
      <AbsoluteContainer>
        <TimelineIndex>{index}</TimelineIndex>
        <img onClick={(e) => handleClickPin(e)} src={Icons.pin} alt="pin" />
      </AbsoluteContainer>

      {isOpenDescription && itemSelected && <ItemInfoDialog
          item={itemSelected}
          openInfoDialog={isOpenDescription}
          ButtonProps={ButtonsDic[strState]}
          showSpinner={false}
          handleClose={handleCloseBookedAttr}
        /> }
  
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const AbsoluteContainer = styled.div`
  position: absolute;
  top: -50px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TimelineIndex = styled.div`
  background-color: ${(p) => p.theme.primary.bgColor};
  color: ${(p) => p.theme.primary.textColor};
  padding: 5px 7px;
  font-weight: 600;
  font-family: Roboto;
  border-radius: 5px;
  margin-bottom: 5px;
  z-index: 5;
`;