import { Endpoints } from "../utils/endpoints";
import Calls from "./calls";
import StorageService from '../../core/services/storage';
import axios from 'axios';

// const { bridgify: { post } } = Calls;
const API_ENDPOINT = "https://api.bridgify.io/attractions/products/?itinerary=True&city_name="
interface ILogTiqetsOrder {
  trip_id: ITripDetails_Converted["_id"];
  itinerary_item_id: EventDetails_Attraction["id"];
  external_id: string; //the external_id?
  window_open_time: string; //YY-MM-DD HH:MM:SS;
  window_close_time: string; //YY-MM-DD HH:MM:SS
}
// export const logTiqetsOrder = async (data: ILogTiqetsOrder): Promise<void> =>
//   post(Endpoints.logTiqetsOrder, data);


  export const logTiqetsOrder = async (a) => {
    try {
      let token = StorageService.local.get.item('OauthToken')
      //  const { data } = await axios.get(API_ENDPOINT + city + '&limit=50' + "&date_from=" + start + "&date_to=" + start + "&time_from=" + start_time + "&time_to=" + end_time);
      const { data } = await axios.post('https://api.bridgify.io/attractions/log-order/', a, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const err = null;
      return { data, err };
    } catch (err) {
      const data = null;
      return { err, data };
    }
  };