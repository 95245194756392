import styled from "styled-components/macro";
// import { Icons } from "../../../assets/images/images";

export interface ICommuteType {
    [index: string]: string
}

// const CommuteType: ICommuteType = {
//     "Driving": Car,
//     "Walking": Walk
// }

interface IProps {
    item: ITripItem_Commute
}
export default function Commute({ item: { commuteTime: { Driving, Walking } } }: IProps) {

    // const renderCommute = useMemo(() => {
    //     //Function that create the cummote Label
    //     const commuteLabelMaker = (amount: number, timeUnit: string) =>
    //         `${amount} ${timeUnit.includes('hour') ? 'hour' : 'min'} `

    //     //@ts-ignore
    //     const [walkingAmount, walkingtimeUnit]: [string | null, string | null] = commuteWalk

    //     const WalkingBox = () => <Box icon={CommuteType["Walking"]}>
    //         {walkingAmount && walkingtimeUnit &&
    //             <Content>
    //                 {commuteLabelMaker(+walkingAmount, walkingtimeUnit)} {'Walk'}
    //             </Content>}
    //     </Box>

    //     //Driving
    //     const drive = commuteInfo.find((commute: any) => commute.commuteType === 'Driving');
    //     let commuteDrive = drive?.content?.split(" ") || [null, null];
    //     const [drivingAmount, drivingtimeUnit] = commuteDrive

    //     const DrivingBox = () => <Box icon={CommuteType["Driving"]}>
    //         {drivingAmount && drivingtimeUnit && walkingAmount && walkingtimeUnit &&
    //             <Content>
    //                 {commuteLabelMaker(+drivingAmount, drivingtimeUnit)}
    //                 Drive ({commuteLabelMaker(+walkingAmount, walkingtimeUnit)} Walk)
    //             </Content>}
    //     </Box>

    //     //Case 1: Short Walk (Shorter than 10 min walk)
    //     //Case 2: Drive / Long walk (Longer than 10 min walk)
    //     return (!walkingtimeUnit?.includes('hour') && walkingAmount && +walkingAmount < 10) ?
    //         <WalkingBox /> : <DrivingBox />
    // }, [commuteInfo]);

    return (
        // <Container>
        //     <TimelineContainer>
        //         <Img src={Icons.car} alt="car" />
        //         <TimelineLine />
        //     </TimelineContainer>
        // </Container>
        <Description>
            {Driving && `${Driving}  `}
            {Walking && (Driving ? `(${Walking} )` : `${Walking} `)}
        </Description>
        // <>{renderCommute}</>sss
    );
}

const Description = styled.div`
    margin: auto;
    background-color: white;
    padding:0.5rem 1.2rem;
    padding-left: 3.2rem;
    border-radius: 10px;
    box-shadow: 0px 1px 10px 1px #8080808f;
`;
// const Container = styled.div`

// `;
// const Content = styled.div`
//     font-size: 15px;
//     line-height: 17px;
//     color: #97A1B1;
//     margin: auto 0;
// `;
